<template>
  <div class="stepper-wrapper">
    <slot name="title">
      <div v-if="title" class="stepper-header-title">{{ title }}</div>
    </slot>
    <div class="stepper-header">
      <div class="stepper-header-line" />

      <div
        :class="[
          'stepper-header-step',
          {
            'current-step': index === internalStepIndex,
            complete: isStepComplete(step),
          },
        ]"
        v-for="(step, index) in steps"
        :key="`stepper-header-step-${step.name || index}`"
        @click="emitStepChange(index)">
        <div class="stepper-header-step-counter-wrapper">
          <div class="stepper-header-step-counter">
            <span v-if="!isStepComplete(step)">{{ index + 1 }}</span>
            <span v-else><ppicon name="check" /></span>
          </div>
        </div>

        <div class="stepper-header-step-name" v-if="step.name">
          {{ step.name }}
        </div>
      </div>
    </div>

    <div class="stepper-step-container">
      <div class="stepper-step-container-back">
        <common-button
          v-if="stepIndex > 0"
          variant="text"
          @click="emitStepChange(stepIndex - 1)">
          <ppicon
            class="stepper-step-container-back-icon"
            name="menu-down"
            alt="triangle" />
          {{ $lang.general_Back }}
        </common-button>
      </div>
      <transition :name="transitionName" mode="out-in">
        <template v-for="(step, index) in stepsWithState">
          <component
            class="stepper-step"
            :is="step.component"
            :options="step.options"
            :state="step.state"
            @update="updateState(step, $event)"
            @back="emitStepChange(index - 1)"
            @continue="emitStepChange(index + 1)"
            @complete="completeStep(step, index)"
            v-if="index === internalStepIndex"
            :key="`stepper-step-${step.name || index}`" />
        </template>

        <!-- Component to display when all steps have been completed -->
        <slot name="step-complete" v-if="showStepCompleteSlot">
          <StepCompleted
            :title="$lang.stepper_Congratulations"
            :description="$lang.stepper_EditProfile" />
        </slot>
      </transition>
    </div>
  </div>
</template>

<script>
  import StepCompleted from "@/components/common/stepper/StepCompleted.vue";

  export default {
    components: {
      StepCompleted,
    },
    props: {
      title: {
        type: String,
        required: false,
      },

      steps: {
        // Array of objects, list of steps
        type: Array,
        required: true,
      },

      stepIndex: {
        // Integer denoting the current step index in steps
        type: Number,
        default: 0,
      },
    },

    data() {
      return {
        // Controls current step navigation
        internalStepIndex: this.stepIndex,
        // Whether the panel slides left or right
        isBackTransition: false,
        // Contains a state property which has the contents of the update data calls
        // Necessary for if a user leaves a step and comes back
        stepsWithState: [],
        // List of steps marked as complete
        completedSteps: [],
      };
    },

    computed: {
      currentStep() {
        if (this.stepIndex >= this.steps.length) {
          return null;
        }

        return this.steps[this.stepIndex];
      },

      transitionName() {
        return this.isBackTransition ? "slide-right" : "slide-left";
      },

      firstIncompleteStepIndex() {
        return this.steps.findIndex((step) => !this.isStepComplete(step));
      },

      areAllStepsComplete() {
        return this.firstIncompleteStepIndex === -1;
      },

      showStepCompleteSlot() {
        return this.areAllStepsComplete && this.stepIndex === this.steps.length;
      },
    },

    watch: {
      steps: {
        immediate: true,
        deep: true,
        handler(val) {
          this.stepsWithState = val;
        },
      },

      stepIndex: {
        immediate: true,
        handler(val) {
          this.navigateToStep(val);
        },
      },
    },

    methods: {
      isStepComplete(step) {
        for (const completedStep of this.completedSteps) {
          if (completedStep.name === step.name) {
            // Completed step
            return true;
          }
        }

        return false;
      },

      isStepOutOfBounds(index) {
        return (
          index < 0 ||
          index > this.steps.length ||
          (index >= this.steps.length && !this.areAllStepsComplete)
        );
      },

      emitStepChange(index) {
        if (this.stepIndex === index) {
          // Active step selected
          return;
        }

        if (this.isStepOutOfBounds(index)) {
          // User may have skipped a step in the middle, navigate there
          index = this.firstIncompleteStepIndex;
        }

        this.$emit("update:stepIndex", index);
      },

      navigateToStep(index) {
        // 0 index is the first step
        if (this.isStepOutOfBounds(index)) {
          return;
        }

        // Active step is ahead of where they want to go, so transition should slide right
        // User is navigating forward in the slides, so transition should slide left
        this.isBackTransition = this.internalStepIndex > index;

        this.internalStepIndex = index;
      },

      completeStep(step, index) {
        this.$emit("complete", step);

        if (!this.isStepComplete(step)) {
          this.completedSteps.push(step);
        }

        this.emitStepChange(index + 1);
      },

      updateState(step, state) {
        this.$emit("update", state);

        for (const internalStep of this.stepsWithState) {
          if (step.name === internalStep.name) {
            this.$set(step, "state", state);
            return;
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .stepper-wrapper {
    margin: 4rem 7rem;
    background-color: $white;
    box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    min-width: 200px;
    min-height: 200px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: $sm) {
      margin: 0;
      border-radius: 0;
    }
  }

  .stepper-header {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .stepper-header-title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    color: $grey-800;
    margin: 60px auto 52px auto;
  }

  .stepper-header-line {
    position: absolute;
    border-bottom: 1px solid $grey-300;
    width: 100%;
    top: 10.5px;
  }

  .stepper-header-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    width: 106px;
    cursor: pointer;

    .stepper-header-step-counter-wrapper {
      background-color: $grey-100;
      color: $grey-400;
      border: 1px solid $grey-400;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      transition: color var(--transition-duration),
        background-color var(--transition-duration),
        border-color var(--transition-duration);
    }

    &.complete .stepper-header-step-counter-wrapper {
      background-color: $green-400;
      color: $white;
      border: 1px solid transparent;
    }

    &.current-step {
      cursor: default;

      .stepper-header-step-counter-wrapper {
        background-color: $blue-400;
        color: $white;
        border: 1px solid transparent;
      }
    }

    .stepper-header-step-name {
      color: $grey-500;
      letter-spacing: 0.1px;
      margin-top: 10px;
    }
  }

  .stepper-step-container {
    overflow: hidden;
    position: relative;
    max-width: 606px;
    margin: 0 auto 32px auto;
    padding: 0 30px 0 30px;
    position: relative;

    @media screen and (max-width: $sm) {
      position: inherit;
    }

    &-back {
      position: absolute;
      left: 11px;
      top: 0;

      @media screen and (max-width: $sm) {
        top: 100px;
        left: 15px;
      }

      &-icon {
        transform: rotate(90deg);
      }
    }
  }

  .stepper-step {
    margin-top: 50px;
  }
</style>
