<template>
  <div class="inbox-container">
    <div class="inbox-header">
      <h2 class="inbox-header-text">{{ $lang.inbox_DirectMessages }}</h2>

      <common-search v-model="query" />
    </div>

    <Scrollbar class="inbox-message-container" alwaysOverflowY>
      <div
        :class="['inbox-message', { 'active-convo': isConvoSelected(convo) }]"
        v-for="convo in searchedConvos"
        :key="`inbox-panel-${convo.convoId}`"
        @click="selectConversation(convo)">
        <div class="profile-pic-container">
          <img :src="getRecipientImage(convo)" alt="Person's picture" />
        </div>

        <div class="message-container">
          <div class="user-message-wrapper">
            <label
              :class="[
                'user-name text-truncate',
                {
                  'unread-message':
                    !convo.message.isRead && convo.message.authorUserId !== uid,
                },
              ]"
              >{{ convo.recipientName }}</label
            >
            <label class="user-message text-truncate">{{
              getDisplayName(convo.recipientOrgName) || convo.message.body
            }}</label>
          </div>

          <div class="date-wrapper">
            <label class="message-date">{{
              formatDate(convo.message.createdDate)
            }}</label>
          </div>
        </div>
      </div>
    </Scrollbar>
  </div>
</template>

<script>
  import { getInbox } from "@/services/api.service.js";
  const api = { getInbox };
  import io from "socket.io-client";
  import { mapState } from "vuex";
  import commonFuncs from "../common/common";

  export default {
    props: {
      selectedConvoId: {
        type: String,
      },
    },

    data() {
      return {
        query: "",
        loading: true,
        socket: null,
        convos: [],
      };
    },

    computed: {
      sortedConvos() {
        let convos = [...this.convos];

        return convos.sort((convo1, convo2) => {
          const date1 = new Date(Date.parse(convo1.message.createdDate));
          const date2 = new Date(Date.parse(convo2.message.createdDate));
          return date2 - date1;
        });
      },

      searchedConvos() {
        if (!this.query) {
          return this.sortedConvos;
        }

        let convos = [];
        const query = this.query.toLowerCase();

        convos = this.sortedConvos.filter((convo) => {
          const recipientName = convo.recipientName?.toLowerCase() || "";
          const message = convo.message?.body?.toLowerCase() || "";

          return (
            recipientName.indexOf(query) >= 0 || message.indexOf(query) >= 0
          );
        });

        return convos;
      },

      ...mapState(["orgName", "requestCall", "token", "uid", "user"]),
    },

    watch: {
      convos: {
        deep: true,
        handler(convos) {
          // update unread message count
          this.user.unreadMessageCount = convos.filter((convo) => {
            return (
              !convo.message.isRead && convo.message.authorUserId !== this.uid
            );
          }).length;
          this.$store.dispatch("setUser", this.user);
        },
      },
    },

    created() {
      this.loadConversations().then(this.configureSocket);
    },

    beforeDestroy() {
      if (this.socket) {
        this.socket.emit("leave inbox", this.uid);
        this.socket.disconnect();
      }
    },

    methods: {
      loadConversations() {
        //getting inbox data & sorting it by timestamp
        return api
          .getInbox()
          .then((response) => {
            this.convos = response.data.convos;

            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      },

      configureSocket() {
        //setting up socket for inbox
        this.socket = io(process.env.VUE_APP_ENDPOINT, {
          query: { token: this.token },
        });

        this.socket.on("news", () => {
          this.socket.emit("enter inbox new", this.uid);
        });

        this.socket.on("inbox v.2 refresh messages", (conversation) => {
          for (let i = 0; i < this.convos.length; i++) {
            let convo = { ...this.convos[i] };

            if (convo.message.conversationID === conversation.conversationID) {
              //Update convo at index
              convo.message.body = conversation.composedMessage;
              convo.message.isRead = false;
              convo.message.authorUserId = conversation.currentUser;
              convo.message.createdDate = conversation.createdDate;

              //Move conversation to top of this
              this.$set(this.convos, i, convo);
              return;
            }
          }
        });

        this.socket.on("server read", (convo) => {
          // the current user sent the convo read event, so blue highlight can be removed
          if (convo.uid === this.uid) {
            let currentConvo = this.convos.find(
              (existingConvo) => existingConvo.convoId === convo.conversationID
            );

            if (currentConvo) {
              currentConvo.message.isRead = true;
            }
          }
        });

        this.socket.on("new convo", (convo) => {
          this.convos.unshift(convo);
        });

        this.socket.on("disconnect", (reason) => {
          if (reason === "forced close" || reason === "io client disconnect") {
            return;
          }

          this.socket.connect();
          this.socket.emit("enter inbox new", this.uid);
        });

        this.$emit("ready");

        if (
          !commonFuncs.isMobile() &&
          !this.$route.query.id &&
          this.convos.length
        ) {
          //Load the first convo if none are selected
          this.selectConversation(this.searchedConvos[0]);
        }
      },

      formatDate(date) {
        const options = { month: "short", day: "numeric" };
        return new Date(date).toLocaleDateString("en-us", options);
      },

      getRecipientImage(convo) {
        return convo.recipientImage
          ? convo.recipientImage
          : require("@/assets/profile.svg");
      },

      selectConversation(convo) {
        this.$emit("select-conversation", convo.convoId);
      },

      isConvoSelected(convo) {
        return this.selectedConvoId === convo.convoId;
      },

      getDisplayName: commonFuncs.getDisplayName,
    },
  };
</script>

<style lang="scss" scoped>
  .inbox-container {
    flex: 1;
    padding: 15px;
    max-width: 400px;
    min-width: 388px;
  }

  .inbox-header {
    padding-left: 5px;
    padding-right: 5px;
  }

  .inbox-header-text {
    text-align: initial;
    margin: 20px 0px;
  }

  .inbox-message-container {
    margin: 16px 0;
    height: calc(100% - 140px);

    &.scrollbar-container.always-overflow-y {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .inbox-message {
    display: flex;
    padding: 16px;
    cursor: pointer;
    border-radius: 12px;
    margin: 5px 0;

    label {
      cursor: pointer;
      max-width: 230px;
    }

    &:hover,
    &.active-convo {
      background-color: $row-hover-background;
    }
  }

  .profile-pic-container {
    height: 40px;
    width: 40px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .message-container {
    display: flex;
    flex: 1;
  }

  .user-message-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 14px;
    margin-right: 14px;
  }

  .user-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;

    &.unread-message {
      font-weight: 700;
      color: $blue-400;
    }
  }

  .message-date,
  .user-message {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .date-wrapper {
    margin-left: auto;
    margin-right: 10px;
    width: 40px;
  }

  @media screen and (max-width: $md) {
    .inbox-container {
      min-width: 288px;
    }

    .inbox-message label {
      max-width: 130px;
    }
  }
</style>
