import Vue from "vue";
import Vuex from "vuex";
import common from "../common/common";
import * as Sentry from "@sentry/vue";
import {
  logout,
  setToken,
  removeToken,
  getUser,
  getBrandingHeader,
} from "../services/api.service";
const api = { logout, setToken, removeToken, getUser, getBrandingHeader };
import colors from "@/constants/colors.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    organizationId: null,
    orgName: null,
    orgDisplayName: null,
    logoUrl: null,
    color: null,
    accentColor: null,
    backColor: null,
    backLink: null,
    canSendMessage: null,
    canRequestCall: null,
    landingPageLinks: [],
    landingPage: [],
    profileFields: [],
    signUpFields: [],
    inquiryQs: [],
    userTypes: {},
    signUpSlider: true,
    user: null,
    isCurrentParent: false,
    token: null,
    uid: null,
    features: {},
    contactUs: {},
    hasRandomizedAmbassadors: false,
    faviconUrl: null,
    ambassadorProfiles: [],
    landingPageIndex: 0,
    landingPageCacheTime: null,
    conversationFeedbackQuestionsAmbassador: null,
    conversationFeedbackQuestionsProspect: null,
  },

  mutations: {
    SET_BRANDING(state, branding) {
      state.organizationId = branding._id;
      state.orgName = branding.name;
      state.orgDisplayName = branding.orgDisplayName;
      state.logoUrl = branding.logoUrl;
      state.color = branding.color;
      state.accentColor = branding.accentColor;
      state.backColor = branding.backColor;
      state.backLink =
        branding.backLink.startsWith("https://") ||
        branding.backLink.startsWith("http://")
          ? branding.backLink
          : "https://" + branding.backLink;
      state.canSendMessage = branding.canSendMessage;
      state.canRequestCall = branding.canRequestCall;
      state.landingPageLinks = branding.landingPageLinks || [];
      state.landingPage = branding.landingPage;
      state.profileFields = branding.profileFields;
      state.signUpFields = branding.signUpFields;
      state.inquiryQs = branding.inquiryQs || [];
      state.userTypes = branding.userTypes;
      state.features = branding.features || {};
      state.contactIsActive = branding.contactIsActive;
      state.contactLabel = branding.contactLabel;
      state.hasRandomizedAmbassadors = branding.hasRandomizedAmbassadors;
      state.faviconUrl = branding.faviconUrl;
      state.conversationFeedbackQuestionsAmbassador =
        branding.conversationFeedbackQuestionsAmbassador || [];
      state.conversationFeedbackQuestionsProspect =
        branding.conversationFeedbackQuestionsProspect || [];

      if (branding.signUpSlider !== undefined) {
        state.signUpSlider = branding.signUpSlider;
      }
    },

    SET_USER(state, user) {
      state.user = user;
    },

    SET_CURRENT_PARENT(state, isCurrentParent) {
      state.isCurrentParent = isCurrentParent;
    },

    SET_LOGIN_USER(state, uid) {
      state.uid = uid;
    },

    SET_LOGIN_TOKEN(state, token) {
      state.token = token;
    },

    SET_HOMEPAGE_PROFILE_DATA(state, data) {
      state.ambassadorProfiles = data.profiles;
      state.landingPageIndex = data.landingPageIndex;
      state.landingPageCacheTime = new Date();
    },
  },

  actions: {
    async getBranding(context) {
      if (
        !context.state.logoUrl ||
        !context.state.color ||
        !context.state.accentColor
      ) {
        // do axios call
        let response;
        try {
          response = await api.getBrandingHeader();
        } catch (err) {
          console.error(
            `Failed to get branding information for URL ${window.location.href}. Error message: ${err}`
          );
          return context.state;
        }

        if (!response || !response.data) {
          Sentry.captureException(`Failed to get branding information`, {
            extra: {
              url: window.location.href,
              stateInfo: {
                ...context.state,
              },
            },
          });

          return context.state;
        }

        const data = response.data;

        const bodyStyle = document.body.style;
        bodyStyle.setProperty("--school-color", data.color);
        bodyStyle.setProperty("--accent-color", data.accentColor);
        bodyStyle.setProperty("--text-color", common.getTextColor(data.color));
        const accentColorRgb = common.hexToRgb(
          data.accentColor || colors.$yellow
        ).value;
        bodyStyle.setProperty("--accent-color-rgb", accentColorRgb);

        data.orgDisplayName = common.getDisplayName(
          data.displayName || data.name
        );

        context.commit("SET_BRANDING", data);
        return context.state;
      } else {
        return {
          orgName: context.state.orgName,
          displayName: context.state.displayName,
          logoUrl: context.state.logoUrl,
          color: context.state.color,
          accentColor: context.state.accentColor,
          backColor: context.state.backColor,
          backLink: context.state.backLink,
          canSendMessage: context.state.canSendMessage,
          canRequestCall: context.state.canRequestCall,
          landingPageLinks: context.state.landingPageLinks,
          landingPage: context.state.landingPage,
          profileFields: context.state.profileFields,
          signUpFields: context.state.signUpFields,
          inquiryQs: context.state.inquiryQs,
          userTypes: context.state.userTypes,
          signUpSlider: context.state.signUpSlider,
          contactIsActive: context.state.contactIsActive,
          contactLabel: context.state.contactLabel,
          hasRandomizedAmbassadors: context.state.hasRandomizedAmbassadors,
          faviconUrl: context.state.faviconUrl,
        };
      }
    },

    async fetchUser(context) {
      if (context.state.user) {
        return context.state.user;
      }

      let user = null;

      try {
        user = (await api.getUser())?.data;
      } catch (error) {
        if (error.response?.status === 401) {
          context.dispatch("clearLoginCookies");
        }
        console.error(error);
      } finally {
        context.commit("SET_USER", user);

        if (user) {
          context.dispatch("setCurrentParent", !user.signedUpOrg);
          context.dispatch("setUserId", user._id);
        }
      }

      return user;
    },

    logout({ commit, dispatch }) {
      api.logout();
      commit("SET_USER", null);
      dispatch("clearLoginCookies");
    },

    setUser({ commit }, user) {
      commit("SET_USER", user);
    },

    setCurrentParent({ commit }, isCurrentParent) {
      commit("SET_CURRENT_PARENT", isCurrentParent);
    },

    setUserId({ commit }, uid) {
      commit("SET_LOGIN_USER", uid);
    },

    setLoginToken({ commit }, token) {
      token ? Vue.$cookies.set("token", token) : Vue.$cookies.remove("token");
      api.setToken(token);
      commit("SET_LOGIN_TOKEN", token);
    },

    clearLoginCookies({ dispatch }) {
      dispatch("setCurrentParent", false);
      dispatch("setUserId", null);
      dispatch("setLoginToken", null);
      api.removeToken();
    },

    setBlackBaudCookies(context, data) {
      for (const [key, value] of Object.entries(data)) {
        Vue.$cookies.set(key, value, "1d");
      }
    },

    clearBlackBaudCookies() {
      const cookies = [
        "invitationID",
        "videoInvitationID",
        "callInvitationID",
        "ambInvitationID",
      ];

      for (const cookie in cookies) {
        Vue.$cookies.remove(cookie);
      }
    },

    setHomepageProfileData({ commit }, data) {
      commit("SET_HOMEPAGE_PROFILE_DATA", data);
    },
  },

  getters: {
    prospSingularUserType(state) {
      return state.userTypes?.prospSingular?.toLowerCase();
    },

    prospPluralUserType(state) {
      return state.userTypes?.prospPlural?.toLowerCase();
    },

    ambassadorPluralUserType(state) {
      return state.userTypes?.ambPlural?.toLowerCase();
    },

    ambassadorSingularUserType(state) {
      return state.userTypes?.ambSingular?.toLowerCase();
    },
    isProspect(state) {
      return !!state.user.signedUpOrg;
    },
  },
});
