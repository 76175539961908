import CommonButton from "@/components/common/Button";
import Dropdown from "@/components/common/Dropdown";
import Icon from "@/components/common/Icon";
import CommonInput from "@/components/common/Input";
import CommonModal from "@/components/common/Modal";
import CommonPhone from "@/components/common/Phone.vue";
import CommonRadioButtonGroup from "@/components/common/RadioButtonGroup";
import CommonSearch from "@/components/common/Search";
import CommonSpinner from "@/components/common/Spinner.vue";
import CommonStepper from "@/components/common/stepper/Stepper.vue";
import Toggle from "@/components/common/Toggle";
//import { datadogLogs } from "@datadog/browser-logs";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
import GSignInButton from "vue-google-signin-button";
import TextareaAutosize from "vue-textarea-autosize";
import App from "./App.vue";
import Scrollbar from "./components/common/Scrollbar";
import toast from "./plugins/toast";
import router from "./router/router.js";
import store from "./store/store.js";
import amplitude from "./utils/amplitude.js";
//import { datadogRum } from "@datadog/browser-rum";

// if (
//   process.env.VUE_APP_DD_RUM_CLIENT_TOKEN &&
//   process.env.VUE_APP_DD_APPLICATION_ID
// ) {
//   datadogRum.init({
//     applicationId: process.env.VUE_APP_DD_APPLICATION_ID,
//     clientToken: process.env.VUE_APP_DD_RUM_CLIENT_TOKEN,
//     site: "datadoghq.com",
//     service: "peerpal-client",
//     env: process.env.NODE_ENV || "production",
//     // Specify a version number to identify the deployed version of your application in Datadog
//     // version: '1.0.0',
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 20,
//     trackUserInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel: "mask-user-input",
//     allowedTracingUrls: ["https://chatapi.peerpal.com"],
//   });

//   datadogRum.startSessionReplayRecording();
// }

// if (process.env.VUE_APP_DD_CLIENT_TOKEN) {
//   datadogLogs.init({
//     clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
//     site: "datadoghq.com",
//     forwardErrorsToLogs: true,
//     sessionSampleRate: 100,
//   });
// }

Vue.config.productionTip = false;

Vue.component("common-input", CommonInput);
Vue.component("common-modal", CommonModal);
Vue.component("common-dropdown", Dropdown);
Vue.component("common-toggle", Toggle);
Vue.component("common-search", CommonSearch);
Vue.component("common-button", CommonButton);
Vue.component("common-radio-button-group", CommonRadioButtonGroup);
Vue.component("common-stepper", CommonStepper);
Vue.component("common-spinner", CommonSpinner);
Vue.component("common-phone", CommonPhone);
Vue.component("Scrollbar", Scrollbar);
Vue.component("ppicon", Icon);
Vue.use(toast);
Vue.use(VueCookies);
Vue.$cookies.config("30d", "", "", true, "None");
Vue.use(amplitude);
Vue.use(GSignInButton);
Vue.use(TextareaAutosize);

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    Vue,
    dsn: "https://c9e5b17859c8459b930b8483af2f40cb@o227651.ingest.sentry.io/5951663",
    integrations: [
      new TracingIntegrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", process.env.VUE_APP_ENDPOINT],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// set login token as early as possible
store.dispatch("setLoginToken", Vue.$cookies.get("token"));

new Vue({
  el: "#app",
  router,
  store,
  amplitude,
  render: (h) => h(App),
});

if (!String.prototype.format) {
  String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  };
}
