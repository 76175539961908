<template>
  <div class="radio-button-group">
    <div
      class="radio-button-group__button"
      v-for="option of options"
      :key="option">
      <input
        :id="option"
        :name="name"
        type="radio"
        ref="input"
        @change="$emit('input', option)" />
      <label :for="option">{{ option }}</label>
    </div>
  </div>
</template>

<script>
  export default {
    name: "common-radio-button",

    props: {
      options: {
        type: Array,
        required: true,
      },
      name: {
        type: String,
        default: "default",
      },
    },
  };
</script>

<style scoped lang="scss">
  .radio-button-group__button {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
  }
  input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0 12px 0 0;
    font: inherit;
    color: $grey-800;
    width: 20px;
    height: 20px;
    border: 0.15em solid $grey-800;
    border-radius: 50%;
    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      /* allows the checked to be printable on a screen instead of background-color */
      box-shadow: inset 1em 1em $grey-800;
    }

    &:checked::before {
      transform: scale(1);
    }
  }
  label {
    color: $grey-800;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
</style>
