<template>
  <div class="profile-edit">
    <div class="profile-edit-title h0">{{ $lang.profileEdit_Title }}</div>
    <div class="profile-edit-card">
      <div class="profile-edit-body">
        <ProfileDetails
          :user="user || cachedUser"
          :profileFields="profileFields"
          @complete="areProfileDetailsValid = $event"
          @update="onUpdateDetails"
          @dirty="isUserFormDirty = true" />
        <template v-if="features.allowProfileVideo && user.organization">
          <div class="profile-edit-divider"></div>
          <VideoRecorder
            :videoSrc="videoSrc"
            :userHidden="userHidden"
            :isAmbassador="!isProspect"
            @update="onUpdateVideoStory"
            @complete="isComplete = $event"
            @dirty="isUserStoryDirty = true" />
        </template>
        <template v-if="user.organization">
          <div class="profile-edit-divider"></div>
          <ProfileReadMyStory
            :stories="user.faq || cachedUser.faq"
            @update="onUpdateFaq"
            @dirty="isUserFormDirty = true" />
        </template>
      </div>
    </div>
    <div class="profile-edit-footer">
      <common-button
        class="profile-edit-save"
        behavior="grow"
        @click="onSave"
        :disabled="!areProfileDetailsValid"
        :loading="saving"
        >{{ $lang.general_Save }}</common-button
      >
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from "vuex";
  import {
    getUserVideoStories,
    createUserVideoStory,
    updateUserVideoStory,
    updateUser,
    deleteUserVideoStory,
  } from "@/services/api.service.js";
  const api = {
    getUserVideoStories,
    createUserVideoStory,
    updateUserVideoStory,
    updateUser,
    deleteUserVideoStory,
  };
  import ProfileDetails from "@/components/ProfileDetails.vue";
  import VideoRecorder from "@/components/VideoRecorder.vue";
  import ProfileReadMyStory from "@/components/ProfileReadMyStory.vue";

  export default {
    name: "ProfileEdit",
    components: {
      ProfileDetails,
      VideoRecorder,
      ProfileReadMyStory,
    },
    data() {
      return {
        cachedUser: {
          first: "",
          last: "",
          faq: [],
        },
        areProfileDetailsValid: false,
        videoSrc: null,
        userHidden: null,
        userStory: null,
        videoRecorderData: null,
        isComplete: false,
        isUserFormDirty: false,
        isUserStoryDirty: false,
        deleteVideo: false,
        saving: false,
      };
    },
    computed: {
      ...mapState([
        "user",
        "uid",
        "profileFields",
        "features",
        "ambassadorProfiles",
        "landingPageIndex",
      ]),
      ...mapGetters(["isProspect"]),
      isFormValid() {
        return this.isComplete;
      },
    },
    async mounted() {
      if (this.features?.allowProfileVideo) {
        const userStories = (await api.getUserVideoStories(this.uid)).data.sort(
          (videoA, videoB) =>
            new Date(videoB.updatedAt) - new Date(videoA.updatedAt)
        );
        const userStory = userStories.length > 0 ? userStories[0] : null;
        this.userStory = userStory;
        this.userHidden = userStory?.userHidden ?? false;
        this.videoSrc = userStory?.video?.mp4Url ?? null;
      }
    },
    methods: {
      onUpdateDetails(value) {
        this.cachedUser = { ...this.user, ...value };
      },
      onUpdateFaq(faq) {
        this.cachedUser = { ...this.user, faq };
      },
      async onSave() {
        try {
          this.saving = true;

          if (this.isUserFormDirty) {
            const response = await api.updateUser(this.cachedUser);
            this.$store.dispatch("setUser", response.data);
          }
          if (this.isUserStoryDirty) {
            if (this.videoRecorderData.videoData) {
              const formData = new FormData();
              formData.append("file", this.videoRecorderData.videoData);
              formData.append(
                "userHidden",
                !!this.videoRecorderData.userHidden
              );
              await api.createUserVideoStory(formData);
              this.userStory = null; // needs admin approval
            } else if (this.deleteVideo && this.userStory?.id) {
              await api.deleteUserVideoStory(this.userStory.id);
              this.userStory = null;
            } else {
              await api.updateUserVideoStory(
                this.userStory?.id,
                this.videoRecorderData.userHidden
              );
              if (this.videoRecorderData.userHidden) {
                this.userStory = null;
              }
            }
          }
          // update user profile on landing page
          const profile = this.ambassadorProfiles.find(
            (profile) => profile._id === this.user._id
          );
          if (profile) {
            profile.first = this.cachedUser.first;
            profile.last = this.cachedUser.last;
            profile.requestCall = this.cachedUser.requestCall;
            profile.profile = this.cachedUser.profile;
            profile.videoStory = this.userStory?.approvedDate
              ? this.userStory
              : null;

            this.$store.dispatch("setHomepageProfileData", {
              profiles: this.ambassadorProfiles,
              landingPageIndex: this.landingPageIndex,
            });
          }

          this.$toast.success(this.$lang.ProfileEdit_Success);
          this.$router.push({ name: "Home" });
        } catch (error) {
          this.$toast.error(this.$lang.stepWatchMyStory_Error);
        } finally {
          this.saving = false;
        }
      },
      onUpdateVideoStory(value) {
        this.videoRecorderData = value;
        this.userHidden = value.userHidden;

        if (value.delete) {
          this.deleteVideo = true;
          this.videoSrc = null;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  $content-width: 1000px;

  .profile-edit {
    margin: 0 20px 40px 20px;

    &-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin: 87px auto 54px auto;

      @media (max-width: $xs) {
        margin: 40px auto;
      }
    }

    &-card {
      margin: 0 auto;
      max-width: $content-width;
      background-color: $white;
      border-radius: 20px 20px 0 0;
      box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.08);
      padding: 64px 30px 34px 30px;
      box-sizing: border-box;

      @media (max-width: $xs) {
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0 30px 34px 30px;
      }
    }

    &-body {
      margin: 0 auto;
      max-width: 600px;
    }

    &-divider {
      border-top: solid 1px $grey-200;
      width: 100%;
      margin: 48px 0;
    }

    &-save {
      width: 164px;
    }

    &-footer {
      margin: 0 auto;
      max-width: $content-width;
      background-color: $white;
      border-top: solid 1px $grey-200;
      border-radius: 0 0 20px 20px;
      box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.08);
      padding: 20px 0;

      @media (max-width: $xs) {
        margin: 0;
        border-radius: 0;
      }
    }

    @media (max-width: $xs) {
      background-color: $white;
      margin: 0;
    }
  }
</style>
