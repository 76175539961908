<template>
  <div class="faqPage">
    <div id="contentContainer">
      <h1>Frequently Asked Questions</h1>
      <h2>What is this?</h2>
      <p>
        PeerPal is a service that allows {{ prospPluralUserType }} considering
        {{ orgDisplayName }} to be able to chat with current
        {{ ambassadorPluralUserType }} at {{ orgDisplayName }} to learn about
        their experiences. The goal of these conversations is to provide you
        with the best information possible so that you can make the best choice
        for your child.
      </p>
      <h2>What kinds of questions can I ask?</h2>
      <p>
        There aren’t any limits on what you can ask about, within reason of
        course. With that said, if you have specific admissions or financial aid
        related questions, you should probably reach out to the admissions
        office.
      </p>
      <h2>Who are these {{ ambassadorPluralUserType }}?</h2>
      <p>
        Good question! You can learn more about the
        {{ ambassadorPluralUserType }} by reading their profiles and bios. All
        of the {{ ambassadorPluralUserType }} you see have at least one child
        who is currently enrolled.
      </p>
      <h2>When can I expect to get a response?</h2>
      <p>
        While the exact response time depends on a number of factors, including
        your specific inquiry and the {{ ambassadorSingularUserType }}’s
        schedule, almost all inquiries receive a response within 48 hours. As
        soon as you have a new response, you will receive an email notifying
        you.
      </p>
      <h2>Why do I have to create an account?</h2>
      <p>
        When creating an account, we just ask for your name, email, and a
        password. We use this information to be able to notify you when you
        receive a new message and to load your conversations when you return to
        the website.
      </p>
      <h2>Are my conversations private?</h2>
      <p>
        Yes, your conversations are confidential conversations between you and
        the person you are speaking to. We will never share your conversations
        with any third parties, including {{ orgDisplayName }}. Feel free to
        read our official
        <router-link :to="{ name: 'Terms of Service' }"
          >Terms of Service</router-link
        >
        for more details.
      </p>
      <h2>Why am I asked to rate my conversation at the end of it?</h2>
      <p>
        At the end of each conversation, we ask you to provide some feedback
        rating the value of your conversation and your interest level in
        {{ orgDisplayName }}, both before and after the conversation. This is
        important data that helps us to measure the value of the conversations
        and improve the service. It should only take a minute!
      </p>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from "vuex";

  export default {
    computed: {
      ...mapState(["orgDisplayName"]),
      ...mapGetters([
        "prospPluralUserType",
        "ambassadorPluralUserType",
        "ambassadorSingularUserType",
      ]),
    },
  };
</script>

<style scoped>
  .faqPage {
    width: 100%;
    height: 100%;
  }
  img {
    float: left;
    margin: 0 20px;
    cursor: pointer;
    height: 75px;
    max-width: 250px;
    object-fit: contain;
    padding: 20px 0 20px 0;
  }
  #contentContainer {
    padding: 20px 0 0 50px;
  }
  h2 {
    margin: 0 30px 10px 0;
    text-align: left;
  }
  p {
    font-size: 18px;
    margin: 0 0 35px 0;
    text-align: left;
    width: 75%;
  }
</style>
