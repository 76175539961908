<template>
  <StepperProfileSignup v-if="isCurrentParent"> </StepperProfileSignup>
  <ProspectQuestions v-else></ProspectQuestions>
</template>

<script>
  import { mapState } from "vuex";
  import StepperProfileSignup from "@/components/profile/StepperProfileSignup.vue";
  import ProspectQuestions from "@/components/profile/ProspectQuestions.vue";

  export default {
    components: { StepperProfileSignup, ProspectQuestions },
    computed: {
      ...mapState(["isCurrentParent"]),
    },
  };
</script>
