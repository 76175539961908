<template>
  <div>
    <h1>Hello</h1>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    created() {
      if (!this.$route.query.code || !this.$route.query.state) {
        this.$toast.error("Failed");
        return;
      }
      let encodedState = this.$route.query.state;

      let decode1 = decodeURIComponent(encodedState);
      let decode2 = atob(decode1);
      let decodeObject = JSON.parse(decode2);
      console.log(decodeObject);

      // TODO: make request to backend to authenticate/create user and redirect correctly
      // TODO: adding amplitude, saving tokens, and redirecting to Signup Profile Page or Messaging Page
    },
  };
</script>

<style scoped></style>
