//English JSON
export default {
  chat_BlockUser: "Block User",
  chat_UnblockUser: "Unblock User",
  chat_BlockUserText: "Are you sure you want to block this user?",
  chat_UnblockUserText: "Are you sure you want to unblock this user?",
  chat_CloseConversation: "Close Conversation",
  chat_CloseConvoTitle:
    "Closing a conversation removes you from reminder notifications. It also alerts both users that the chat has been ended. Sending a message will reopen the conversation",
  chat_DisabledCloseConvoTitle:
    "This conversation is already closed. You may reopen the conversation by sending another message",
  chat_AdminUser: "An admin",
  chat_AskAdmissions: "Ask Admissions",
  chat_AskAdmissionsPlaceholder: "Type your message here",
  chat_AskAdmissionsSuccess:
    "Message sent! You will receive an email notification when admissions responds!",
  chat_UserClosedConversation: "{0} has ended the conversation.",
  chat_ProvideFeedback: "Provide Feedback",
  chat_ProvideFeedbackRequired:
    "Please answer all questions before submitting your feedback!",
  chat_ProvideFeedbackAmbassadorQuestion1:
    "How interested do you think the {0} is in {1} (1-10)",
  chat_ProvideFeedbackAmbassadorQuestion2:
    "Were there any aspects of {0} that the {1} was particularly interested in?",
  chat_ProvideFeedbackAmbassadorQuestion3:
    "Did you or the {0} have any concerns?",
  chat_ProvideFeedbackProspectQuestion1: "What’s your interest level in {0}?",
  chat_ProvideFeedbackProspectQuestion2: "How helpful was this conversation?",
  chat_ProvideFeedbackProspectQuestion3:
    "Do you have any feedback on the conversation or the service?",
  chat_ProvideFeedbackPlaceholder: "Enter feedback here",
  chat_ProvideFeedbackOptionalPlaceholder: "Enter feedback here (optional)",
  chat_SendMessage: "Send Message",
  chat_MessagePlaceholder: "Send a message",
  chat_ReportUser: "Report User",

  inbox_DirectMessages: "Direct Messages",

  navigation_Home: "Home",
  navigation_Messages: "Messages",
  navigation_Outreach: "Outreach",

  userMenu_NotificationSettings: "Notification Settings",
  userMenu_EditProfile: "Edit Profile",
  userMenu_AddUserStory: "Add User Story",
  userMenu_SignOut: "Sign Out",

  watchMyStory_Title: "Watch my Story",
  watchMyStory_Description:
    "Video Stories allow you to share more about yourself. Anything specific you'd like to go more into detail about, you can include here.",

  editProfile_Header: "Edit Profile",
  editProfile_VacationMode: "Vacation Mode",
  editProfile_VacationModeDescription:
    "Turn on/off your visibility on the landing page",
  editProfile_RequestCall: "Request Call",
  editProfile_RequestCallDescription:
    "Activate this button if you want to receive calls",
  editProfile_Details: "Details",
  editProfile_DeletePhoto: "Delete Photo",
  editProfile_ReadMyStory: "Read My Story",
  editProfile_ReadMyStoryInstructions:
    "Stories allow you to share more about yourself. Anything specific you’d like to go more into detail about, you can include here.",
  editProfile_ReadMyStoryExample:
    "Ex: Why did you choose {0}? What was the most important factor when you were considering {0}?",
  editProfile_ReadMyStoryQuestionPlaceholder: "Enter a question here",
  editProfile_ReadMyStoryAnswerPlaceholder: "Enter the answer here",
  editProfile_Error: "Unable to update profile details",
  editProfile_EmailVerificationRequired: "Email verification required",
  editProfile_PendingEmailChange: "Pending email verification for {0}",

  profileEdit_Title: "Edit Your Profile",
  profileEdit_Success: "Successfully updated your profile",

  footer_TutorialModalHeader: "Tutorials",
  footer_ReportModalHeader: "Report",
  footer_ReportModalBlankReportError: "You can't send a blank report",

  inviteSignup_LoadingVideo: "Loading Video...",
  inviteSignup_RecordedVideoAndLeftMessage:
    "{0} has recorded a personalized video and left a message for you!",
  inviteSignup_RecordedVideo: "{0} has recorded a personalized video for you!",
  inviteSignup_LeftMessage: "{0} invited you to message them!",
  inviteSignup_Instructions:
    "Press play to view. To respond, click on the respond button below.",
  inviteSignup_CreateAccountInvitedMessage:
    "Create an account to start messaging {0}",
  inviteSignup_CreateAccountMessage:
    "Create an account to start messaging {0} at {1}",
  inviteSignup_CreateAccountBtn: "Create Account",
  inviteSignup_ModalHeader: "Sign up for PeerPal",
  inviteSignup_ModalBody: "Sign up to connect with {0}, it’s quick and simple!",
  inviteSignup_TOSPrefix: "By signing up, you agree to our",
  inviteSignup_AmbassadorInviteNotFound:
    "This invitation does not exist. Please contact an administrator at {0}.",

  landingPage_Welcome: "Welcome,",
  landingPage_NoProfileText: "No ambassadors to display",
  landingPage_MessageModalHeader: "Message {0}",
  landingPage_MessageModalMessagePlaceholder: "Write a message",
  landingPage_MessageModalButtonSend: "Send",
  landingPage_MessageModalInitialMessage:
    "Send me a message! I'm happy to share insights about {0} and answer any questions you might have!",
  landingPage_MessageModalConfirmation:
    "You will receive an email when {0} responds!",
  landingPage_SearchPlaceholder: "Search for an ambassador",
  landingPage_SearchBtn: "Search",
  landingPage_SearchClearBtn: "Clear",
  landingPage_CallModalHeader: "Request a Call",
  landingPage_CallModalSubtitle: "Please provide some context for your call",
  landingPage_CallModalTopicPlaceholder: "What would you like to discuss?",
  landingPage_CallModalWhenPlaceholder: "When are you available for a call?",
  landingPage_CallModalClose:
    "Are you sure you want to exit? Your request for a call will not be sent.",
  landingPage_CallModalButtonNext: "Next",
  landingPage_ContactInfoModalHeader:
    "Please submit your contact information to receive a call",
  landingPage_ContactInfoModalButtonBack: "BACK",
  landingPage_ContactInfoModalTOSPrefix: "By submitting, you agree to our",
  landingPage_ConfirmationModalHeader:
    "Please leave your contact information to be notified when you receive a response",
  landingPage_ConfirmationModalMessage:
    "Thank you! {0} has been notified of your call request.",
  landingPage_InvalidPhoneNumber:
    "Invalid phone number, a valid phone number is required",
  landingPage_ValidationMissingFields:
    "You need to fill out every field to submit",
  landingPage_ValidationInvalidEmail: "Please enter a valid email",
  landingPage_ValidationEmailInUse:
    "The email you entered is currently in use. Please login to continue messaging if this is your account.",
  landingPage_ProfileMessageBtn: "Send Message",
  landingPage_ProfileRequestCallBtn: "Request Call",
  landingPage_MyStoryModalHeader: "{0}'s Story",
  landingPage_ProfileMyStoryBtn: "Read my story",
  landingPage_ProspectConversationLimit:
    "You have reached the limit of active conversations. To initiate a new conversation please end one of your existing ones.",
  landingPage_AmbassadorConversationLimit:
    "This ambassador is not accepting anymore conversations. Please message another ambassador.",

  modal_Submit: "Submit",

  notificationSettings_Header: "Notification Settings",

  maintenance_Title: "Be a pal!",
  maintenance_Banner: "UNDER CONSTRUCTION",
  maintenance_DescriptionPart1:
    "Please be patient while we work on some routine maintenance.",
  maintenance_DescriptionPart2: "We should be back up ",

  login_CreateAccount: "Create Account",
  login_ResetPasswordPlaceholder: "Enter your email here",
  login_SignInEmail: "Sign in with Email",
  login_SignInGoogle: "Sign in with Google",
  login_SignInMSA: "Sign in with {0}",
  login_LogInPageTitle: "Log in",
  login_LogInPageSubtitle: "Log in to continue your conversations",
  login_LogInFail: "Login failed, please contact our team",
  login_ResendValidationEmailConfirmation:
    "Do you want to resend the verification email?",
  login_VerificationEmailSent:
    "A verification email has been sent. Please check your inbox and spam folder for the email. Click it to start messaging!",
  login_VerificationEmailError:
    "You can't request any more verification emails. Please wait for a few hours before trying again.",
  login_FirstNamePlaceholder: "Your first name",
  login_LastNamePlaceholder: "Your last name",
  login_EmailPlaceholder: "Your email address",
  login_PasswordPlaceholder: "Enter your password",
  login_Login: "Login",
  login_InvalidEmail: "Please enter a valid email to signup",
  login_ResetPassword: "Forgot/Reset Password",
  login_ResetPasswordTitle: "Reset Password",
  login_ResetPasswordFailure: "Email was not sent. Please try again.",
  login_ResetPasswordFailureMSA:
    "You can't reset the password of an MSA account.",
  login_ResetPasswordSuccess:
    "You should have received an email. Please click the link in the email to reset your password.",
  login_ForgotPasswordSubmit: "Submit",
  login_SignUpEmail: "Sign up with Email",
  login_SignUpGoogle: "Sign up with Google",
  login_SignUpMSA: "Sign up with {0}",
  login_FillOutAllFields: "Please fill out all fields",
  login_SignUp: "Sign up",
  login_AmbassadorSubtitle: "Create an account to start answering questions",
  login_ExistingEmailRegistrationError:
    "That email already has an account attached to it. Attempting to log in.",
  login_ProspectSubtitle: "Create an account to continue messaging {0}",
  login_ProspectSubtitleNoInviter:
    "Create an account to continue messaging {0} at {1}",
  login_ProspectCardTitleText: "Continue messaging {0}!",
  login_ExpiredEmailMagic:
    "This link has expired. Please log in once you are redirected to the log in page.",
  login_RecordedVideo:
    "{0} has recorded a personalized video for you! Sign up to respond!",
  login_LeftMessage: "{0} invited you to message them!",
  login_AdminLink: "I am an admin",
  login_InvitationAccepted: "Invitation accepted!",
  login_InvitationAcceptedUnauthorized:
    "Invitation accepted! Login to begin messaging!",
  login_ExpiredInvitationLink: "This link has expired.",
  login_UnauthorizedUserError:
    "User is unauthorized to view this page, please log in with an authorized user",

  signUpProfilePage_100CharacterFieldError:
    "The {0} field can have a max of 100 characters",
  signUpProfilePage_250CharacterFieldError:
    "The {0} field can have a max of 250 characters",

  videoRecording_Record: "Record Video",
  videoRecording_Divider: "or",
  videoRecording_Note:
    "(.mp4, .ogg, and .webm file types supported. Maximum file size: {0}MB)",
  videoRecording_Start: "Record",
  videoRecording_Timeout: "Recording in...",
  videoRecording_Stop: "Stop Recording",
  videoRecording_Delete: "Delete Recording",
  videoRecording_ChangeCameras: "Change Cameras",
  videoRecording_Upload: "Upload Video",
  videoRecording_ErrorMaxFileSize: "File size must not exceed {0}MB",
  videoRecording_UserHidden: "Make Visible on Profile",
  videoRecording_CameraError: "Unable to load camera.",

  validation_Required: "Required",

  modalReportUser_Title: "Do you want to Report this user?",
  modalReportUser_Subtext: "Please provide a reason:",
  modalReportUser_Option0: "Inappropriate content",
  modalReportUser_Option1: "Inappropriate behavior (i.e. too many messages)",
  modalReportUser_Option2: "Fake account",
  modalReportUser_Option3: "Other",
  modalReportUser_Error: "Failed to report user. Please try again.",
  modalReportUser_Required: "Please select a reason and try again.",

  modalInvitesCall_TitleMain: "{0} Call Request",
  modalInvitesCall_TitleFeedback: "Leave Feedback on Your Call",
  modalInvitesCall_TitleMessage: "Send {0} a message",
  modalInvitesCall_PhoneNumber: "Phone Number:",
  modalInvitesCall_Notes: "Notes on {0}:",
  modalInvitesCall_TimeFree: "Best time to reach out:",
  modalInvitesCall_Topic: "{0} wants to talk about:",
  modalInvitesCall_CallFeedbackTitle: "Provide feedback for {0}.",
  modalInvitesCall_CallFeedbackButton: "Submit Feedback",
  modalInvitesCall_MessageTitle: "Having trouble connecting by phone?",
  modalInvitesCall_MessageLabel: "Send {0} a message.",
  modalInvitesCall_MessageButton: "Send Message",

  stepper_ProfileSignup: "Set Up Your Profile",

  stepper_AllStepsComplete: "All steps complete!",
  stepper_Congratulations: "Congratulations!",
  stepper_EditProfile: "You finished setting up your profile",
  stepper_Ok: "Ok!",

  stepWatchMyStory_Error: "Unable to save video Story",

  emailVerification_verified: "{0} has been verified",
  emailVerification_invalidLink: "Invalid or expired email verification",

  error_ProspectConversationLimit: "You have reached your messaging limit",
  error_ProspectCannotSignInYet:
    "Please wait for a email response from an ambassador before signing in.",

  general_Email: "Email",
  general_FirstName: "First Name",
  general_LastName: "Last Name",
  general_Phone: "Phone",
  general_SignIn: "Sign In",
  general_Password: "Password",
  general_Back: "Back",
  general_Save: "Save",
  general_Cancel: "Cancel",
  general_Continue: "Continue",
  general_Skip: "Skip",
  general_TermsOfService: "Terms of Service",
  general_ErrorMessage: "Something went wrong. Please try again later!",
  general_SearchPlaceholder: "Search",
};
