<template>
  <div class="body">
    <div class="container">
      <div class="pref-container">
        <div
          class="pref-item"
          v-for="(group, index) in prefArr"
          v-bind:key="index">
          <div class="title-container">
            <span class="title">{{ group.name }}</span>
            <span class="title-description">{{ group.description }}</span>
          </div>
          <span>
            <input type="checkbox" v-model="group.suppressed" />
          </span>
        </div>
      </div>
      <div class="select-button">
        <button class="addQ smallButton" @click="allTrue">Select All</button>
        <button class="addQ smallButton" @click="allFalse">Unselect All</button>
      </div>
      <button v-if="!submitting" @click="submitPref" class="btn">Submit</button>
      <Loading v-else />
    </div>
  </div>
</template>

<script>
  import {
    getNotificationSettings,
    updateNotifications,
  } from "@/services/api.service.js";
  const api = { getNotificationSettings, updateNotifications };
  import Loading from "./Loading";

  export default {
    components: {
      Loading: Loading,
    },
    data() {
      //different syntax of data: function() {}
      return {
        email: "",
        errorMessage: "",
        token: "",
        uid: "",
        prefArr: [],
        valid: true,
        submitting: false,
      };
    },

    async created() {
      if (this.$route.query.tok) {
        try {
          let arr = await api.getNotificationSettings(this.$route.query.tok);
          this.prefArr = JSON.parse(JSON.stringify(arr.data.groups));
        } catch (e) {
          this.$router.push({ name: "Home" });
        }
        for (let element of this.prefArr) {
          element.suppressed = !element.suppressed;
        }
      } else {
        this.$router.push({ name: "Home" });
      }
    },

    methods: {
      allTrue() {
        for (let i of this.prefArr) {
          i.suppressed = true;
        }
      },

      allFalse() {
        for (let i of this.prefArr) {
          i.suppressed = false;
        }
      },

      submitPref: async function () {
        this.submitting = true;
        let sendArr = JSON.parse(JSON.stringify(this.prefArr));
        for (let element of sendArr) {
          element.suppressed = !element.suppressed;
        }
        let res = await api
          .updateNotifications(this.$route.query.tok, sendArr)
          .catch((e) => {
            console.log(e.response);
          });
        this.submitting = false;

        if (res.status == 200) {
          this.$toast.success("Email Preferences Updated");
        } else {
          this.$toast.error(
            "There was a problem updating your preferences, please try again later"
          );
        }
      },
    },
  };
</script>

<style scoped>
  .container {
    max-width: 400px;
    min-width: 200px;
    max-height: 100vh;
    background-color: white;
    margin: 80px auto 80px auto;
    padding: 25px 50px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  }
  .container h2 {
    font-size: 2em;
    margin: 20px 0 0 0;
  }
  .container h3 {
    margin: 0 0 20px 0;
    font-weight: 525;
  }
  .container .link {
    color: #007aff;
    border: 0;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
  }
  .pref-container {
    display: flex;
    flex-direction: column;
  }
  .pref-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .title-description {
    font-size: 0.8em;
  }
  .title {
    font-size: 1.2em;
  }
  .btn {
    height: 40px;
    padding: 10px 15px 10px 15px;
    background-color: var(--accent-color);
    color: white;
    border: 0;
    border-radius: 20px;
    font-size: 15px;
    margin: 15px 0;
  }
  .btn:focus {
    outline: none;
  }
  .addQ:focus {
    outline: none;
  }
  .addQ {
    margin: 0px;
    font-size: 13px !important;
    height: 20px !important;
    padding: 4px;
    margin-right: 5%;
    padding-top: 2%;
    padding-bottom: 6%;
    border-radius: 5px;
  }
  button.addQ:hover {
    background-color: lightgrey !important;
    border-radius: 5px;
  }
  .smallButton {
    background-color: white;
    border: 0px;
    margin-left: 5%;
    font-size: 16px !important;
  }
  @media (max-width: 460px) {
    .container {
      margin-left: 10px;
      margin-right: 10px;
      padding: 25px 25px;
      max-width: calc(100% - 72px);
    }
  }
</style>
