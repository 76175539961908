<template>
  <common-modal @close="$emit('close')">
    <template #header>
      <input
        type="checkbox"
        :checked="allSelected"
        @change="clickSelectAll" />{{ $lang.notificationSettings_Header }}
    </template>

    <Loading v-if="loading" />
    <div v-else class="pref-container">
      <div
        class="pref-item"
        v-for="(group, index) in notifications"
        v-bind:key="index">
        <span class="pref-checkbox">
          <input type="checkbox" v-model="group.active" />
        </span>
        <div class="title-container">
          <div class="title">{{ group.name }}</div>
          <div class="title-description">{{ group.description }}</div>
        </div>
      </div>
    </div>

    <template #footer>
      <common-button
        icon="content-save"
        :disabled="loading"
        :loading="submitting"
        @click="save">
        {{ $lang.general_Save }}
      </common-button>
    </template>
  </common-modal>
</template>

<script>
  import Loading from "./Loading";
  import {
    getNotificationSettings,
    updateNotifications,
  } from "@/services/api.service.js";
  const api = { getNotificationSettings, updateNotifications };

  export default {
    name: "NotificationSettingsModal",
    components: {
      Loading,
    },
    props: {
      user: {
        type: Object,
      },
    },
    data() {
      return {
        loading: true,
        submitting: false,
        notifications: [],
      };
    },
    computed: {
      allSelected() {
        return (
          this.notifications.length > 0 &&
          this.notifications.every((group) => group.active)
        );
      },
    },
    created() {
      api
        .getNotificationSettings(this.user._id)
        .then((res) => {
          const notifications = res.data.groups;

          for (let group of notifications) {
            group.active = !group.suppressed;
          }

          this.notifications = notifications;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    methods: {
      clickSelectAll() {
        const active = !this.allSelected;
        for (const group of this.notifications) {
          group.active = active;
        }
      },
      save() {
        this.submitting = true;
        const payload = [];

        for (let group of this.notifications) {
          payload.push({
            id: group.id,
            suppressed: !group.active,
          });
        }

        api
          .updateNotifications(this.user._id, payload)
          .then(() => {
            this.$emit("close");
          })
          .catch((e) => {
            console.error(e);
            this.$toast.error(this.$lang.general_ErrorMessage);
          })
          .finally(() => {
            this.submitting = false;
          });
      },
    },
  };
</script>

<style scoped>
  .pref-container {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.25em;
  }
  .pref-item {
    display: flex;
    flex-direction: row;
  }
  .pref-checkbox {
    margin-top: 0.2rem;
  }
  .pref-item .title-container {
    font-size: 14px;
    text-align: left;
  }
  .title {
    font-weight: 500;
    line-height: 20px;
  }
  input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    margin: 0 1rem 0 0;
  }
</style>
