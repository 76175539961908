<template>
  <div class="chat-container">
    <template v-if="conversation">
      <div class="chat-header" ref="chatHeader">
        <div class="user-info">
          <ppicon
            class="back-arrow icon-button"
            name="arrow-left"
            @click.native="returnToInbox" />
          <div class="profile-pic-container">
            <img :src="recipientImage" alt="Person's picture" />
          </div>

          <a
            class="user-name-container"
            :href="conversation.orgUrl"
            rel="noreferrer"
            target="_blank">
            <div class="user-name">{{ conversation.recipientName }}</div>
            <div class="user-organization" v-if="orgDisplayName">
              {{ orgDisplayName }}
            </div>
          </a>
        </div>

        <div class="chat-header-actions">
          <a
            class="org-logo-container"
            :href="conversation.orgUrl"
            rel="noreferrer"
            target="_blank"
            :style="{ 'background-color': conversation.orgPrimaryColor }"
            ><img class="org-logo" :src="orgLogoUrl" v-if="orgLogoUrl"
          /></a>

          <common-dropdown
            :items="dropdownOptions"
            @menu-click="menuClick"
            @toggle="menuShown = $event"
            class="message-menu">
          </common-dropdown>
        </div>
      </div>

      <Scrollbar
        class="chat-body"
        alwaysOverflowY
        ref="scrollbar"
        @loaded="moveScrollbarToEnd"
        :style="{ 'max-height': chatBodyMaxHeight }">
        <div
          class="chat-day-container"
          v-for="day in timestampGroupedMessages"
          :key="`${day.date}-chat-group`">
          <p class="day-group-header">{{ toPrettyDateString(day.date) }}</p>

          <div
            :class="[
              'message-container',
              { 'recipient-message': isRecipientMessage(message) },
            ]"
            v-for="message in day.messages"
            :key="message.id">
            <div
              class="profile-pic-container"
              :title="
                isRecipientMessage(message)
                  ? conversation.recipientName
                  : `${user.first} ${user.last}`
              ">
              <img :src="getMessageImage(message)" alt="Person's picture" />
            </div>

            <div class="message-wrapper">
              <p class="message" v-html="message.body" />
              <p class="message-timestamp">
                {{ toPrettiestPrincessOfAllTimeString(message.createdDate) }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="closed-conversation-wrapper"
          v-if="displayClosedConversationText">
          <label>{{ displayClosedConversationText }}</label>
        </div>
      </Scrollbar>

      <div class="chat-footer-container">
        <div class="chat-footer">
          <textarea
            class="message-input"
            v-model="messageText"
            :placeholder="$lang.chat_MessagePlaceholder"
            @input="adjustSendMessageHeight"
            :style="{ height: messageInputHeight }"
            :disabled="conversationDisabled"
            ref="messageBox" />

          <ppicon
            name="send"
            class="icon-button"
            @click.native="sendMessage"
            :title="$lang.chat_SendMessage" />
        </div>
      </div>

      <common-modal
        size="lg"
        :title="this.contactLabel || $lang.chat_AskAdmissions"
        v-if="showAskAdmissionsModal"
        @close="toggleAskAdmissionsModal"
        @submit="sendAskAdmissions">
        <div class="ask-admissions-container">
          <textarea
            type="text"
            :placeholder="$lang.chat_AskAdmissionsPlaceholder"
            class="input"
            v-model="askAdmissionsText"></textarea>
        </div>
      </common-modal>
      <ModalReportUser
        v-if="showModalReportUser"
        :recipientID="conversation.recipientID"
        @close="showModalReportUser = false" />
      <ModalChatFeedback
        v-if="showFeedbackModal"
        :questions="questions"
        :conversationId="selectedConvoId"
        :isAmbassador="isCurrentParent"
        @close="showFeedbackModal = false" />
    </template>
  </div>
</template>

<script>
  import {
    readChat,
    createAdminConversation,
    getConversation,
    blockUser,
    closeConversation,
    getConversationFeedbackQuestions,
  } from "@/services/api.service.js";
  const api = {
    readChat,
    createAdminConversation,
    getConversation,
    blockUser,
    closeConversation,
    getConversationFeedbackQuestions,
  };
  import io from "socket.io-client";
  import commonFuncs from "../common/common";
  import { mapState, mapGetters } from "vuex";
  import ModalReportUser from "@/components/modal/ModalReportUser.vue";
  import ModalChatFeedback from "@/components/modal/ModalChatFeedback.vue";

  export default {
    components: {
      ModalReportUser,
      ModalChatFeedback,
    },
    props: {
      selectedConvoId: {
        type: String,
      },

      ready: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        conversation: null,
        messageText: "",
        socket: null,
        menuShown: false,
        showFeedbackModal: false,
        feedbackAnswers: [],
        showAskAdmissionsModal: false,
        showModalReportUser: false,
        askAdmissionsText: "",
        messageBoxDefaultHeight: 37,
        messageBoxHeight: 37,
        maxTextBoxHeight: 300,
        headerHeight: 138,
        questions: [],
      };
    },

    computed: {
      messages() {
        let messages = [];

        if (this.conversation?.chats) {
          messages = this.conversation.chats;
        }

        return messages;
      },

      timestampGroupedMessages() {
        //Messages are grouped by day
        let messages = [];

        let currentGroupDate = null;
        let currentGrouping = [];

        for (let i = 0; i < this.messages.length; i++) {
          if (!currentGroupDate) {
            currentGroupDate = new Date(this.messages[i].createdDate);
          }

          let messageDate = new Date(this.messages[i].createdDate);

          if (
            currentGroupDate.getMonth() !== messageDate.getMonth() ||
            currentGroupDate.getFullYear() !== messageDate.getFullYear() ||
            currentGroupDate.getDate() !== messageDate.getDate()
          ) {
            // New grouping, push the old one
            if (currentGrouping.length) {
              messages.push({
                date: currentGroupDate,
                messages: currentGrouping,
              });
              currentGrouping = [];
              currentGroupDate = messageDate;
            }
          }

          currentGrouping.push(this.messages[i]);
        }

        if (currentGrouping.length) {
          messages.push({
            date: currentGroupDate,
            messages: currentGrouping,
          });
        }

        return messages;
      },

      blockUserText() {
        return this.conversation.didBlock
          ? this.$lang.chat_UnblockUser
          : this.$lang.chat_BlockUser;
      },

      conversationDisabled() {
        return (
          this.conversation.beenBlocked || this.conversation.recipientArchived
        );
      },

      displayClosedConversationText() {
        if (this.conversation?.activeConvo) {
          return "";
        }

        return !this.conversation?.activeConvo &&
          this.conversation?.closedByUser
          ? this.$lang.chat_UserClosedConversation.format(
              `${this.conversation?.closedByUser.first} ${this.conversation?.closedByUser.last}`
            )
          : this.$lang.chat_UserClosedConversation.format(
              this.$lang.chat_AdminUser
            );
      },

      closeConversationTitle() {
        return this.conversation?.activeConvo
          ? this.$lang.chat_CloseConvoTitle
          : this.$lang.chat_DisabledCloseConvoTitle;
      },

      dropdownOptions() {
        const options = [
          {
            label: this.blockUserText,
            icon: "block-helper",
            action: this.blockUser,
          },
        ];

        if (this.contactIsActive) {
          options.push({
            label: this.contactLabel || this.$lang.chat_AskAdmissions,
            icon: "message-text",
            action: this.toggleAskAdmissionsModal,
          });
        }

        if (!this.conversation?.isAdminConvo && this.user?.organization) {
          options.push({
            label: this.$lang.chat_ReportUser,
            icon: "flag-variant",
            action: () => {
              this.showModalReportUser = true;
            },
          });
        }

        if (
          this.orgName !== "Greenwood College School" &&
          this.questions.length > 0
        ) {
          //TODO: Change this to feature flag instead of this neanderthal-level garbage
          options.push({
            label: this.$lang.chat_ProvideFeedback,
            icon: "message-alert",
            action: () => {
              this.showFeedbackModal = true;
            },
          });
        }
        if (this.conversation.activeConvo) {
          options.push({
            label: this.$lang.chat_CloseConversation,
            icon: "close-circle",
            action: this.closeConversation,
          });
        }

        return options;
      },

      orgLogoUrl() {
        return commonFuncs.optimizeOrgLogoUrl(this.conversation.orgLogo);
      },

      userProfileImage() {
        return (
          commonFuncs.optimizeProfileImageUrl(this.user.profileImageUrl) ||
          require("@/assets/profile.svg")
        );
      },

      recipientImage() {
        return (
          commonFuncs.optimizeProfileImageUrl(
            this.conversation?.recipientImage
          ) || require("@/assets/profile.svg")
        );
      },

      messageInputHeight() {
        return this.messageBoxHeight + "px";
      },

      chatBodyMaxHeight() {
        return `calc(100% - ${this.headerHeight + this.messageBoxHeight}px)`;
      },

      ...mapState([
        "organizationId",
        "orgName",
        "orgDisplayName",
        "profileFields",
        "contactLabel",
        "contactIsActive",
        "isCurrentParent",
        "token",
        "uid",
        "user",
        "conversationFeedbackQuestions",
      ]),

      ...mapGetters(["prospSingularUserType"]),
    },

    watch: {
      ready: {
        immediate: true,
        handler(val) {
          //Wait for inbox socket to connect
          if (val && this.selectedConvoId) {
            this.loadConversation();
          }
        },
      },

      selectedConvoId: {
        immediate: true,
        handler(val) {
          if (val && this.ready) {
            this.loadConversation();
          }
        },
      },
    },

    mounted() {
      window.addEventListener("resize", this.onResize);
      this.onResize();
      getConversationFeedbackQuestions(this.organizationId).then((response) => {
        const feedbackQuestions = response.data;
        this.questions = this.isCurrentParent
          ? feedbackQuestions.conversationFeedbackQuestionsAmbassador
          : feedbackQuestions.conversationFeedbackQuestionsProspect;
      });
    },

    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
      this.closeSocket();
    },

    methods: {
      loadConversation() {
        api
          .getConversation(this.selectedConvoId)
          .then((response) => {
            this.conversation = response.data;

            if (!this.socket) {
              // Only initialize the socket on the first conversation load
              this.initiateSocket();
            } else if (this.selectedConvoId) {
              // Enter conversation if the conversation context switched
              this.socket.emit("enter conversation", this.selectedConvoId);
              this.socket.emit("client read", {
                conversationID: this.selectedConvoId,
                uid: this.uid,
                recipientID: this.conversation?.recipientID,
              });
            }

            // read endpoint (changes isRead to true for recipient's messages)
            api
              .readChat(this.selectedConvoId, this.conversation.recipientID)
              .catch((err) => {
                console.log(err);
              });
            this.getHeaderHeight();
            this.moveScrollbarToEnd();
          })
          .catch((error) => {
            console.error(error);
          });
      },

      initiateSocket() {
        //setting up sockets and listeners, should put methods in socket.on to do certain things
        this.socket = io(process.env.VUE_APP_ENDPOINT, {
          query: { token: this.token },
        });

        this.socket.on("refresh messages", (data) => {
          const messageText = commonFuncs.createTextLinks(data.composedMessage);
          if (this.uid !== data.authorUserId) {
            // read endpoint (changes isRead to true for recipient's messages)
            api
              .readChat(this.selectedConvoId, this.conversation?.recipientID)
              .catch((err) => {
                console.log(err);
              });

            this.socket.emit("client read", {
              conversationID: this.selectedConvoId,
              uid: this.uid,
              recipientID: this.conversation?.recipientID,
            });
          }

          this.conversation.activeConvo = true;

          //adding message to array
          this.conversation.chats.push({
            ...data,
            body: messageText,
          });

          this.moveScrollbarToEnd();
        });

        this.socket.on("disconnect", (reason) => {
          if (reason === "forced close" || reason === "io client disconnect") {
            return;
          }

          this.socket.connect();
          this.socket.emit("enter conversation", this.selectedConvoId);
        });

        this.socket.emit("enter conversation", this.selectedConvoId);
        this.socket.emit("client read", {
          conversationID: this.selectedConvoId,
          uid: this.uid,
          recipientID: this.conversation?.recipientID,
        });
      },

      closeSocket() {
        if (this.socket) {
          this.socket.emit("leave conversation", this.selectedConvoId);
          this.socket.disconnect();
          this.socket = null;
        }
      },

      resetSocket() {
        this.closeSocket();
        this.initiateSocket();
      },

      sendMessage() {
        if (!this.messageText) {
          return;
        }

        let sentMessage = this.messageText;
        sentMessage = sentMessage.replace("<", "");
        sentMessage = sentMessage.replace(">", "");

        if (!this.socket || !this.socket.connected) {
          // if socket isn't connected, reconnect
          this.resetSocket();
        }

        // send message
        this.socket.emit("new message", {
          conversationID: this.selectedConvoId,
          composedMessage: sentMessage,
          authorUserId: this.uid,
          recipientID: this.conversation.recipientID,
          schoolName: this.orgName,
          recipientOrgName: this.conversation.recipientOrgName,
        });

        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude.getInstance().logEvent("MESSAGE_SENT");
        }

        this.messageText = "";
        this.adjustSendMessageHeight();
      },

      blockUser() {
        let confirmStatus;
        if (this.conversation?.didBlock) {
          confirmStatus = confirm(this.$lang.chat_UnblockUserText);
        } else {
          confirmStatus = confirm(this.$lang.chat_BlockUserText);
        }

        if (!confirmStatus) {
          return;
        }

        //put request to block or unblock user
        api
          .blockUser(this.conversation.recipientID)
          .then(() => {
            this.conversation.didBlock = !this.conversation.didBlock;
          })
          .catch(() => {
            this.$toast.error("Failed to block user. Please try again.");
          });
      },

      closeConversation() {
        if (this.conversation?.activeConvo) {
          api
            .closeConversation(this.selectedConvoId)
            .then((res) => {
              if (res?.status === 200) {
                this.conversation.closedByUser = res?.data?.closedByUser;
                this.conversation.activeConvo = false;
              } else {
                this.$toast.error(this.$lang.general_ErrorMessage);
              }
            })
            .catch((e) => {
              console.error(e);
              this.$toast.error(this.$lang.general_ErrorMessage);
            });
        }
      },

      menuClick(option) {
        this.menuShown = false;

        if (option.action) {
          option.action();
        }
      },

      isRecipientMessage(message) {
        return message.authorUserId !== this.uid;
      },

      getMessageImage(message) {
        if (this.isRecipientMessage(message)) {
          return this.recipientImage;
        }

        return this.userProfileImage;
      },

      toggleFeedbackModal() {
        this.showFeedbackModal = !this.showFeedbackModal;
      },

      toggleAskAdmissionsModal() {
        this.showAskAdmissionsModal = !this.showAskAdmissionsModal;

        if (!this.showAskAdmissionsModal) {
          this.askAdmissionsText = "";
        }
      },

      sendAskAdmissions() {
        if (!this.askAdmissionsText) {
          return;
        }

        api
          .createAdminConversation(this.orgName, this.askAdmissionsText)
          .then(() => {
            this.$toast.success(this.$lang.chat_AskAdmissionsSuccess);
            this.toggleAskAdmissionsModal();
          })
          .catch((err) => {
            console.log(err);
          });
      },

      moveScrollbarToEnd() {
        this.$nextTick(() => {
          const firstChild = this.$refs.scrollbar?.$el?.firstChild;

          if (firstChild) {
            firstChild.scrollTop = firstChild.scrollHeight;
          }
        });
      },

      adjustSendMessageHeight() {
        this.messageBoxHeight = this.messageBoxDefaultHeight;

        this.$nextTick(() => {
          const height = this.$refs.messageBox.scrollHeight;
          this.messageBoxHeight =
            height > this.maxTextBoxHeight ? this.maxTextBoxHeight : height;
        });
      },

      returnToInbox() {
        this.$router.push({ name: "Messaging" });
      },

      getHeaderHeight() {
        this.headerHeight = this.$refs.chatHeader?.scrollHeight + 73 || 138;
      },

      onResize() {
        this.getHeaderHeight();
      },

      toPrettyDateString: commonFuncs.toPrettyDateString,
      toPrettiestPrincessOfAllTimeString:
        commonFuncs.toPrettiestPrincessOfAllTimeString,
    },
  };
</script>

<style lang="scss" scoped>
  .chat-container {
    background-color: $grey-250;
    border-radius: 12px;
    flex: 2;
  }

  .chat-header {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $grey-650;
    border-radius: 8px;
    margin: 10px;
  }

  .chat-header-actions {
    display: flex;
    align-items: center;

    .org-logo-container {
      height: 24px;
      padding: 8px;
      margin-right: 18px;
      border-radius: 8px;
      width: 180px;

      @media screen and (max-width: $md) {
        width: 100px;
      }
    }

    .org-logo {
      height: 100%;
    }
  }

  .user-info {
    display: flex;
    align-items: center;

    .profile-pic-container {
      height: 40px;
      width: 40px;
      flex: 0 0 auto;

      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .user-name-container {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-decoration: none;
    pointer-events: none;
    margin-right: 5px;
  }

  .user-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $primary-text;
    cursor: pointer;
  }

  .user-organization {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    cursor: pointer;
    color: $primary-text;
    text-align: left;
  }

  .chat-body {
    height: calc(100% - 175px);
    margin: 16px 11px;
  }

  .day-group-header {
    font-size: 12px;
    line-height: 14px;
  }

  .message-container {
    display: flex;
    flex-direction: row-reverse;

    .message-wrapper {
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      word-break: break-word;
    }

    .message {
      background-color: $black-150;
      padding: 8px;
      min-width: 250px;
      max-width: 80%;
      border-radius: 8px;
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 4px;
      white-space: break-spaces;
    }

    .message-timestamp {
      text-align: right;
      font-size: 12px;
      line-height: 14px;
      margin-top: 0;
      margin-left: 8px;
    }

    &.recipient-message {
      flex-direction: row;

      .message-wrapper {
        margin-left: 8px;
        align-items: flex-start;
      }

      .message-timestamp {
        text-align: left;
      }

      .message {
        background-color: $white;
      }
    }
  }

  .message-container .profile-pic-container img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-top: 15px;
    object-fit: cover;
  }

  .closed-conversation-wrapper {
    padding: 8px;
    background-color: $grey-650;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 8px;
    margin: 27px 32px;
  }

  .chat-footer-container {
    margin: 9px;
    padding-top: 15px;
    border-top: 1px solid $black-150;
  }

  .chat-footer {
    display: flex;
    align-items: center;

    .pp-icon {
      font-size: 21px;
    }
  }

  .message-input {
    background: $white;
    border-radius: 24px;
    border: none;
    flex: 1;
    margin-right: 12px;
    outline: none;
    resize: none;
    word-wrap: break-word;
    padding: 10px 20px;
    box-sizing: border-box;
    min-height: 37px;
  }

  .ask-admissions-container {
    textarea {
      display: block;
      width: calc(100% - 40px);
      height: 90px;
      margin: 0 20px;
      padding: 10px;
      resize: none;
      font-size: 15px;
      border-radius: 10px;
      border: 0;
      box-sizing: border-box;
      background-color: $grey-100;
      word-wrap: break-word;
      outline: none;
    }
  }

  .back-arrow {
    display: none;
    margin-right: 10px;

    @media screen and (max-width: $sm) {
      display: block;
    }
  }

  @media screen and (max-width: 850px) {
    .message-container .message {
      min-width: 170px;
    }
  }

  @media screen and (max-width: $sm) {
    .back-arrow {
      display: block;
    }

    .chat-container {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0px;
      border-radius: 0;
    }

    .message-container .message {
      min-width: 130px;
    }

    .org-logo-container {
      display: none;
    }

    .user-name-container {
      pointer-events: all;

      .user-organization {
        color: $blue-400;
      }
    }
  }
</style>
