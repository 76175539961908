import Vue from "vue";
import Router from "vue-router";
import LoginSignupPage from "../components/LoginSignupPage.vue";
import MessagingPage from "../components/MessagingPage.vue";
import LandingPage from "../components/LandingPage.vue";
import FAQsPage from "../components/FAQsPage.vue";
import ToSPage from "../components/ToSPage.vue";
import ResetPage from "../components/ResetPassword.vue";
import EmailVerifPage from "../components/EmailVerifPage.vue";
import GoogleRedirectTo from "../components/GoogleRedirectTo.vue";
import GoogleRedirectFrom from "../components/GoogleRedirectFrom.vue";
import LoadingPage from "../components/LoadingPage.vue";
import InvitesPage from "../components/InvitesPage.vue";
import BlackBaudOne from "../components/BlackBaudOne.vue";
import Veracross from "../components/Veracross.vue";
import Unsubscribe from "../components/Unsubscribe";
import SignupProfilePage from "@/components/SignupProfilePage.vue";
import MaintenancePage from "@/components/MaintenancePage.vue";
import ProfileEdit from "@/components/profile/ProfileEdit.vue";
import store from "@/store/store.js";
import roles from "@/constants/roles.js";
import { initRouteGuard } from "./route-guard";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: LandingPage,
    },
    {
      path: "/loginsignup",
      name: "Login",
      props: true,
      component: LoginSignupPage,
    },
    {
      // TODO: remove this after transition phase (9/1/2022)
      path: "/invitelogin",
      redirect: "loginsignup",
    },
    {
      path: "/profile",
      name: "Signup Profile Page",
      component: SignupProfilePage,
      meta: { permissions: [roles.AMBASSADOR, roles.PROSPECT] },
    },
    {
      path: "/messaging",
      name: "Messaging",
      props: true,
      component: MessagingPage,
      meta: { permissions: [roles.AMBASSADOR, roles.PROSPECT] },
    },
    {
      path: "/faqs",
      name: "FAQs",
      component: FAQsPage,
    },
    {
      path: "/terms",
      name: "Terms of Service",
      component: ToSPage,
    },
    {
      path: "/resetPW",
      name: "Reset Password",
      component: ResetPage,
    },
    {
      path: "/emailVerification",
      name: "Verify Email",
      component: EmailVerifPage,
    },
    {
      path: "/googleRedirectTo",
      name: "Google Redirect To",
      component: GoogleRedirectTo,
    },
    {
      path: "/googleRedirectFrom",
      name: "Google Redirect From",
      component: GoogleRedirectFrom,
    },
    {
      path: "/loading",
      name: "Loading",
      props: true,
      component: LoadingPage,
    },
    {
      path: "/invites",
      name: "Invites",
      props: true,
      component: InvitesPage,
      meta: { permissions: [roles.AMBASSADOR] },
    },
    {
      path: "/blackbaud",
      name: "BlackBaud Handler",
      component: BlackBaudOne,
    },
    {
      path: "/veracross",
      name: "Veracross",
      component: Veracross,
    },
    {
      path: "/unsubscribeEmail",
      name: "Unsubscribe",
      component: Unsubscribe,
    },
    {
      path: "/profile-edit",
      name: "ProfileEdit",
      component: ProfileEdit,
      meta: { permissions: [roles.AMBASSADOR, roles.PROSPECT] },
    },
    {
      path: "/maintenance",
      name: "Maintenance",
      component: MaintenancePage,
    },
  ],

  scrollBehavior: (to, from, savedPosition) =>
    new Promise((resolve) => {
      // Scroll up on page change
      const position = savedPosition || {};
      if (!savedPosition) {
        if (to.hash) {
          position.selector = to.hash;
        } else {
          position.x = 0;
          position.y = 0;
        }
      }

      router.app.$once("triggerScroll", () => {
        router.app.$nextTick(() => resolve(position));
      });
    }),
});

initRouteGuard(router, store);

export default router;
