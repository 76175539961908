<template>
  <div class="profile-container">
    <h2 class="profile-title">{{ $lang.editProfile_Details }}</h2>
    <div class="profile-image">
      <button class="profile-image-button" @click="selectImage">
        <img
          v-if="!currentUser.profileImage && !currentUser.profileImageUrl"
          src="../assets/profile.svg"
          alt="profile image"
          class="profile-image-preview" />
        <img
          v-else
          :src="currentUser.profileImage || currentUser.profileImageUrl"
          alt="profile image"
          class="profile-image-preview" />

        <ppicon name="camera" class="profile-image-icon" />

        <input
          type="file"
          ref="file"
          @change="fileSelected($event)"
          accept="image/*"
          class="profile-image-file" />
      </button>

      <button @click="deleteImage">{{ $lang.editProfile_DeletePhoto }}</button>
    </div>

    <div class="profile-names">
      <common-input
        name="fname"
        v-model="currentUser.first"
        @dirty="$emit('dirty', true)"
        :label="$lang.general_FirstName"
        :error="errors.first" />
      <common-input
        name="lname"
        v-model="currentUser.last"
        @dirty="$emit('dirty', true)"
        :label="$lang.general_LastName"
        :error="errors.last" />
    </div>

    <div style="position: relative">
      <common-input
        name="email"
        v-model="currentUser.email"
        @dirty="$emit('dirty', true)"
        :label="$lang.general_Email"
        :message="emailMessage" />
    </div>

    <div v-if="user.organization" class="profile-fields">
      <common-input
        v-for="(field, i) of fields"
        @dirty="$emit('dirty', true)"
        :key="i"
        :value="currentUser.profile[i]"
        @input="($event) => onUpdateProfile($event, i)"
        :label="field"
        :rows="3"
        :maxLength="profileMaxLength" />

      <div class="profile-settings">
        <div>
          <div class="profile-setting">
            <label class="profile-label" for="vacation-mode">
              {{ $lang.editProfile_VacationMode }}
            </label>
            <common-toggle
              id="vacation-mode"
              v-model="currentUser.isOnVacation"
              @dirty="$emit('dirty', true)"
              aria-describedby="vacation-description" />
          </div>
          <p id="vacation-description" class="profile-description">
            {{ $lang.editProfile_VacationModeDescription }}
          </p>
        </div>

        <div>
          <div class="profile-setting">
            <label class="profile-label" for="request-call">
              {{ $lang.editProfile_RequestCall }}
            </label>
            <common-toggle
              id="request-call"
              v-model="currentUser.requestCall"
              @dirty="$emit('dirty', true)"
              aria-describedby="request-call-description" />
          </div>
          <p id="request-call-description" class="profile-description">
            {{ $lang.editProfile_RequestCallDescription }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import commonFuncs from "@/common/common";

  export default {
    props: {
      user: Object,
      profileFields: Array,
    },
    data() {
      return {
        currentUser: {},
        errors: {},
        profileMaxLength: 250,
        pendingEmailChange: "",
      };
    },
    computed: {
      emailMessage() {
        if (this.currentUser.email !== this.user.email) {
          return this.$lang.editProfile_EmailVerificationRequired;
        } else if (this.user.newEmail) {
          return this.$lang.editProfile_PendingEmailChange.format(
            this.user.newEmail
          );
        }
        return "";
      },
      fields() {
        return this.profileFields.filter((field) => field);
      },
    },
    watch: {
      currentUser: {
        deep: true,
        handler() {
          const results = this.validate();
          if (results.valid) {
            this.$emit("update", this.currentUser);
          }
          this.$emit("complete", results.complete);
        },
      },
    },
    created() {
      this.currentUser = {
        first: this.user.first,
        last: this.user.last,
        email: this.user.email,
        isOnVacation: this.user.isOnVacation || false,
        requestCall: this.user.requestCall || false,
        profileImage: null,
        profileImageUrl: commonFuncs.optimizeProfileImageUrl(
          this.user.profileImageUrl
        ),
        profile: this.user?.profile ?? new Array(this.fields).fill(""),
      };

      if (this.user.organization) {
        if (!this.currentUser.profile) {
          this.currentUser.profile = [];
        }

        for (const prop in this.profileFields) {
          if (!this.currentUser.profile[prop]) {
            this.currentUser.profile[prop] = "";
          }
        }
      }
    },
    methods: {
      validate() {
        let valid = true;
        let complete = true;
        this.errors = {};

        if (this.currentUser.first.length === 0) {
          this.errors.first = this.$lang.validation_Required;
          valid = false;
          complete = false;
        }
        if (this.currentUser.last.length === 0) {
          this.errors.last = this.$lang.validation_Required;
          valid = false;
          complete = false;
        }

        if (this.currentUser.profile) {
          // profile questions are null for prospects
          for (const [index, field] of this.currentUser.profile.entries()) {
            if (!field.length && this.profileFields[index]?.length) {
              complete = false;
            }
            if (field.length > this.profileMaxLength) {
              valid = false;
              break;
            }
          }
        }
        return { valid, complete };
      },
      selectImage() {
        this.$refs.file.click();
      },
      fileSelected(event) {
        if (event.target.files.length === 0) return;

        const image = event.target.files[0];
        const fileSize = event.target.files[0].size;
        const reader = new FileReader();

        // downsize and compress if image > 100 KB
        if (fileSize > 100000) {
          reader.readAsArrayBuffer(image);
          reader.onload = (event) => {
            const blob = new Blob([event.target.result]);
            window.URL = window.URL || window.webkitURL;
            const blobURL = window.URL.createObjectURL(blob);
            const image = new Image();
            image.src = blobURL;

            image.onload = () => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              const SIZE = 200;

              // dimensions to minimum of SIZE
              if (image.width > SIZE && image.height > SIZE) {
                if (image.width < image.height) {
                  let ratio = SIZE / image.width;
                  canvas.width = SIZE;
                  canvas.height = ratio * image.height;
                } else {
                  let ratio = SIZE / image.height;
                  canvas.width = ratio * image.width;
                  canvas.height = SIZE;
                }
              } else {
                canvas.width = image.width;
                canvas.height = image.height;
              }
              ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
              this.currentUser.profileImage = canvas.toDataURL("image/jpeg");
            };
          };
        } else {
          reader.readAsDataURL(image);
          reader.onload = (event) => {
            this.currentUser.profileImage = event.target.result;
          };
        }
        this.$emit("dirty", true);
      },
      deleteImage() {
        this.currentUser.profileImage = null;
      },
      onUpdateProfile(value, index) {
        const updatedProfile = [...this.currentUser.profile];
        updatedProfile[index] = value;
        this.currentUser.profile = updatedProfile;
      },
    },
  };
</script>

<style scoped lang="scss">
  .profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .profile-title {
    margin-bottom: 2rem;
  }
  .profile-image {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .profile-image-button {
    position: relative;
    align-self: center;
    padding: 0;
    margin: 0.5rem;

    .profile-image-icon {
      position: absolute;
      width: 16px;
      height: 16px;
      padding: 4px;
      display: grid;
      place-items: center;
      right: 5px;
      bottom: 5px;
      color: $white;
      background-color: var(--accent-color);
      border-radius: 50%;
    }
  }
  .profile-image-preview {
    display: block;
    width: 114px;
    height: 114px;
    border-radius: 50%;
    align-self: center;
    object-fit: cover;
  }
  .profile-image-file {
    display: none;
  }
  .profile-names {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .profile-names > * {
    flex: 1 0 250px;
  }
  .profile-settings {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  .profile-setting {
    display: flex;
    gap: 1rem;
  }
  .profile-label {
    color: var(--accent-color);
    font-weight: 500;
    line-height: 1;
    display: flex;
    align-items: center;
  }
  .profile-description {
    font-size: 12px;
    color: $grey-600;
    text-align: left;
  }

  @media (max-width: $xs) {
    .profile-settings {
      grid-template-columns: 1fr;
    }
    .profile-names {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
</style>
