const axios = require("axios");

const http = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
});

// Login/Registration

let loggedIn = false;

export function setToken(token) {
  if (token) {
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    loggedIn = true;
  }
}

export function removeToken() {
  delete http.defaults.headers.common["Authorization"];
  loggedIn = false;
}

export async function login(data) {
  return http.post("/users/authenticate", data).then((res) => {
    setToken(res.data.token);
    return res;
  });
}

export function logout() {
  removeToken();
}

export function forgotPassword(email) {
  return http.post("users/forgotPassword", {
    email,
  });
}

export function verifyEmail(emailMagic) {
  return http.post("users/verifyEmail", { emailMagic });
}

export function verifyGoogle(passMagic) {
  return http.post("users/verifyGoogle", { passMagic });
}

export function validateGoogle(profileData) {
  return http.post("users/google", profileData);
}

export function verifyEmailMagic(emailMagic) {
  return http.post("users/verifyEmailMagic", {
    emailMagic,
  });
}

export function resendEmailVerification(url, email) {
  return http.post("users/resend", {
    url,
    email,
  });
}

export function ambassadorRegistration(params) {
  return http.post("users/register", params);
}

export function prospectRegistration(params) {
  return http.post("users/MessReg", params);
}

// User

export async function getUser() {
  if (loggedIn) {
    return http.get("/users/current");
  } else {
    return null;
  }
}

export function updateUser(data) {
  return http.put("/users/update", data);
}

export function updatePassword(password, passMagic) {
  return http.put("users/updatePassword", { password, passMagic });
}

export function getNotificationSettings(userId) {
  return http.get("/users/unsubscribeEmail", {
    params: {
      id: userId,
    },
  });
}

export function updateNotifications(userId, notifications) {
  return http.post("users/updateUnsubscribe", {
    user: userId,
    pref: notifications,
  });
}

export function getAmbInvite(inviteId) {
  return http.get(`users/ambInvite/${inviteId}`);
}

export function getInvitation(invitationId, type) {
  return http.get("users/invitation", {
    params: {
      invitationId,
      type,
    },
  });
}

export function sendReport(body) {
  return http.post("users/SendReport", body);
}

export function validateMsa(body) {
  return http.post("users/msa", body);
}

export function getIndex(orgName) {
  return http.post("users/getIndex", { organization: orgName });
}

export function searchParentsFuzzy(organization, searchText, userId) {
  return http.post("users/searchParentsFuzzy", {
    organization,
    searchText,
    userId,
  });
}

export async function getAmbassadors(data) {
  const url = loggedIn ? "/users/CurrentParents" : "/users/allParents";

  return http.post(url, data).then((res) => {
    return res.data;
  });
}

// Chat

export function getInbox() {
  return http.get("users/chat");
}

export function readChat(conversationID, recipientID) {
  return http.put("users/chat/read", { conversationID, recipientID });
}

export function getConversation(conversationId) {
  return http.get(`users/chat/${conversationId}`);
}

export function blockUser(userId) {
  return http.put("users/chat/block", {
    id: userId,
  });
}

export function sendMessage(uid, composedMessage) {
  return http.put(`users/chat/new/${uid}`, {
    composedMessage,
  });
}

export function addFeedback(id, idType, answers) {
  return http.post(`users/feedback`, {
    id,
    idType,
    answers,
  });
}

export function getConversationFeedbackQuestions(organizationId) {
  return http.get(`users/chat/questions/${organizationId}`);
}

export function createAdminConversation(organization, composedMessage) {
  return http.post("users/chat/newAdminConvo", {
    organization,
    composedMessage,
  });
}

// Branding
export function getBrandingHeader() {
  return http.get("users/brandingHeader");
}

export function getVendors(orgName) {
  return http.post("users/vendors", {
    organization: orgName,
  });
}

// Invites
export function getInvitesEmail() {
  return http.get("users/invites");
}

export function getInvitesCall() {
  return http.get(`users/callInvitations`);
}

export function getInvitesVideo() {
  return http.get("users/activeVideoInvites");
}

export function sendInvitation(message, subject, invitationID, url) {
  return http.post("users/sendInvitation", {
    message,
    subject,
    invitationID,
    url,
  });
}

export function sendExistingInvite(inviteMagic, invitationID) {
  return http.post("users/sendExistingInvite", { inviteMagic, invitationID });
}

export function uploadVideoInviteSubmissionRecord(formData) {
  return http.post("users/videoInviteSubmissionRecord", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log(
        `InvitesPages.vue, users/videoInviteSubmissionRecord > percentCompleted ${percentCompleted}`
      );

      document.title = `${
        document.title.split("- Uploading")[0]
      } - Uploading ${percentCompleted}%`;
      if (percentCompleted >= 99) {
        document.title = document.title.split("- Uploading")[0];
      }
    },
  });
}

export function logAnalyticsEvent(params) {
  return http.post("users/createInvitationAnalyticsEvent", params);
}

// Notifications

export function requestBan(userId, reason) {
  return http.post("notifications/bans", {
    user: userId,
    reason,
  });
}

// UserVideoStories

export function getUserVideoStories(userId) {
  return http.get(`users/videoStory/read?uid=${userId}`);
}

export function createUserVideoStory(formData) {
  return http.post("users/videoStory/create", formData, {
    "Content-Type": "multipart/form-data",
  });
}

export function updateUserVideoStory(userVideoStoryID, userHidden) {
  return http.post("users/videoStory/update", { userVideoStoryID, userHidden });
}

export function deleteUserVideoStory(userVideoStoryID) {
  return http.post("users/videoStory/delete", { userVideoStoryID });
}

export function getVideoMetadata(videoInvitationID) {
  return http.get("users/getVideoMetadataFromVideoInvitation", {
    params: { videoInvitationID },
  });
}

export function getVideoSubmissionFromVideoRequest(videoInvitationRequestID) {
  return http.get("users/getVideoSubmissionFromVideoRequest", {
    params: { videoInvitationRequestID },
  });
}

// Call

export function createCall(body) {
  return http.post("users/createCall", body);
}

export function getCallRequests() {
  return http.get("users/callRequests");
}

export function sendCallFeedbackProspect(body) {
  return http.post("users/submitCallInviteFeedback", body);
}

export function getIsCallExpired(callID, callInvitationID) {
  return http.post("users/checkCallExpired", { callID, callInvitationID });
}

// Message

export function sendMessageProspectCallInvite(callInviteID, composedMessage) {
  return http.post("users/messageProspectCallInvite", {
    callInviteID,
    composedMessage,
  });
}

export function sendMessageProspectCallRequest(callID, composedMessage) {
  return http.post("users/messageProspectCallRequest", {
    callID,
    composedMessage,
  });
}

export function sendInstantMes(body) {
  return http.post("users/instantMes", body);
}

export function closeConversation(conversationID) {
  return http.put("users/closeConversation", {
    conversationID,
  });
}
