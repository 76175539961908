<template>
  <div class="profile" :class="{ skeleton }">
    <div class="profile-header">
      <button
        v-if="videoStory"
        class="profile-video"
        @click="$emit('videoStoryClicked', videoStory)">
        <img class="profile-img profile-img-video" :src="videoThumbnail" alt />
        <img class="profile-img-overlay" :src="profileImageUrl" alt />
        <span class="video-time"> {{ videoDuration }}</span>
        <img class="play" src="../assets/play-circle.svg" alt />
      </button>
      <img v-else class="profile-img" :src="profileImageUrl" alt />
      <h3 class="profile-name">{{ user.first }} {{ user.last }}</h3>
      <div class="profile-extras">
        <button
          class="btn-link"
          v-if="myStory"
          @click="$emit('my-story-click')">
          {{ $lang.landingPage_ProfileMyStoryBtn }}
          <ppicon name="chevron-right" />
        </button>
      </div>
    </div>
    <div class="profile-body">
      <Scrollbar @overflow-change="onOverflowChange">
        <div class="questions">
          <div class="question" v-for="(field, i) of fields" :key="i">
            <h4>{{ field.question }}</h4>
            <p>
              {{ field.answer }}
            </p>
          </div>
          <div class="fade-padding" v-if="showFade"></div>
        </div>
      </Scrollbar>
      <div class="fade" v-if="showFade"></div>
    </div>

    <div class="profile-footer" v-if="!skeleton && user.canSendMessage">
      <button
        v-if="canSendMessage"
        class="btn"
        @click="$emit('startChat', user)">
        <img src="../assets/message.svg" />
        {{ $lang.landingPage_ProfileMessageBtn }}
      </button>
      <button v-if="canRequestCall" class="btn btn-fixed" @click="reqCall">
        <img src="../assets/phone.svg" />
        {{ $lang.landingPage_ProfileRequestCallBtn }}
      </button>
    </div>
  </div>
</template>

<script>
  import commonFuncs from "@/common/common";

  export default {
    name: "ProfileCard",
    props: {
      user: {
        type: Object,
        default() {
          return { first: "Ambassador" };
        },
      },
      landingPage: {
        type: Boolean,
      },
      profileFields: {
        type: Array,
      },
      canSendMessage: {
        type: Boolean,
        default: true,
      },
      canRequestCall: {
        type: Boolean,
        default: true,
      },
      videoStory: {
        type: Object,
      },
      skeleton: {
        type: Boolean,
      },
    },
    data() {
      return {
        scrollbar: null,
        showFade: false,
      };
    },
    computed: {
      fields() {
        if (this.skeleton) {
          const qa = { question: "Question", answer: "Answer" };
          return [qa, qa, qa, qa];
        }

        const fields = [];

        if (this.user?.profile) {
          for (const [i, field] of this.profileFields.entries()) {
            const question = field.trim();
            const answer = this.user.profile[i]?.trim();

            if (question.length && answer?.length) {
              fields.push({ question, answer });
            }
          }
        }

        return fields;
      },
      myStory() {
        if (this.user.faq && this.user.faq.length > 0) {
          return this.user.faq;
        }
        return false;
      },
      profileImageUrl() {
        return (
          commonFuncs.optimizeProfileImageUrl(this.user.profileImageUrl) ||
          require("@/assets/profile.svg")
        );
      },
      videoThumbnail() {
        // https://cloudinary.com/documentation/video_manipulation_and_delivery#generating_video_thumbnails
        return `https://res.cloudinary.com/peerpal/video/upload/so_0p/${this.videoStory.video.publicId}.jpg`;
      },
      videoDuration() {
        const seconds = this.videoStory.video.duration;
        const min = seconds / 60;
        const sec = seconds % 60;
        return min.toFixed() + ":" + sec.toFixed().padStart(2, 0);
      },
    },
    methods: {
      reqCall() {
        // create an emitter for the root vue componetnt of landing page to view the data needed properly.
        if (this.landingPage) {
          this.$emit("startReqCall", this.user);
        } else {
          this.$emit("newReqCall", { recipientID: this.user.id });
        }
      },
      onOverflowChange(hasOverflow) {
        this.showFade = hasOverflow?.y;
      },
    },
  };
</script>

<style scoped>
  * {
    box-sizing: border-box;
    font-size: 14px;
  }
  .profile {
    border-radius: 24px;
    box-shadow: 0px 15px 30px -6px rgba(0, 0, 0, 0.16);
    overflow: hidden;
    position: relative;
    background: white url("../assets/profile-card-bg.svg") no-repeat;
  }
  .profile-header {
    padding: 24px 24px 0px;
    position: relative;
  }
  .profile-body {
    margin: 0 22px 16px;
    position: relative;
    --fade-height: 28px;
  }
  .profile-footer {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-evenly;
  }
  .profile-video {
    width: 240px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
  .profile-video:focus {
    outline: 0.25rem solid rgba(var(--accent-color-rgb), 0.3);
    border-radius: 15px;
  }
  .profile-video > * {
    pointer-events: none;
  }
  .profile-img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    padding: 10px;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .profile-img-video {
    width: 100%;
    border-radius: 15px;
    padding: 0;
    cursor: pointer;
    position: relative;
  }
  .profile-img-overlay {
    position: absolute;
    top: 10px;
    left: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .video-time {
    position: absolute;
    bottom: 8px;
    left: 8px;
    padding: 4px 8px;
    color: #ddd;
    background: rgb(0, 0, 0, 0.65);
    border-radius: 8px;
  }
  .play {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 48px;
    opacity: 0.7;
  }
  .profile-name {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0 0;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .profile-extras {
    height: 26px;
    margin-bottom: 4px;
  }
  .questions {
    line-height: 20px;
    height: 236px;
    text-align: left;
    padding-right: 12px;
  }
  .question + .question {
    margin-top: 12px;
  }
  .question h4 {
    font-weight: 500;
    margin: 0;
    text-align: left;
  }
  .question p {
    margin: 0;
  }
  .fade-padding {
    height: var(--fade-height);
  }
  .fade {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - 18px);
    height: var(--fade-height);
    box-sizing: border-box;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 1)
    );
    overflow: auto;
  }
  .profile-footer .btn {
    padding-left: 12px;
    padding-right: 12px;
  }

  .skeleton {
    --color: #ccc;
  }
  .skeleton h3 {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  .skeleton h3,
  .skeleton .question {
    background: var(--color);
    color: var(--color);
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    animation: shimmer;
  }

  .skeleton .question::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    );
    animation: loading 2s infinite;
    content: "";
  }

  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }
</style>
