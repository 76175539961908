<template>
  <input
    type="checkbox"
    role="switch"
    class="toggle"
    :checked="value ^ inverted"
    @click="onClick" />
</template>

<script>
  export default {
    name: "common-toggle",
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      // allows ON = false and OFF = true
      inverted: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      onClick() {
        this.$emit("input", !this.value);
        this.$emit("dirty", true);
      },
    },
  };
</script>

<style scoped lang="scss">
  .toggle {
    margin: 0;
    appearance: none;
    width: 48px;
    height: 24px;
    border-radius: 24px;
    position: relative;
    background-color: $grey-200;
    transition: background-color var(--transition-duration) ease;
  }
  .toggle:checked {
    background: $green-400;
  }
  .toggle:after {
    content: "";
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    background: $white;
    border-radius: 50%;
    box-shadow: $box-shadow-lg;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: transform var(--transition-duration) ease;
  }
  input:checked.toggle:after {
    transform: translateX(150%);
  }
</style>
