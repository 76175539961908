<template>
  <transition name="fade">
    <button
      v-show="show"
      class="btn btn-up"
      @click="$emit('click')"
      aria-label="scroll to top">
      <ppicon name="chevron-up" class="btn-icon" large alt />
    </button>
  </transition>
</template>

<script>
  export default {
    name: "ScrollToTopBtn",
    props: ["show"],
  };
</script>

<style scoped>
  .btn-up {
    position: fixed;
    z-index: 1;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.5);
  }
  .btn-up .btn-icon {
    width: 36px;
    height: 36px;
  }
</style>
