<template>
  <div class="body">
    <g-signin-button
      :params="googleSignInParams"
      @success="onSignInSuccess"
      @error="onSignInError"
      ref="signinBtn">
    </g-signin-button>
    <h1 v-if="errorMsg">{{ errorMsg }}</h1>
    <div v-else id="loading-container">
      <div class="sk-fading-circle">
        <div class="sk-circle1 sk-circle"></div>
        <div class="sk-circle2 sk-circle"></div>
        <div class="sk-circle3 sk-circle"></div>
        <div class="sk-circle4 sk-circle"></div>
        <div class="sk-circle5 sk-circle"></div>
        <div class="sk-circle6 sk-circle"></div>
        <div class="sk-circle7 sk-circle"></div>
        <div class="sk-circle8 sk-circle"></div>
        <div class="sk-circle9 sk-circle"></div>
        <div class="sk-circle10 sk-circle"></div>
        <div class="sk-circle11 sk-circle"></div>
        <div class="sk-circle12 sk-circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { validateGoogle } from "@/services/api.service.js";
  const api = { validateGoogle };
  // https://www.npmjs.com/package/vue-google-signin-button
  // eslint-disable-next-line no-unused-vars
  import GSignInButton from "vue-google-signin-button";

  export default {
    data() {
      return {
        googleSignInParams: {
          client_id: process.env.VUE_APP_GOOGLE_URL,
        },
        errorMsg: null,
      };
    },
    methods: {
      onSignInSuccess(googleUser) {
        // `googleUser` is the GoogleUser object that represents the just-signed-in user.
        // See https://developers.google.com/identity/sign-in/web/reference#users
        const profile = googleUser.getBasicProfile();

        let profileData = {
          token: googleUser.getAuthResponse().id_token,
          googleId: googleUser.getId(),
          email: profile.getEmail(),
          first: profile.getGivenName(),
          last: profile.getFamilyName(),
        };

        if (this.$route.query.signedUpOrg) {
          profileData.signedUpOrg = this.$route.query.signedUpOrg;
        } else if (this.$route.query.org) {
          profileData.organization = this.$route.query.org;
        }

        if (this.$route.query.invite) {
          profileData.invitationID = this.$route.query.invite;
        }
        if (this.$route.query.videoInvitationID) {
          profileData.videoInvitationID = this.$route.query.videoInvitationID;
        }
        if (this.$route.query.callInvitationID) {
          profileData.callInvitationID = this.$route.query.callInvitationID;
        }

        if (this.$route.query.sender) {
          profileData.source = this.$route.query.sender;
        }
        if (this.$route.query.create) {
          profileData.emailMagic = this.$route.query.create;
        }
        if (this.$route.query.ambInvitationID) {
          profileData.ambInvitationID = this.$route.query.ambInvitationID;
        }

        if (this.$route.query.callID) {
          profileData.callID = this.$route.query.callID;
        }

        if (this.$route.query.callInvitationID) {
          profileData.callInvitationID = this.$route.query.callInvitationID;
        }

        if (this.$route.query.message) {
          profileData.composedMessage = this.$route.query.message;
        }

        if (this.$route.query.callID) {
          profileData.callID = this.$route.query.callID;
        }

        if (this.$route.query.callInvitationID) {
          profileData.callInvitationID = this.$route.query.callInvitationID;
        }

        if (this.$route.query.message) {
          profileData.composedMessage = this.$route.query.message;
        }

        api
          .validateGoogle(profileData)
          .then((response) => {
            let googleMagic = response.data.googleMagic;
            const urlBase = this.$route.query.url;
            const isLogin = response.data.isLogIn;

            let queryParam = "";
            if (this.$route.query.goToInvite) {
              queryParam += `&goToInvite=true`;
            }

            window.location.href = `${urlBase}/googleRedirectFrom/?magic=${googleMagic}&log=${isLogin}${queryParam}`;
          })
          .catch((err) => {
            if (err.response && err.response.status == 409) {
              this.$toast.error(
                "This email is already in use by another, non-Google account."
              );
            } else if (err.response && err.response.status === 410) {
              this.$toast.error(
                "There is no account associated this email. Please go back."
              );
              const urlBase = this.$route.query.url;
              window.location.href = urlBase;
            } else {
              this.$toast.error("Login failed.");
            }
          });
      },
      onSignInError(error) {
        // `error` contains any error occurred.
        console.log("OH NO", error);
        if (error.error === "popup_blocked_by_browser") {
          this.errorMsg =
            "You need to enable pop-ups to sign in with Google. Please enable pop-ups and reload this page to sign in.";
        }
      },
    },
    mounted() {
      setTimeout(() => {
        this.$refs.signinBtn.$el.click();
      }, 1000);
    },
  };
</script>

<style scoped lang="scss">
  .g-signin-button {
    background-color: transparent;
    height: 0;
    width: 0;
  }
  #loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sk-fading-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-fading-circle .sk-circle:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  .sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  .sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  .sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  .sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  .sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  .sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  .sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  .sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  .sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -var(--transition-duration);
    animation-delay: -var(--transition-duration);
  }
  .sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }

  @keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
</style>
