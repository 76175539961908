<template>
  <div class="login-signup-form">
    <div class="card">
      <div class="back" @click="toggleLoginForm">
        <img src="../assets/Back.png" class="back-button-caret" alt="Back" />
        <label class="back-label">{{ $lang.general_Back }}</label>
      </div>

      <template>
        <h2>{{ title }}</h2>
        <h3 class="card-subtitle">{{ subtitle }}</h3>

        <div class="form">
          <common-input
            type="text"
            name="fname"
            :label="$lang.login_FirstNamePlaceholder"
            v-model="first"
            @keyup.native.enter="loginOrSignup"
            v-if="isSignup" />

          <common-input
            type="text"
            name="lname"
            :label="$lang.login_LastNamePlaceholder"
            v-model="last"
            @keyup.native.enter="loginOrSignup"
            v-if="isSignup" />

          <common-input
            type="text"
            name="email"
            :label="$lang.login_EmailPlaceholder"
            :disabled="isSignup"
            v-model="email"
            @keyup.native.enter="loginOrSignup" />

          <common-input
            type="password"
            name="password"
            :label="$lang.login_PasswordPlaceholder"
            v-model="password"
            @keyup.native.enter="loginOrSignup" />

          <button class="login-signup-submit" @click="loginOrSignup">
            {{ submitButtonText }}
          </button>
        </div>

        <button
          class="forgot-pw"
          @click="toggleForgotPassword"
          v-if="!isSignup">
          {{ $lang.login_ResetPassword }}
        </button>
      </template>
    </div>

    <common-modal
      :title="$lang.login_ResetPasswordTitle"
      @submit="forgotPW"
      @close="toggleForgotPassword()"
      v-if="showForgotPasswordModal">
      <common-input
        type="text"
        name="email"
        :label="$lang.login_ResetPasswordPlaceholder"
        v-model="forgotPasswordEmail" />
    </common-modal>
  </div>
</template>

<script>
  //Used by login/signup with email/password
  import { forgotPassword } from "@/services/api.service.js";
  const api = { forgotPassword };

  export default {
    props: {
      isSignup: {
        type: Boolean,
        default: false,
      },

      title: {
        type: String,
        default() {
          return this.$lang.login_LogInPageTitle;
        },
      },

      subtitle: {
        type: String,
        default() {
          return this.$lang.login_LogInPageSubtitle;
        },
      },

      signUpUserInfo: {
        type: Object,
      },
    },

    data() {
      return {
        first: "",
        last: "",
        email: "",
        password: "",
        forgotPasswordEmail: "",
        showForgotPasswordModal: false,
      };
    },

    computed: {
      submitButtonText() {
        return this.isSignup
          ? this.$lang.login_CreateAccount
          : this.$lang.login_Login;
      },

      userData() {
        return {
          email: this.email,
        };
      },
    },

    watch: {
      signUpUserInfo: {
        immediate: true,
        deep: true,
        handler(val) {
          this.first = val.first || "";
          this.last = val.last || "";
          this.email = val.email || "";
        },
      },

      userData: {
        immediate: true,
        deep: true,
        handler(val) {
          // Send current user data to parent for API calls
          this.$emit("update-data", val);
        },
      },
    },

    methods: {
      clearForgotPasswordData() {
        this.forgotPasswordEmail = "";
      },

      toggleLoginForm() {
        this.$emit("toggle-login-form");
      },

      toggleForgotPassword() {
        this.showForgotPasswordModal = !this.showForgotPasswordModal;

        if (this.showForgotPasswordModal) {
          this.forgotPasswordEmail = this.email;
        }
      },

      forgotPW() {
        if (this.forgotPasswordEmail) {
          //POST request for reset password
          api
            .forgotPassword(this.forgotPasswordEmail)
            .then(() => {
              this.$toast.success(this.$lang.login_ResetPasswordSuccess);
              this.showForgotPasswordModal = false;
              this.clearForgotPasswordData();
            })
            .catch((err) => {
              if (!err.response || !err.response.status) {
                this.$toast.error(this.$lang.login_ResetPasswordFailure);
                return;
              }

              if (err.response.status === 434) {
                this.$toast.error(this.$lang.login_ResetPasswordFailureMSA);
                return;
              }

              this.$toast.error(err);
            });
        }
      },

      loginOrSignup() {
        if (
          !this.email ||
          !this.password ||
          (this.isSignup && (!this.first || !this.last))
        ) {
          this.$toast.error(this.$lang.login_FillOutAllFields);
          return;
        }

        let credentials = {
          email: this.email,
          password: this.password,
        };

        let event = "login";

        if (this.isSignup) {
          credentials.first = this.first;
          credentials.last = this.last;
          event = "register";
        }

        this.$emit(event, credentials);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .back {
    display: flex;
    justify-content: left;
    cursor: pointer;
    height: 20px;
    font-weight: 500;
  }
  .back:active {
    opacity: 0.5;
  }
  .back .back-button-caret {
    object-fit: scale-down;
    margin-right: 20px;
  }
  .back .back-label {
    cursor: pointer;
  }

  .login-signup-submit {
    padding-right: 25px;
    padding-left: 25px;
    background: var(--accent-color);
    height: 50px;
    border: 0;
    box-sizing: border-box;
    font-size: 16px;
    color: $white;
    border-radius: 25px;
    margin-top: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    margin-bottom: 10px;

    &:hover {
      transform: scale(1.06);
    }

    &:focus {
      outline: none;
    }

    &:active {
      opacity: 0.5;
    }
  }

  .forgot-pw {
    color: blue;
    cursor: pointer;
    font-size: 16px;
  }
</style>
