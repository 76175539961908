<template>
  <button
    :class="['button', variant, behavior, { loading }]"
    @click="!loading && $emit('click')">
    <div class="button-wrapper">
      <div class="button-content" :class="{ invisible: loading }">
        <ppicon v-if="icon" :class="{ icon: !isIconOnly }" :name="icon" />
        <div v-if="tooltip" class="tooltip">{{ tooltip }}</div>
        <slot></slot>
      </div>
      <div v-if="loading" class="loading">
        <common-spinner />
      </div>
    </div>
  </button>
</template>

<script>
  export default {
    name: "Button",
    props: {
      behavior: {
        type: String,
        default: "fit",
        validator: (value) => ["grow", "fit"].includes(value),
      },
      variant: {
        type: String,
        default: "primary",
        validator: (value) =>
          ["primary", "secondary", "outlined", "text", "iconOnly"].includes(
            value
          ),
      },
      icon: {
        type: String,
        required: false,
      },
      tooltip: {
        type: String,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isIconOnly() {
        return this.variant === "iconOnly";
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "@/scss/_typography.scss";

  .button {
    font-weight: 500;
    border: none;
    border-radius: 24px;
    transition: opacity 0.5s ease;
    outline: none;
    position: relative;
    padding: 0 18px;
    height: 36px;

    &:hover:not(.loading) {
      filter: brightness(85%);
    }

    &:hover .tooltip {
      opacity: 1;
    }

    &.loading {
      cursor: auto;
    }
  }

  .button-wrapper {
    display: grid;
    place-items: center;

    .button-content {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.6px;
      text-transform: uppercase;
    }

    .button-content,
    .loading {
      grid-row: 1;
      grid-column: 1;
      display: flex;
      align-items: center;
      gap: 0.35em;
    }
  }

  .button::-moz-focus-inner {
    border: 0;
  }

  .grow {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0px;
  }

  .fit {
    width: fit-content;
  }

  :disabled {
    cursor: not-allowed;
  }

  .primary {
    background-color: var(--accent-color);
    color: $white;

    &:disabled {
      background-color: $grey-100;
      color: $grey-400;
    }
  }

  .secondary {
    background-color: $grey-200;
    color: $grey-800;
  }

  .outlined {
    background-color: transparent;
    color: $grey-800;
    border: 1px solid $grey-800;
  }

  .text {
    background-color: transparent;
    color: var(--accent-color);
  }

  .iconOnly {
    height: 48px;
    width: 48px;
    font-size: 24px;
    border-radius: 50%;
    background-color: $white;
    border: 1px solid $grey-200;
    line-height: 24px;
    text-align: center;
    padding: 0;
  }

  .icon {
    opacity: 0.5;
    font-weight: 400;
    font-size: 18px;
  }

  .tooltip {
    @extend .font-secondary;
    background-color: $grey-800;
    color: $white;
    border-radius: 8px;
    position: absolute;
    padding: 10px 14px;
    top: -10px;
    left: 50%;
    transform: translate(-50%, -100%);
    opacity: 0;
    transition: opacity 0.3s ease-in;

    &::after {
      content: " ";
      background-color: #4d4d4d;
      height: 14px;
      width: 14px;
      bottom: -3px;
      left: calc(50% - 7px);
      transform: rotate(45deg);
      position: absolute;
      border-radius: 2px;
    }
  }

  .invisible {
    visibility: hidden;
  }
</style>
