<template>
  <div class="body">
    <div class="container">
      <h4 v-if="signUpSlider">
        What is your interest in {{ orgDisplayName }}? (1 is the lowest - 10 is
        the highest)
      </h4>
      <input
        type="range"
        min="1"
        max="10"
        v-if="signUpSlider"
        class="slider"
        v-model.number="qOneValue" />
      <h4 v-if="signUpSlider">{{ qOneValue }}</h4>
      <ul>
        <template v-for="(q, index) in inquiryQs">
          <li v-if="q" :key="index" class="inbox-list-item">
            <h4>{{ q.question }}</h4>
            <div v-if="q.type == 'dropdown'">
              <select v-model="inquiryAs[index]" class="dropdown">
                <option selected disabled>Please select one</option>
                <template v-for="(x, index) in q.details">
                  <option :key="index" v-if="x.active">
                    {{ x.detail }}
                  </option>
                </template>
              </select>
            </div>

            <textarea-autosize
              v-else
              class="input"
              rows="1"
              v-model="inquiryAs[index]"
              placeholder="Enter answer here" />
          </li>
        </template>
      </ul>
      <button class="ls" @click="updateInterest">Submit</button>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { updateUser } from "@/services/api.service.js";

  export default {
    data() {
      return {
        qOneValue: 5,
        inquiryAs: [],
      };
    },
    computed: {
      ...mapState([
        "orgDisplayName",
        "signUpSlider",
        "inquiryQs",
        "isCurrentParent",
        "uid",
      ]),
    },

    watch: {
      inquiryQs: {
        immediate: true,
        handler(val) {
          if (val?.length && !this.arrayEmpty(val)) {
            this.inquiryAs = new Array(val.length);
          }
        },
      },
    },

    methods: {
      updateInterest() {
        if (this.inquiryQs.length > 0 && this.arrayEmpty(this.inquiryAs)) {
          this.$toast.error(
            "Please fill out all of the questions before proceeding!"
          );
          return;
        }

        let data = {};

        if (this.signUpSlider) {
          data.q1 = this.qOneValue;
        }

        if (this.inquiryAs.length > 0) {
          data.inquiryAs = this.inquiryAs;
        }

        updateUser(data)
          .then(() => {
            if (process.env.VUE_APP_API_KEY) {
              let identify = new this.$amplitude.Identify().set(
                "initialInterest",
                this.qOneValue
              );
              this.$amplitude.getInstance().identify(identify);
            }
            this.$router.push({ name: "Messaging", params: { tour: true } });
          })
          .catch(() => {
            this.$toast.error("Failed to update profile. Please try again");
          });
      },
      arrayEmpty(arr) {
        if (arr.includes(undefined) || arr.includes(null) || arr.includes("")) {
          return true;
        } else {
          return false;
        }
      },
    },
  };
</script>

<style scoped>
  .container {
    max-width: 500px;
    background-color: white;
    margin: 50px auto;
    padding: 40px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  }
  h2 {
    margin-top: 0;
    margin-bottom: 30px;
  }
  h4 {
    font-size: 17.5px;
    margin: 0 auto 10px auto;
  }
  h5 {
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    text-align: right;
    margin: 0 0 5px 0;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    margin: 20px 0;
  }
  .input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 5px;
    font-size: 15px;
    line-height: 20px;
    outline: none;
    color: black;
    transition: all 0.5s ease;
    background-color: #eeeeee;
    overflow-y: scroll;
    resize: none;
  }
  .input:focus {
    border: 2px solid grey;
  }
  .ls {
    width: 40%;
    background: var(--accent-color);
    height: 50px;
    border: 0;
    box-sizing: border-box;
    font-size: 16px;
    color: white;
    border-radius: 25px;
    margin-top: 10px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  .ls:hover {
    transform: scale(1.06);
  }
  .ls:focus {
    outline: none;
  }
  .ls:active {
    opacity: 0.5;
  }
  .dropdown {
    border: none;
    background-color: #eeeeee;
    font-size: 15px;
    padding: 10px;
    border-radius: 5px;
    width: 60%;
  }
  .dropdown:focus {
    outline: none;
  }
  @media (max-width: 460px) {
    .container {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
</style>
