<template>
  <div class="pp-icon" :class="`icon-${size}`">
    <svg
      class="svg-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true">
      <path :d="icons[name]"></path>
    </svg>
  </div>
</template>

<script>
  import icons from "@/plugins/icons";

  export default {
    name: "icon",
    props: {
      name: {
        type: String,
        required: true,
      },
      large: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        icons,
      };
    },
    computed: {
      size() {
        if (this.large) {
          return "large";
        }
        return "";
      },
    },
  };
</script>

<style scoped lang="scss">
  .pp-icon {
    height: 24px;
    width: 24px;
  }
  .svg-icon {
    width: 100%;
    fill: currentColor;
  }
</style>
