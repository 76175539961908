<template>
  <div class="phone-wrapper">
    <input
      class="input"
      :class="{ error: isInvalid }"
      :value="inputValue"
      type="tel"
      :placeholder="placeholder"
      @input="emitInput"
      :disabled="disabled"
      aria-label="telephone"
      ref="input" />
    <div v-if="isInvalid && dirty" class="error">Invalid phone number.</div>
  </div>
</template>

<script>
  import intlTelInput from "intl-tel-input";
  import "intl-tel-input/build/css/intlTelInput.css";

  export default {
    name: "phone",
    props: {
      value: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      openToTop: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        inputValue: "",
        telephoneInput: null,
        isInvalid: false,
        dirty: false,
      };
    },
    created() {
      this.inputValue = this.value;
      this.$nextTick(() => {
        const input = this.$refs.input;
        this.telephoneInput = intlTelInput(input, {
          utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.min.js",
        });
      });
    },
    beforeDestroy() {
      this.telephoneInput?.destroy();
      this.telephoneInput = null;
    },
    methods: {
      emitInput({ target: { value } }) {
        this.inputValue = value;
        this.dirty = true;
        this.isInvalid = !this.telephoneInput.isValidNumber();
        if (!this.isInvalid) {
          this.$emit("input", this.telephoneInput.getNumber());
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .error {
    margin: 6px 0 0 12px;
    text-align: left;
    color: $red;
  }

  ::v-deep.phone-wrapper {
    &.open-top .iti__country-list {
      top: -160px;
      max-height: 160px;
    }
    .iti {
      width: 100%;
    }
    .iti__flag-container {
      border-radius: 20px;
      margin-left: 12px;
    }
    .iti__country-list {
      bottom: 32px;
      max-height: 100px;
    }
    .input {
      width: 100%;
      background-color: $grey-100;
      border: none;
      border-radius: 20px;
      outline: none;
      padding: 0.5rem 0.65rem 0.5rem 4.2rem;
      font-size: 14px;
      line-height: 18px;
      color &:focus {
        border: none;
      }
    }
    ::-webkit-input-placeholder {
      /* Chrome */
      color: $grey-900;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: $grey-900;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $grey-900;
      opacity: 1;
    }
    :-moz-placeholder {
      /* Firefox 4 - 18 */
      color: $grey-900;
      opacity: 1;
    }
  }
</style>
