<template>
  <div class="layout" v-if="ready">
    <Header
      v-if="showHeader"
      @logout="logout"
      @tutorial="toggleTutorialModal"
      @report-problem="toggleReportModal">
    </Header>

    <transition
      name="fade"
      mode="out-in"
      @after-leave="$root.$emit('triggerScroll')">
      <router-view class="body" />
    </transition>

    <common-modal
      size="xl"
      hideFooter
      :title="$lang.footer_TutorialModalHeader"
      v-if="showTutModal"
      @close="toggleTutorialModal">
      <div v-for="(video, index) in loomsArr" :key="index">
        <div class="video-title">{{ video.title }}</div>
        <div class="video-wrap">
          <iframe
            :src="video.url"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            class="video"></iframe>
        </div>
      </div>
    </common-modal>

    <common-modal
      :title="$lang.footer_ReportModalHeader"
      v-if="showReportModal"
      @close="toggleReportModal">
      <textarea
        type="text"
        placeholder="Report a bug/user"
        class="input report-modal-text"
        v-model="report"></textarea>

      <template #footer>
        <!-- TODO: Add button slot/props to modal so a save/cancel button are easily configurable -->
        <button @click="sendReport" class="btn">
          {{ $lang.modal_Submit }}
        </button>
      </template>
    </common-modal>

    <footer>
      <p class="sub-footer" v-html="footerText" v-if="footerText"></p>

      <div class="footer">
        <button class="footer-action" @click="toggleTutorialModal">
          Tutorial
        </button>

        <span class="spacer" />

        <router-link
          class="footer-action"
          :to="{ name: 'Terms of Service' }"
          target="_blank">
          Terms of Service
        </router-link>
        <span class="spacer" />

        <router-link
          class="footer-action"
          :to="{ name: 'FAQs' }"
          target="_blank">
          Help
        </router-link>

        <span class="spacer" />

        <button class="footer-action" @click="toggleReportModal">
          Report a Problem
        </button>

        <div class="powered-by">
          Powered by
          <a
            href="https://graduway.com/products/admissions/"
            rel="noopener"
            target="_blank"
            class="footer-link">
            PeerPal from Gravyty
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import Vue from "vue";
  import englishLanguageFile from "@/localization/en.js";
  import Header from "./components/common/Header.vue";
  import DefaultFavicon from "@/assets/default-favicon.png";
  import { sendReport } from "@/services/api.service.js";
  const api = { sendReport };

  export default {
    name: "app",

    components: { Header },

    data() {
      return {
        ready: false,
        landingPage: [],
        showTutModal: false,
        showReportModal: false,
        report: "",
        loomsArr: [
          {
            url: "https://player.vimeo.com/video/684259985?h=1c9ee09e9e&color=ffffff&title=0&byline=0&portrait=0",
            title: "Tutorial #1",
          },
          {
            url: "https://player.vimeo.com/video/684437681?h=c14a79b267&color=ffffff&title=0&byline=0&portrait=0",
            title: "Tutorial #2",
          },
          {
            url: "https://player.vimeo.com/video/685463611?h=7a9733380a&color=ffffff&title=0&byline=0&portrait=0",
            title: "Tutorial #3",
          },
        ],
      };
    },

    computed: {
      footerText() {
        if (this.landingPage?.length >= 3) {
          return this.landingPage[2];
        }
        return "";
      },
      showHeader() {
        return this.$route.name !== "Google Redirect To";
      },
    },

    watch: {
      "$route.query.language": {
        immediate: true,
        deep: true,
        handler(val) {
          this.setLanguageFile(val);
        },
      },
    },

    async created() {
      await Promise.all([this.setLanguageFile(), this.loadBranding()]);
      this.ready = true;
    },
    methods: {
      setLanguageFile(lang) {
        let languageCode = lang || "en";

        return import(`@/localization/${languageCode}.js`)
          .then((module) => {
            Vue.prototype.$lang = module.default;
          })
          .catch((err) => {
            console.log(
              `Failed to find language file matching code, ${lang}, loading English. Error message: ${err}`
            );

            Vue.prototype.$lang = englishLanguageFile;
            languageCode = "en";
          })
          .finally(() => {
            // use more specific language code
            Vue.prototype.$locale = navigator.langauge?.startsWith(languageCode)
              ? navigator.language
              : languageCode;
          });
      },

      loadBranding() {
        return this.$store.dispatch("getBranding").then((response) => {
          if (Array.isArray(response)) {
            //TODO: Figure out why this is sending an array. The action is clearly returning a single object
            response = response[0];
          }

          this.landingPage = response.landingPage;

          let bodyStyle = document.body.style;

          if (this.showNiche) {
            bodyStyle.setProperty("--school-color", "#59a44d");
            bodyStyle.setProperty("--accent-color", "#59a44d");
            bodyStyle.setProperty("--text-color", "#ffffff");
            this.accentColor = "#59a44d";
          } else {
            this.accentColor = response.accentColor;
          }

          let link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          link.href = response.faviconUrl
            ? response.faviconUrl
            : DefaultFavicon;
        });
      },

      toggleTutorialModal() {
        this.showTutModal = !this.showTutModal;
      },

      toggleReportModal() {
        this.showReportModal = !this.showReportModal;
      },

      sendReport() {
        if (this.report) {
          api
            .sendReport({ report: this.report })
            .then(() => {
              this.showReportModal = false;
              this.report = null;
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.$toast.error(this.$lang.footer_ReportModalBlankReportError);
        }
      },

      logout() {
        this.$store.dispatch("logout");

        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude.getInstance().setUserId(null);
          this.$amplitude.getInstance().regenerateDeviceId();
        }

        if (this.$route.name !== "Home") {
          this.$router.push({ name: "Home" });
        }
      },
    },
  };
</script>

<style lang="scss">
  @import "@/scss/_typography.scss";

  * {
    font-family: $font-family-primary;
    --transition-duration: 0.3s;

    @media (prefers-reduced-motion) {
      --transition-duration: 0s !important;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $grey-800;
  }

  .layout {
    text-align: center;
    color: $grey-800;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: $grey-150;
  }

  html,
  body {
    margin: 0;
    font-size: 14px;
    line-height: 1.3;
    font-weight: 400;
  }
  .container {
    width: 100%;
    max-width: 1400px;
    min-width: 280px;
    margin: 0 auto;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--accent-color);
  }

  .btn {
    display: inline-flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    padding: 8px 18px;
    border: 2px solid transparent;
    border-radius: 20px;
    background-color: var(--accent-color);
    color: $white;
    cursor: pointer;
    letter-spacing: 0.1em;
    text-decoration: none;
  }

  .btn.btn-wide {
    padding: 8px 46px;
  }

  .btn:hover {
    filter: brightness(90%);
  }

  .btn:active {
    filter: brightness(85%);
  }

  .btn:focus {
    outline: 0.25rem solid rgba(var(--accent-color-rgb), 0.3);
  }

  .btn-outline {
    background-color: $white;
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
    font-weight: 700;
  }

  .btn-secondary {
    background-color: $grey-200;
    color: $black-175;
    font-weight: 600;
  }

  .btn-link {
    background-color: $white;
    color: var(--accent-color);
    font-weight: 500;
    display: inline-flex;
    align-items: center;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity var(--transition-duration);
  }

  .slide-to-left-absolute-enter,
  .slide-to-left-absolute-leave-active {
    // For layered menus, like the mobile menu
    right: -100% !important;
  }

  .slide-to-left-absolute-enter-active,
  .slide-to-left-absolute-leave-active {
    transition: right var(--transition-duration);
  }

  .slide-left-leave-active,
  .slide-left-enter-active {
    transition: var(--transition-duration);
  }
  .slide-left-enter {
    transform: translate(100%, 0);
  }
  .slide-left-leave-to {
    transform: translate(-100%, 0);
  }

  .slide-right-leave-active,
  .slide-right-enter-active {
    transition: var(--transition-duration);
  }
  .slide-right-enter {
    transform: translate(-100%, 0);
  }
  .slide-right-leave-to {
    transform: translate(100%, 0);
  }

  footer {
    @media screen and (max-width: $sm) {
      display: none;
    }
  }

  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon-button {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity var(--transition-duration);

    &:hover {
      opacity: 1;
    }
  }
</style>

<style lang="scss" scoped>
  footer {
    display: flex;
    margin-top: auto;
    flex-direction: column;
  }

  footer,
  .footer-action {
    font-size: 14px;
    line-height: 16.41px;
    letter-spacing: 0.1px;
    color: $white;
  }

  .footer {
    background-color: $grey-800;
    padding: 12px 40px;
    display: flex;
    align-items: center;
  }

  .powered-by {
    margin-left: auto;
  }

  .footer-link:link,
  .footer-link:visited {
    font-weight: 700;
    color: $white;
  }

  .sub-footer {
    color: $grey-500;
    margin-bottom: 23px;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
  }

  .footer-action {
    background: none;
    border: none;
    cursor: pointer;

    &:link,
    &:visited {
      text-decoration: none;
      color: $white;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .spacer {
    margin-left: 30px;
    margin-right: 30px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: $white;
  }

  .report-modal-text {
    padding: 10px;
    border: 0;
    background-color: $grey-150;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 15px;
    line-height: 20px;
    outline: none;
    color: black;
    resize: none;
    margin: 10px 20px 10px 20px;
    display: block;
    width: calc(100% - 40px);
    height: 200px;

    &:focus {
      outline: none;
    }
  }

  .video-title {
    font-size: 18px;
    padding-bottom: 15px;
    padding-top: 7%;
  }

  .video-wrap {
    position: relative;
    padding-bottom: 62.5%;
    height: 0;
    margin: 0 40px 20px 40px;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: $sm) {
    footer {
      display: none;
    }
  }
</style>
