<template>
  <div class="profile-container">
    <h2 class="profile-title">{{ $lang.editProfile_ReadMyStory }}</h2>
    <p class="profile-instructions">
      {{ $lang.editProfile_ReadMyStoryInstructions }}
    </p>
    <p class="profile-instructions">
      {{ $lang.editProfile_ReadMyStoryExample.format(orgDisplayName) }}
    </p>
    <ol class="profile-stories">
      <li v-for="(story, i) of userStories" :key="i">
        <div class="profile-story">
          <div>
            <common-input
              v-model="story.q"
              @dirty="$emit('dirty', true)"
              :placeholder="$lang.editProfile_ReadMyStoryQuestionPlaceholder"
              compact />
            <common-input
              v-model="story.a"
              @dirty="$emit('dirty', true)"
              :placeholder="$lang.editProfile_ReadMyStoryAnswerPlaceholder"
              compact
              :rows="1"
              autoresize />
          </div>
          <button
            v-if="i || !isEmpty"
            @click="deleteStory(i)"
            class="profile-delete-btn">
            <ppicon name="close-circle" />
          </button>
        </div>
      </li>
    </ol>
    <button @click="addStory" class="profile-add-btn">+</button>
  </div>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    props: {
      stories: Array,
    },
    data() {
      return {
        userStories: [{ q: "", a: "" }],
      };
    },

    computed: {
      isEmpty() {
        return (
          this.userStories.length === 1 &&
          this.userStories[0].q === "" &&
          this.userStories[0].a === ""
        );
      },

      ...mapState(["orgDisplayName"]),
    },
    watch: {
      userStories: {
        deep: true,
        handler() {
          if (this.userStories.length === 0) {
            this.$emit("update", []); // necessary for deleting all stories
            this.addStory();
            return;
          }
          const results = this.validate();
          if (results.valid) {
            this.$emit("update", this.userStories);
          }
          this.$emit("complete", results.complete);
        },
      },
    },

    mounted() {
      if (this.stories?.length) {
        this.userStories = [...this.stories];
      }
    },

    methods: {
      addStory() {
        this.userStories.push({ q: "", a: "" });
      },
      deleteStory(i) {
        this.userStories.splice(i, 1);
      },
      validate() {
        let valid = true;

        for (const story of this.userStories) {
          if (!story.q || !story.a) {
            valid = false;
            break;
          }
        }
        let complete = valid && !this.isEmpty;
        return { valid, complete };
      },
    },
  };
</script>

<style scoped lang="scss">
  .profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
  }
  .profile-title {
    margin-bottom: 2rem;
  }
  .profile-instructions {
    margin-bottom: 28px;
    text-align: left;
    color: $grey-500;
  }
  .profile-stories {
    margin: 0.5rem 0;
    padding-left: 1.5rem;
  }
  .profile-story {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.25rem;
    margin: 0 0.5rem 1rem;
  }
  .profile-add-btn {
    color: $white;
    background-color: var(--accent-color);
    border-radius: 8px;
    width: 30px;
    height: 30px;
    font-size: 18px;
    margin-left: 2rem;

    &:hover {
      filter: brightness(0.92);
    }

    &:focus {
      outline: 0.25rem solid rgba(var(--accent-color-rgb), 0.3);
    }
  }

  .profile-delete-btn {
    width: 36px;
    height: 36px;
    font-size: 20px;
    color: $grey-300;
    margin: 8px 0;
    padding: 0;
    border: 0.25rem solid transparent;

    &:hover {
      filter: brightness(0.92);
    }

    &:focus {
      outline: none;
      border: 0.25rem solid rgba(var(--accent-color-rgb), 0.3);
    }
  }
</style>
