<template>
  <div class="inviteContainer" v-bind:style="generateInviteContainerStyle()">
    <div id="infoPanel">
      <div id="infoPanel-title">
        <h2>Outreach Requests</h2>
        <div
          v-if="pendingInvites && pendingInvites.length > 0"
          class="badge-number">
          {{ pendingInvites.length }}
        </div>
      </div>
      <div id="comp-requests-cont" @click="switchMode">
        <svg
          id="comp-requests-icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.7725 1.5285C12.5154 1.50952 12.2578 1.50001 12 1.5V0C12.2948 0.000143741 12.5895 0.0111512 12.8835 0.033L12.7725 1.5285ZM15.7785 2.2035C15.2977 2.01796 14.8038 1.86805 14.301 1.755L14.6295 0.291C15.204 0.42 15.7695 0.591 16.3185 0.804L15.7785 2.2035V2.2035ZM17.8335 3.2685C17.619 3.12547 17.3994 2.99038 17.175 2.8635L17.9145 1.5585C18.4274 1.8491 18.9182 2.17699 19.383 2.5395L18.4605 3.723C18.2571 3.56435 18.048 3.41324 17.8335 3.27V3.2685ZM20.5845 5.9535C20.2878 5.53196 19.9604 5.13288 19.605 4.7595L20.691 3.7245C21.096 4.152 21.471 4.6095 21.8115 5.0895L20.5845 5.9535V5.9535ZM21.7005 7.9815C21.602 7.74369 21.495 7.50953 21.3795 7.2795L22.719 6.6045C22.9839 7.13113 23.2095 7.67661 23.394 8.2365L21.969 8.706C21.8884 8.4613 21.7988 8.21964 21.7005 7.9815V7.9815ZM22.4955 11.742C22.4834 11.2265 22.4332 10.7126 22.3455 10.2045L23.823 9.9495C23.9235 10.5285 23.982 11.1165 23.997 11.7045L22.497 11.742H22.4955ZM22.299 14.049C22.3485 13.794 22.389 13.5405 22.4205 13.284L23.91 13.4685C23.838 14.0537 23.7226 14.6328 23.565 15.201L22.119 14.8005C22.188 14.553 22.248 14.3025 22.299 14.049V14.049ZM20.871 17.6175C21.147 17.1825 21.39 16.7265 21.6 16.2555L22.971 16.863C22.731 17.403 22.4535 17.922 22.1385 18.42L20.871 17.6175ZM19.425 19.425C19.608 19.242 19.7835 19.053 19.95 18.858L21.087 19.8375C20.8945 20.0608 20.6938 20.2769 20.4855 20.4855L19.425 19.425V19.425Z"
            fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 1.5C10.2733 1.50012 8.57333 1.92607 7.05058 2.74011C5.52783 3.55415 4.22934 4.73115 3.2701 6.16687C2.31087 7.6026 1.72051 9.25272 1.55131 10.9711C1.38211 12.6895 1.63929 14.423 2.30008 16.0183C2.96087 17.6135 4.00487 19.0212 5.33961 20.1166C6.67434 21.212 8.25862 21.9613 9.95212 22.2982C11.6456 22.6351 13.3961 22.5492 15.0484 22.048C16.7008 21.5468 18.204 20.6459 19.425 19.425L20.4855 20.4855C19.0901 21.8817 17.3719 22.9121 15.4831 23.4855C13.5943 24.0589 11.5932 24.1575 9.65711 23.7727C7.72105 23.3879 5.9098 22.5315 4.38384 21.2793C2.85788 20.0272 1.66432 18.418 0.908918 16.5943C0.153517 14.7707 -0.140404 12.7888 0.0531976 10.8244C0.246799 8.85999 0.921946 6.97365 2.01882 5.33253C3.11569 3.6914 4.60042 2.34617 6.34146 1.416C8.08249 0.485843 10.0261 -0.000523098 12 4.22187e-07V1.5Z"
            fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.25 4.5C11.4489 4.5 11.6397 4.57902 11.7803 4.71967C11.921 4.86032 12 5.05109 12 5.25V13.065L16.872 15.849C17.0397 15.9502 17.1612 16.1129 17.2104 16.3024C17.2597 16.492 17.2329 16.6933 17.1358 16.8633C17.0386 17.0333 16.8788 17.1586 16.6905 17.2124C16.5022 17.2661 16.3003 17.2441 16.128 17.151L10.878 14.151C10.7632 14.0854 10.6678 13.9907 10.6014 13.8764C10.535 13.762 10.5 13.6322 10.5 13.5V5.25C10.5 5.05109 10.579 4.86032 10.7197 4.71967C10.8603 4.57902 11.0511 4.5 11.25 4.5V4.5Z"
            fill="white" />
        </svg>
        <p v-if="mode === 0">View Completed Requests</p>
        <p v-else>View Pending Requests</p>
      </div>
    </div>

    <div v-if="!requestsLoaded">
      <div id="loading-container">
        <div class="sk-fading-circle">
          <div class="sk-circle1 sk-circle"></div>
          <div class="sk-circle2 sk-circle"></div>
          <div class="sk-circle3 sk-circle"></div>
          <div class="sk-circle4 sk-circle"></div>
          <div class="sk-circle5 sk-circle"></div>
          <div class="sk-circle6 sk-circle"></div>
          <div class="sk-circle7 sk-circle"></div>
          <div class="sk-circle8 sk-circle"></div>
          <div class="sk-circle9 sk-circle"></div>
          <div class="sk-circle10 sk-circle"></div>
          <div class="sk-circle11 sk-circle"></div>
          <div class="sk-circle12 sk-circle"></div>
        </div>
      </div>
    </div>
    <div
      v-else-if="mode === 0 && pendingInvites.length > 0"
      class="card-container">
      <div
        v-for="invite in pendingInvites"
        v-bind:key="invite.id"
        @click.stop="changeModalIntermediate($event, invite)"
        class="invite-card">
        <div class="disclaimer pending space-btwn">
          <label v-if="invite.type === 'call' || invite.type === 'call invite'"
            >You haven't had this call yet</label
          >
          <label v-else-if="invite.status === 'cloudinary'"
            >This outreach request is processing and will be ready soon.</label
          >
          <label v-else>You haven't sent this outreach request yet</label>
          <label>Requested on: {{ invite.timeString }}</label>
        </div>
        <div class="info-container">
          <div class="info-sub-cont">
            <img
              v-if="invite.type === 'email'"
              src="../assets/Email.svg"
              alt="Email" />
            <img
              v-else-if="
                invite.type === 'call' || invite.type === 'call invite'
              "
              src="../assets/ThinPhone.svg"
              alt="Phone" />
            <img v-else src="../assets/Video.svg" alt="Video" />
            <h2 v-if="invite.type === 'email'">
              Send Message to: {{ invite.invitee.name }}
            </h2>
            <h2
              v-else-if="
                invite.type === 'call' || invite.type === 'call invite'
              ">
              Call: {{ invite.invitee.name }}
            </h2>
            <h2 v-else>Send Video to: {{ invite.invitee.name }}</h2>
          </div>
          <label class="contact-info-btn" v-if="invite.type.includes('call')"
            >Click To View Contact Information</label
          >
          <label class="contact-info-btn" v-else-if="invite.notes"
            >Click To View School Notes</label
          >
          <label v-else></label>
        </div>
      </div>
    </div>
    <div
      v-else-if="mode === 1 && completedInvites.length > 0"
      class="card-container">
      <div
        v-for="invite in completedInvites"
        v-bind:key="invite.id"
        @click.stop="changeModalIntermediate($event, invite)"
        class="invite-card">
        <span
          v-if="
            invite.type === 'video submission' &&
            (!invite.video ||
              !invite.video.mp4Url ||
              !invite.video.jpgUrl ||
              invite.status === 'cloudinary')
          "
          :style="'pointer-events: none; user-select: none;'">
          <div class="disclaimer pending space-btwn">
            <label>Processing, uploaded on: {{ invite.timeString }}</label>
          </div>
          <div class="info-container">
            <div class="info-sub-cont">
              <img src="../assets/Video.svg" alt="Video" />
              <h2>
                Send Video to: {{ invite.videoInvitationRequest.invitee.name }}
              </h2>
            </div>
            <label
              class="contact-info-btn"
              v-if="invite.videoInvitationRequest.notes"
              >Click To View School Notes</label
            >
            <label v-else></label>
          </div>
        </span>
        <span v-else>
          <div v-if="invite.type === 'email'" class="disclaimer done checked">
            <img src="../assets/Check_darkgreen.png" alt="Check" />
            <label>Outreach request sent!</label>
          </div>
          <div
            v-else-if="invite.type === 'call' || invite.type === 'call invite'"
            class="disclaimer done checked">
            <img src="../assets/Check_darkgreen.png" alt="Check" />
            <label>You called this prospect!</label>
          </div>
          <div
            v-else-if="invite.type === 'video submission'"
            class="disclaimer done checked">
            <img src="../assets/Check_darkgreen.png" alt="Check" />
            <label
              >Video awaiting approval; submitted for review on
              {{ generateTimestamp(invite.createdDate) }}</label
            >
          </div>
          <div
            v-else-if="invite.acceptedUserId"
            class="disclaimer done checked">
            <img src="../assets/Check_darkgreen.png" alt="Check" />
            <label>Outreach request accepted, user signed up!</label>
          </div>
          <div v-else class="disclaimer done checked">
            <img src="../assets/Check_darkgreen.png" alt="Check" />
            <label>Outreach request sent! Waiting for user to sign up...</label>
          </div>
          <div class="info-container">
            <div class="info-sub-cont" v-if="invite.type === 'email'">
              <img src="../assets/Email.svg" alt="Email" />
              <h2>You messaged: {{ invite.invitee.name }}</h2>
            </div>
            <div
              class="info-sub-cont"
              v-if="invite.type === 'call' || invite.type === 'call invite'">
              <img src="../assets/ThinPhone.svg" alt="Call" />
              <h2>You called: {{ invite.invitee.name }}</h2>
            </div>
            <div
              class="info-sub-cont"
              v-if="invite.type === 'video submission'">
              <img src="../assets/Video.svg" alt="Video" />
              <h2>
                You sent a video to:
                {{ invite.videoInvitationRequest.invitee.name }}
              </h2>
            </div>

            <div class="info-sub-cont" v-if="invite.type === 'video invite'">
              <img src="../assets/Video.svg" alt="Video" />
              <h2>
                You sent a video to:
                {{
                  invite.videoInvitationRequestSubmission.videoInvitationRequest
                    .invitee.name
                }}
              </h2>
            </div>
            <video
              v-if="invite.type === 'video submission'"
              playsinline
              class="invite-card-video no-focus-outline"
              type="video/mp4"
              v-bind:src="invite.video.mp4Url"
              v-bind:poster="invite.video.jpgUrl"
              controls />
            <video
              v-else-if="invite.type === 'video invite'"
              playsinline
              class="invite-card-video no-focus-outline"
              type="video/mp4"
              v-bind:src="invite.videoInvitationRequestSubmission.video.mp4Url"
              v-bind:poster="
                invite.videoInvitationRequestSubmission.video.jpgUrl
              "
              controls />
            <label
              v-if="invite.notes && invite.type.includes('call')"
              class="contact-info-btn"
              >Click To View Contact Information</label
            >
            <label v-else-if="invite.notes" class="contact-info-btn"
              >Click To View School Notes</label
            >
            <label
              v-else-if="
                invite.type === 'video submission' &&
                invite.videoInvitationRequest.notes
              "
              class="contact-info-btn"
              >Click To View School Notes</label
            >
            <label
              v-else-if="
                invite.type === 'video invite' &&
                invite.videoInvitationRequestSubmission.videoInvitationRequest
                  .notes
              "
              class="contact-info-btn"
              >Click To View School Notes</label
            >
            <label v-else></label>
          </div>
        </span>
      </div>
    </div>

    <div class="modal" v-if="showInviteModal">
      <div class="modal-div">
        <div class="modal-block">
          <h2 v-if="!selectedInvitation.inviteSent">
            Invite {{ selectedInvitation.invitee.name }} to message you!
          </h2>
          <h2 v-else>
            You've sent {{ selectedInvitation.invitee.name }} a message
          </h2>
          <img
            src="../assets/X.png"
            alt="Cancel"
            @click="changeInviteModal"
            class="x" />
        </div>
        <div class="modal-notes" v-if="selectedInvitation.notes">
          <p class="call-info-box-h3 mb-5">
            Notes on {{ selectedInvitation.invitee.name }}:
          </p>
          <textarea-autosize
            class="call-info-box-large-input-notes"
            v-model="selectedInvitation.notes"
            disabled />
        </div>
        <div v-if="!selectedInvitation.inviteSent">
          <div id="subject-container">
            <label id="subject"><strong>Subject: </strong></label>
            <select v-model="pickedSubject">
              <option hidden disabled placeholder>
                Select an Email Subject Line
              </option>
              <option>Introduction from {{ currentUserName }}</option>
              <option>
                Welcome to the {{ orgDisplayName }} Community from
                {{ currentUserName }}
              </option>
              <option>Follow up from your Tour at {{ orgDisplayName }}</option>
              <option>Greetings from {{ orgDisplayName }}</option>
              <option>Write Your Own</option>
            </select>
          </div>
          <input
            v-if="pickedSubject === 'Write Your Own'"
            type="text"
            placeholder="Subject of the email"
            v-model="typedSubject"
            class="input" />
          <textarea
            :placeholder="placeholder"
            v-model="selectedInvitation.message"
            class="textarea"></textarea>
          <button @click="sendInvitation">Send Message</button>
        </div>
        <div v-else>
          <h4 class="info-text">
            Message you sent: {{ selectedInvitation.message.body }}
          </h4>

          <h5 class="info-text">
            * Your message will create a conversation thread once
            {{ selectedInvitation.invitee.name }} creates an account. *
          </h5>
        </div>
      </div>
    </div>

    <ModalInvitesCall
      v-if="selectedCallOutreachRequest"
      :outreach="selectedCallOutreachRequest"
      :orgDisplayName="orgDisplayName"
      :organizationId="organizationId"
      @close="selectedCallOutreachRequest = null"
      @feedback="completeRequest(selectedCallOutreachRequest)" />

    <div class="modal" v-if="selectedVideoInviteRequest">
      <div class="modal-div video-modal-div">
        <div>
          <img
            src="../assets/X.png"
            alt="Cancel"
            @click="selectedVideoInviteRequest = null"
            class="video-modal-cancel" />
        </div>

        <div class="modal-block">
          <h2>
            Please upload a video for
            {{ selectedVideoInviteRequest.invitee.name }}
          </h2>
        </div>
        <div class="modal-notes" v-if="selectedVideoInviteRequest.notes">
          <p class="call-info-box-h3 mb-5">
            Notes on {{ selectedVideoInviteRequest.invitee.name }}:
          </p>
          <textarea-autosize
            rows="1"
            class="call-info-box-large-input-notes"
            v-model="selectedVideoInviteRequest.notes"
            disabled />
        </div>

        <div class="recorder">
          <VideoRecorder @update="videoData = $event.videoData" />
        </div>

        <div>
          <div id="subject-container">
            <div id="subject-separator" />

            <h2>Create Your Email</h2>

            <select required v-model="pickedSubject">
              <option hidden disabled placeholder>
                Select an Email Subject Line
              </option>
              <option>Introduction from {{ currentUserName }}</option>
              <option>
                Welcome to the {{ orgDisplayName }} Community from
                {{ currentUserName }}
              </option>
              <option>Follow up from your Tour at {{ orgDisplayName }}</option>
              <option>Greetings from {{ orgDisplayName }}</option>
              <option>Write Your Own</option>
            </select>
          </div>
          <input
            v-if="pickedSubject === 'Write Your Own'"
            type="text"
            placeholder="Subject of the email"
            v-model="typedSubject"
            class="input" />
          <textarea
            :placeholder="placeholder"
            v-model="selectedVideoInviteRequest.message"
            class="textarea"></textarea>
          <button @click="sendVideoInviteRequestClicked">Send Message</button>

          <div class="video-modal-padding" />
        </div>
      </div>
    </div>

    <div class="modal" v-if="selectedVideoInviteSubmission">
      <div class="modal-div">
        <div>
          <img
            src="../assets/X.png"
            alt="Cancel"
            @click="selectedVideoInviteSubmission = null"
            class="video-modal-cancel" />
        </div>

        <div class="modal-block">
          <h2>You've submitted a video outreach request for review.</h2>

          <span
            v-if="selectedVideoInviteSubmission.videoInvitationRequest.notes">
            <p class="call-info-box-h3 mb-5">
              Notes on
              {{
                selectedVideoInviteSubmission.videoInvitationRequest.invitee
                  .first
              }}:
            </p>
            <textarea-autosize
              class="call-info-box-large-input-notes"
              v-model="
                selectedVideoInviteSubmission.videoInvitationRequest.notes
              "
              disabled />
          </span>
        </div>

        <div>
          <h4 v-if="selectedVideoInviteSubmission.message" class="info-text">
            <strong>Message you sent:</strong>
            {{ selectedVideoInviteSubmission.message.body }}
          </h4>
          <h5 class="info-text">
            * This invite will be sent once it is accepted *
          </h5>
        </div>
      </div>
    </div>

    <div class="modal" v-if="selectedVideoInvite">
      <div class="modal-div">
        <div>
          <img
            src="../assets/X.png"
            alt="Cancel"
            @click="selectedVideoInvite = null"
            class="video-modal-cancel" />
        </div>

        <div class="modal-block">
          <h2>
            You've sent
            {{
              selectedVideoInvite.videoInvitationRequestSubmission
                .videoInvitationRequest.invitee.name
            }}
            a message
          </h2>

          <span
            v-if="
              selectedVideoInvite.videoInvitationRequestSubmission
                .videoInvitationRequest.notes
            ">
            <p class="call-info-box-h3 mb-5">
              Notes on
              {{
                selectedVideoInvite.videoInvitationRequestSubmission
                  .videoInvitationRequest.invitee.first
              }}:
            </p>
            <textarea-autosize
              class="call-info-box-large-input-notes"
              v-model="
                selectedVideoInvite.videoInvitationRequestSubmission
                  .videoInvitationRequest.notes
              "
              disabled />
          </span>
        </div>

        <div>
          <h4
            v-if="selectedVideoInvite.videoInvitationRequestSubmission.message"
            class="info-text">
            <strong>Message you sent:</strong>
            {{ selectedVideoInvite.videoInvitationRequestSubmission.message }}
          </h4>
          <h5 v-if="selectedVideoInvite.acceptedUserId" class="info-text">
            * This outreach request has been accepted! *
          </h5>
          <h5 v-else class="info-text">
            * This user has not accepted the outreach request yet *
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    sendInvitation,
    uploadVideoInviteSubmissionRecord,
    getInvitesEmail,
    getInvitesVideo,
    getCallRequests,
    getInvitesCall,
    getVideoSubmissionFromVideoRequest,
  } from "@/services/api.service.js";
  const api = {
    sendInvitation,
    uploadVideoInviteSubmissionRecord,
    getInvitesEmail,
    getInvitesVideo,
    getCallRequests,
    getInvitesCall,
    getVideoSubmissionFromVideoRequest,
  };
  import VideoRecorder from "./VideoRecorder.vue";
  import ModalInvitesCall from "@/components/modal/ModalInvitesCall.vue";
  import { mapState } from "vuex";
  import common from "@/common/common";

  export default {
    props: ["inviteTab"],
    components: {
      VideoRecorder,
      ModalInvitesCall,
    },
    data() {
      return {
        requestsLoaded: false,
        mode: 0,
        pendingInvites: [],
        completedInvites: [],
        showInviteModal: false,
        selectedInvitation: null,
        sectionPicked: 0,
        pickedSubject: "Select an Email Subject Line",
        typedSubject: null,
        selectedCallOutreachRequest: null,
        /* video invites */
        invitationTypePicked: 0,
        videoSectionPicked: 0,
        videoInvitationRequests: [],
        videoInvitationSubmissions: [],
        videoInvitations: [],
        selectedVideoInviteRequest: null,
        selectedVideoInviteSubmission: null,
        selectedVideoInvite: null,
        cloudinaryRequests: [],
        videoData: null,
      };
    },

    computed: {
      currentUserName() {
        return common.toFullName(this.user.first, this.user.last);
      },

      placeholder() {
        if (!this.selectedInvitation) {
          if (!this.selectedVideoInviteRequest) {
            return "";
          }

          return `Personalize the email message that ${this.selectedVideoInviteRequest.invitee.name} will see when they receive your video.`;
        }

        return `Message you want to send (${this.selectedInvitation.invitee.name} will see this in their inbox and be able to chat with you once they sign up)`;
      },

      isSafari() {
        return (
          navigator.vendor &&
          navigator.vendor.indexOf("Apple") > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf("CriOS") == -1 &&
          navigator.userAgent.indexOf("FxiOS") == -1
        );
      },

      ...mapState(["orgDisplayName", "token", "uid", "user", "organizationId"]),
    },

    watch: {
      pendingInvites(invites) {
        // update outreach count
        this.user.outreachCount = invites.length;
        this.$store.dispatch("setUser", this.user);
      },
    },

    async created() {
      if (!this.token) {
        this.$store.dispatch("clearLoginCookies");

        this.$router.push({ name: "Login", params: { invite: true } });
      }

      const { emailPendingInvites, emailCompletedInvites } =
        await this.loadEmailInvites();
      const { videoRequests, videoSubmissions, videoInvitations } =
        await this.loadVideoInvites();
      const { pendingCalls, completedCalls } = await this.loadCallRequests();
      const { pendingCallInvites, completedCallInvites } =
        await this.loadCallOutreachRequests();

      // combine all pending outreach requests, and sort from oldest to newest
      let combinedRequests = [
        ...emailPendingInvites,
        ...videoRequests,
        ...pendingCalls,
        ...pendingCallInvites,
      ];

      //sorting by timestamp of request (oldest -> newest)
      let n = combinedRequests.length;
      let i;

      for (i = 0; i < n - 1; i++) {
        // Find the max element in unsorted array
        let minIndex = i;
        let j;
        for (j = i + 1; j < n; j++) {
          if (
            combinedRequests[j].numTimeStamp <
            combinedRequests[minIndex].numTimeStamp
          ) {
            minIndex = j;
          }
        }
        // Swap the found max element with the first element
        let temp = combinedRequests[minIndex];
        combinedRequests[minIndex] = combinedRequests[i];
        combinedRequests[i] = temp;
      }

      let combinedCompletedRequests = [
        ...emailCompletedInvites,
        ...videoSubmissions,
        ...videoInvitations,
        ...completedCalls,
        ...completedCallInvites,
      ];

      //sorting by timestamp of request (newest -> oldest)
      let m = combinedCompletedRequests.length;
      let h;

      for (h = 0; h < m - 1; h++) {
        // Find the max element in unsorted array
        let maxIndex = h;
        let j;
        for (j = h + 1; j < m; j++) {
          if (
            combinedCompletedRequests[j].numTimeStamp >
            combinedCompletedRequests[maxIndex].numTimeStamp
          ) {
            maxIndex = j;
          }
        }
        // Swap the found max element with the first element
        let temp = combinedCompletedRequests[maxIndex];
        combinedCompletedRequests[maxIndex] = combinedCompletedRequests[h];
        combinedCompletedRequests[h] = temp;
      }

      this.pendingInvites = combinedRequests;
      this.completedInvites = combinedCompletedRequests;

      this.requestsLoaded = true;
    },

    methods: {
      switchMode() {
        // to switch between looking at pending requests and complete requests
        if (this.mode === 0) {
          this.mode = 1;
        } else {
          this.mode = 0;
        }
      },

      changeModalIntermediate(event, invite) {
        switch (invite.type) {
          case "email":
            this.changeInviteModal(invite);
            break;
          case "video request":
            this.changeVideoInviteRequest(invite);
            break;
          case "video submission":
            this.changeVideoInviteSubmission(event, invite);
            break;
          case "video invite":
            this.changeVideoInvite(event, invite);
            break;
          default:
            // this is meant for "call" || "call invite" to use the same modal for now
            this.changeCallRequestModal(invite);
            break;
        }
      },

      changeInviteModal(invite) {
        if (this.showInviteModal) {
          this.showInviteModal = false;
          this.selectedInvitation = null;
        } else {
          this.showInviteModal = true;
          this.selectedInvitation = invite;
        }
      },

      changeCallRequestModal(call) {
        this.selectedCallOutreachRequest = this.selectedCallOutreachRequest
          ? null
          : call;
      },

      isModalOpen() {
        const isOpen =
          this.showInviteModal ||
          this.selectedCallOutreachRequest ||
          this.selectedVideoInviteRequest ||
          this.selectedVideoInviteSubmission ||
          this.selectedVideoInvite;
        if (isOpen) {
          return true; // return boolean as isOpen will be the first non-null object
        } else {
          return false;
        }
      },

      changeVideoInviteRequest(invite) {
        if (this.selectedVideoInviteRequest) {
          this.selectedVideoInviteRequest = null;
        } else {
          this.selectedVideoInviteRequest = invite;
        }
      },

      changeVideoInviteSubmission(event, invite) {
        if (event.target.tagName === "VIDEO") {
          return; // ignore because we're tapping on video (happens on mobile)
        }

        if (this.selectedVideoInviteSubmission) {
          this.selectedVideoInviteSubmission = null;
        } else {
          this.selectedVideoInviteSubmission = invite;
        }
      },

      changeVideoInvite(event, invite) {
        if (event.target.tagName === "VIDEO") {
          return; // ignore because we're tapping on video (happens on mobile)
        }

        if (this.selectedVideoInvite) {
          this.selectedVideoInvite = null;
        } else {
          this.selectedVideoInvite = invite;
        }
      },

      completeRequest(outreach) {
        // remove request from pending and put in completed
        this.pendingInvites = this.pendingInvites.filter((inv) => {
          return inv._id !== outreach._id;
        });
        this.completedInvites.unshift(outreach);
        this.changeCallRequestModal(null);
      },

      generateTimestamp(updatedAt) {
        const date = new Date(updatedAt);
        const options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString(this.$locale, options);
      },

      generateInviteContainerStyle() {
        let styleObject = {};

        if (this.isModalOpen()) {
          styleObject.overflowY = "hidden";
        } else {
          styleObject.overflowY = "auto";
        }

        return styleObject;
      },

      sendVideoInviteRequestClicked() {
        if (!this.videoData) {
          this.showBannerError(
            "You have to record or choose a video before you can submit this outreach request."
          );
          return;
        }

        const result = confirm(`Are you sure you want to submit this video?`);
        if (!result) {
          return;
        }

        //subject line
        let subject;
        if (this.pickedSubject === "Select an Email Subject Line") {
          this.showBannerError("You must select an Email Subject Line.");
          return;
        }

        if (this.pickedSubject === "Write Your Own") {
          subject = this.typedSubject;
        } else {
          subject = this.pickedSubject;
        }

        const formData = new FormData();
        formData.append("video", this.videoData, this.videoData.name);
        formData.append("subject", subject);

        const request = this.selectedVideoInviteRequest;
        formData.append("videoInvitationRequest", request._id);
        formData.append("message", request.message ? request.message : "");

        this.selectedVideoInviteRequest = null; //closes modal

        this.showBannerSticky("Uploading, please wait...");

        api
          .uploadVideoInviteSubmissionRecord(formData)
          .then((recordResponse) => {
            document.title = document.title.split("- Uploading")[0];
            this.$toasted.clear();
            if (!recordResponse || !recordResponse.data) {
              return;
            }

            if (process.env.VUE_APP_API_KEY) {
              this.$amplitude.getInstance().logEvent("INVITE_SENT");
            }

            const responseSubmission = recordResponse.data;
            if (responseSubmission.status === "cloudinary") {
              this.showBannerSuccess(
                `Your outreach request to ${request.invitee.name} has been submitted successfully.`
              );

              this.pendingInvites = this.pendingInvites.map((invite) => {
                if (invite._id == request._id) {
                  invite.videoInvitationRequest = request;
                  invite.timeString = this.getDateFromISO(
                    responseSubmission.createdDate
                  );
                  invite.numTimeStamp = this.getUnixFromISO(
                    responseSubmission.createdDate
                  );
                  invite.status = "cloudinary";
                  invite.type = "video submission";
                }

                return invite;
              });

              this.cloudinaryRequests = this.cloudinaryRequests.concat([
                request,
              ]);
              this.checkCloudinaryRequests(1000);
            }
          })
          .catch((recordError) => {
            document.title = document.title.split("- Uploading")[0];
            this.$toasted.clear();
            this.showBannerError("Unable to upload video");
            console.log(recordError);
          });
      },

      checkCloudinaryRequests(delay) {
        if (this.cloudinaryRequests.length < 1) {
          return;
        }

        const requests = this.cloudinaryRequests;
        this.cloudinaryRequests = [];

        for (let request of requests) {
          api
            .getVideoSubmissionFromVideoRequest(request._id)
            .then((response) => {
              if (
                response.data &&
                response.data.video &&
                response.data.video.mp4Url &&
                response.data.video.jpgUrl
              ) {
                this.showBannerSuccess(
                  `Your outreach request to ${request.invitee.name} has finished processing.`
                );

                this.pendingInvites = this.pendingInvites.filter((pending) => {
                  return pending._id !== request._id;
                });

                let submission = response.data;
                submission.videoInvitationRequest = request;
                submission.timeString = this.getDateFromISO(
                  submission.createdDate
                );
                submission.numTimeStamp = this.getUnixFromISO(
                  submission.createdDate
                );
                submission.type = "video submission";
                this.completedInvites = [submission].concat(
                  this.completedInvites
                );
              } else {
                this.cloudinaryRequests = this.cloudinaryRequests.concat([
                  request,
                ]);
                setTimeout(() => {
                  this.checkCloudinaryRequests(delay);
                }, delay);
              }
            })
            .catch((err) => {
              if (err && err.response && err.response.status === 404) {
                this.cloudinaryRequests = this.cloudinaryRequests.concat([
                  request,
                ]);
                setTimeout(() => {
                  this.checkCloudinaryRequests(delay);
                }, delay);
              } else {
                console.log(err);
              }
            });
        }
      },

      sendInvitation() {
        //post request

        if (!this.selectedInvitation.message) {
          this.showBannerError(
            "You have to add a message to your outreach request before you can send it!"
          );
          return;
        }

        let result = confirm(
          `Are you sure you want to send an outreach request to ${this.selectedInvitation.invitee.name}?`
        );
        if (!result) {
          return;
        }

        //subject line
        let subject;
        if (this.pickedSubject === "Write Your Own") {
          subject = this.typedSubject;
        } else {
          subject = this.pickedSubject;
        }

        api
          .sendInvitation(
            this.selectedInvitation.message,
            subject,
            this.selectedInvitation.id,
            window.location.origin
          )
          .then(() => {
            //shifting the invitation from pending to completed
            this.pendingInvites = this.pendingInvites.filter(
              (invitation) => invitation != this.selectedInvitation
            );
            this.selectedInvitation.message = {
              body: this.selectedInvitation.message,
            };
            this.selectedInvitation.inviteSent = true;
            this.completedInvites.unshift(this.selectedInvitation);

            if (process.env.VUE_APP_API_KEY) {
              this.$amplitude.getInstance().logEvent("INVITE_SENT");
            }

            const name = this.selectedInvitation.invitee.name;
            this.changeInviteModal();

            setTimeout(() => {
              this.showBannerSuccess(
                `You sent ${name} their outreach request to the platform! You should see the message you sent in your inbox once they sign up.`
              );
            }, 50);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      async loadEmailInvites() {
        //endpoint for receiving invitations (sort by inviteSent true and false and createdDate)
        let response;
        try {
          response = await api.getInvitesEmail();
        } catch (err) {
          console.log(err);
        }

        if (!response || !response.data) {
          console.log("Invalid response from server.");
          return {
            emailPendingInvites: [],
            emailCompletedInvites: [],
          };
        }

        if (!response.data.invitations) {
          console.log("No outreach requests found at all.");
          return {
            emailPendingInvites: [],
            emailCompletedInvites: [],
          };
        }

        let invitations = response.data.invitations || [];
        const pendingInvites = [];
        const completedInvites = [];

        invitations.forEach((invitation) => {
          invitation.timeString = this.getDateFromISO(invitation.createdDate);
          invitation.numTimeStamp = this.getUnixFromISO(invitation.createdDate);
          invitation.type = "email";

          if (invitation.inviteSent) {
            completedInvites.push(invitation);
          } else {
            pendingInvites.push(invitation);
          }
        });

        return {
          emailPendingInvites: pendingInvites,
          emailCompletedInvites: completedInvites,
        };
      },

      async loadVideoInvites() {
        // 3 data points needs for video invitations:
        // video invitation requests, submissions, and invites
        // all of which are returned from the /users/activeVideoInvites endpoint
        let response;
        try {
          response = await api.getInvitesVideo();
        } catch (err) {
          console.log(err);
        }

        if (!response || !response.data) {
          console.log("Invalid response from server.");
          this.videoInvitationRequests = [];
          this.videoInvitationSubmissions = [];
          this.videoInvitations = [];

          return {
            videoRequests: [],
            videoSubmissions: [],
            videoInvitations: [],
          };
        }

        const { requests, submissions, invitations } = response.data;

        let requestsWithType = requests.map((request) => {
          request.timeString = this.getDateFromISO(request.createdDate);
          request.numTimeStamp = this.getUnixFromISO(request.createdDate);
          request.type = "video request";
          return request;
        });

        const submissionsWithVideoURLs = submissions.flatMap((submission) => {
          if (
            submission.status === "cloudinary" ||
            !submission.video ||
            !submission.video.mp4Url ||
            !submission.video.jpgUrl
          ) {
            const request = submission.videoInvitationRequest;
            request.status = "cloudinary";
            request.timeString = this.getDateFromISO(submission.createdDate);
            request.numTimeStamp = this.getUnixFromISO(submission.createdDate);
            request.type = "video request";
            requestsWithType = requestsWithType.concat([request]);

            this.cloudinaryRequests = this.cloudinaryRequests.concat([request]);
            this.checkCloudinaryRequests(1000);

            return [];
          }

          submission.timeString = this.getDateFromISO(submission.createdDate);
          submission.numTimeStamp = this.getUnixFromISO(submission.createdDate);
          submission.type = "video submission";
          return submission;
        });

        const invitationsWithVideoURLS = invitations.map((invitation) => {
          invitation.timeString = this.getDateFromISO(invitation.createdDate);
          invitation.numTimeStamp = this.getUnixFromISO(invitation.createdDate);
          invitation.type = "video invite";
          return invitation;
        });

        return {
          videoRequests: requestsWithType,
          videoSubmissions: submissionsWithVideoURLs,
          videoInvitations: invitationsWithVideoURLS,
        };
      },

      loadCallRequests: async function () {
        let response;
        let pendingCalls = [];
        let completedCalls = [];

        try {
          response = await api.getCallRequests();
        } catch (err) {
          console.log(err);
        }

        if (response) {
          pendingCalls = this.mapCallData(
            response.data.pendingCalls || [],
            "call"
          );
          completedCalls = this.mapCallData(
            response.data.completedCalls || [],
            "call"
          );
        }

        return { pendingCalls, completedCalls };
      },

      mapCallData(calls, type) {
        calls.forEach((request) => {
          request.timeString = this.getDateFromISO(request.createdDate);
          request.numTimeStamp = this.getUnixFromISO(request.createdDate);
          request.type = type;
        });

        return calls;
      },

      async loadCallOutreachRequests() {
        let response;
        let pendingCallInvites = [];
        let completedCallInvites = [];

        try {
          response = await api.getInvitesCall();
        } catch (err) {
          console.log(err);
        }

        if (response) {
          pendingCallInvites = this.mapCallData(
            response.data.pendingRequests || [],
            "call invite"
          );

          completedCallInvites = this.mapCallData(
            response.data.completedRequests || [],
            "call invite"
          );
        }

        return { pendingCallInvites, completedCallInvites };
      },

      getDateFromISO(isoTime) {
        // get the date of the outreach request from the mongo autogen timestamp
        let iso = isoTime;
        const options = {
          month: "numeric",
          day: "numeric",
        };
        const time = new Date(iso);
        const formattedTime = time.toLocaleDateString("en-US", options);
        return formattedTime;
      },

      getUnixFromISO(isoTime) {
        return Date.parse(isoTime);
      },

      showBanner(message) {
        this.$toasted.show(message, {
          position: "bottom-left",
          duration: 2000,
          className: "invite-banner",
          theme: "outline",
        });
      },

      showBannerSuccess(message) {
        this.$toasted.show(message, {
          position: "bottom-left",
          duration: 2000,
          className: "invite-banner",
          containerClass: "invite-banner-success-container",
          theme: "outline",
        });
      },

      showBannerError(message) {
        this.$toasted.show(message, {
          position: "bottom-left",
          duration: 2000,
          className: "invite-banner",
          containerClass: "invite-banner-error-container",
          theme: "outline",
        });
      },

      showBannerSticky(message) {
        this.$toasted.show(message, {
          position: "bottom-left",
          className: "invite-banner",
          theme: "outline",
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .mb-5 {
    margin-bottom: 5px;
  }
  .inviteContainer {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100px auto;
    overflow-y: auto;
  }
  #infoPanel {
    padding-top: 40px;
    width: 100%;
    height: 60px;
    background-color: var(--school-color);
    line-height: 1;
  }
  #infoPanel-title {
    display: flex;
    justify-content: center;
  }
  #infoPanel h2 {
    margin: 0;
    color: var(--text-color);
    line-height: 30px;
  }
  #comp-requests-cont {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
  #comp-requests-cont > p {
    margin: 0 10px;
    color: var(--text-color);
    font-weight: bold;
  }
  #comp-requests-icon {
    width: 20px;
    height: 20px;
  }
  .badge-number {
    margin: 0 0 0 10px;

    max-width: 100px;
    max-height: 15px;

    border: 2px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.9);
    color: #0076ff;

    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

    border-radius: 5px;

    padding: 5px;
  }

  .video-invitation-container {
    margin-top: 180px;
    width: 100%;
    position: absolute;
  }
  .video-picker-container {
    margin-top: -30px;
    height: 60px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }
  .video-picker-container h2 {
    height: 30px;
    margin: 12.5px 0;
    font-size: 22.5px;
  }
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .invite-card {
    width: 60%;
    min-width: 450px;
    border-radius: 10px;
    margin: 20px 0;
    overflow-y: scroll;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px rgba(166, 166, 166, 0.75);
    border: 1px solid lightgrey;
    transition: transform 0.2s ease-in-out;
    transform: scale(1, 1);
  }
  .invite-card:hover {
    transform: scale(1.05);
  }
  .invite-card:active {
    background-color: lightgrey;
  }
  .pending {
    display: flex;
    justify-content: left;
    height: 32px;
    width: 100%;
    cursor: pointer;
  }
  .disclaimer {
    display: flex;
  }
  .disclaimer label {
    display: block;
    font-size: 15px;
    margin: 0 5px;
    cursor: pointer;
    text-align: left;
    padding: 5px;
    line-height: 1.5;
  }
  .space-btwn {
    justify-content: space-between;
  }
  .checked {
    background-color: #adedb3 !important;
  }
  .checked label {
    color: rgb(48, 135, 54) !important;
  }
  .checked img {
    filter: color #43b65f;
  }
  .pending {
    background-color: #add8e6;
  }
  .pending label {
    color: #3a9fbf;
  }
  .done {
    background-color: lightgrey;
  }
  .done img {
    height: 25px;
    width: 25px;
    margin: auto 0 auto 15px;
  }
  .info-container {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
  .info-sub-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .info-sub-cont > h2 {
    margin: 0;
    font-size: 22.5px;
  }
  .info-sub-cont > img {
    height: 20px;
    margin-right: 10px;
  }
  .info-container label {
    display: flex;
    justify-content: center;
    width: 90%;
    overflow: wrap;
    margin: auto;
    min-height: 20px;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .modal {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100vh;
  }
  .modal-div {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 600px;
    min-height: 100px;
    max-height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
  }
  .modal-block {
    display: block;
  }
  .modal-div h2 {
    width: calc(100% - 60px);
    margin: 15px 0 20px 30px;
    float: left;
    clear: both;
    box-sizing: border-box;
  }
  .modal-div h3 {
    font-weight: 400;
    margin: 15px 0;
    font-size: 17.5px;
  }
  .modal-div .x {
    height: 20px;
    width: 20px;
    margin: 0;
    cursor: pointer;
  }
  .modal-div .feedback-block h2 {
    margin-top: -4px;
  }
  .modal-div .feedback-back-btn {
    float: left;
  }
  .modal-div .feedback-x-btn {
    float: right;
  }
  .contact-info-btn {
    color: #3c9ebf;
    font-weight: 700;
  }
  .video-modal-cancel {
    display: block;
    width: 20px;
    height: 20px;

    margin-left: auto;
    text-align: right;

    cursor: pointer;
  }
  .textarea {
    background-color: #eeeeee;
    margin: 10px 20px 10px 20px;
    display: block;
    width: calc(100% - 40px);
    height: 160px;
    border: 0;
    border-radius: 15px;
    padding: 5px 10px;
    box-sizing: border-box;
    font-size: 16px;
    resize: none;
  }
  .textarea:focus {
    outline: none;
  }
  .modal-div button {
    height: 40px;
    padding: 10px 15px 10px 15px;
    background-color: var(--accent-color);
    color: #fff;
    border: 0;
    border-radius: 20px;
    font-size: 16px;
    margin: 10px 0 0 0;
    cursor: pointer;
  }
  .modal-div button:focus {
    outline: none;
    cursor: pointer;
  }
  .modal-div button:active {
    opacity: 0.5;
  }
  .modal-div select {
    border: none;
    background-color: #eeeeee;
    padding: 5px 10px;
    border-radius: 20px;
    width: calc(100% - 40px);
    font-size: 16px;
  }
  select:invalid {
    color: #6e6e6e;
  }
  option:enabled {
    color: black !important;
  }

  .modal-div select:focus {
    outline: none;
  }
  .info-text {
    font-weight: 400;
    margin: 15px 0;
    font-size: 17.5px;
    word-wrap: break-word;
  }
  .input {
    width: calc(100% - 40px);
    height: 46px;
    padding: 10px 10px 8px 14px;
    margin: 10px 20px 0px 20px;
    border: 0;
    background-color: #edeeed;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;
    color: black;
    overflow-y: scroll;
    resize: none;
    font-size: 17.5px;
  }
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }
  #subject-container {
    margin-top: 0px;
  }
  #subject {
    font-size: 17.5px;
  }
  .mobile {
    height: 100%;
    width: 100%;
  }
  .mobile .titleContainer {
    border-bottom: 1px solid lightgrey;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .mobile h2 {
    color: black;
    margin: 10px;
    align-content: center;
  }
  .mobile .badge {
    width: 150px;
    height: 50px;
    object-fit: contain;
    cursor: pointer;
  }
  @media (max-width: 460px) {
    .invite-card {
      width: 80%;
    }
  }

  .video-modal-div {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    max-width: 1400px;
    max-height: 80%;
    overflow-y: scroll;
  }

  @media (max-width: $xs) {
    .video-modal-div {
      width: 100%;
      height: 100%;
    }

    .modal-div .textarea {
      height: 200px;
    }
  }

  .video-modal-padding {
    padding: 10px;
  }

  .invite-card-video {
    z-index: 100;
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    margin: 0;
    border-radius: 18px;
  }

  .no-focus-outline,
  .no-focus-outline:focus {
    outline: none;
  }

  #subject-separator {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.25);
    width: 80%;
    max-width: 600px;

    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .recorder {
    margin: 0 auto;
  }

  video {
    background-color: transparent !important;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }

  .call-info-box-h3 {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    text-align: left;
  }
  .call-info-box-large-input-notes {
    font-size: 16px;
    font-weight: 500;
    padding: 5px 10px;
    color: #000000;
    background-color: #f4f4f4;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    width: 100%;
    resize: none;
    height: auto;
    display: block;
    box-sizing: border-box;
  }

  .modal-notes {
    text-align: left;
    margin: 20px;
  }

  /* loading styles */
  #loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sk-fading-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-fading-circle .sk-circle:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  .sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  .sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  .sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  .sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  .sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  .sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  .sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  .sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  .sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -var(--transition-duration);
    animation-delay: -var(--transition-duration);
  }
  .sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }

  @keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
</style>

<style>
  /* global banner styles */
  .invite-banner-success-container > * {
    background: #37d566 !important;
    color: #fff !important;
  }

  .invite-banner-error-container > * {
    background: #eb001e !important;
    color: #fff !important;
  }
</style>
