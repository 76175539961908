import roles from "@/constants/roles.js";

export function initRouteGuard(router, store) {
  router.beforeEach(async (to, _from, next) => {
    if (
      !!process.env.VUE_APP_MAINTENANCE_END_DATE &&
      to.name !== "Maintenance"
    ) {
      next({
        name: "Maintenance",
      });
    }
    if (
      !process.env.VUE_APP_MAINTENANCE_END_DATE &&
      to.name === "Maintenance"
    ) {
      next({
        name: "Login",
      });
    }

    const user = store.state.user || (await store.dispatch("fetchUser"));

    // If permissions are set, all roles allowed to access the page must be included
    // If permissions are not set, the page is assumed to be public-facing
    if (to.matched.some((route) => route.meta?.permissions?.length)) {
      // Route has permissions required, check them

      if (!user || user?.signedUpOrg) {
        // Short circuit if user is ambassador, for now, since ambassadors aren't limited anywhere.
        // This will need to be removed if we do proper role-based auth and add more roles other than amb/prospect

        // Check if ambassador role is on route
        const userRole = !user
          ? roles.UNAUTHENTICATED
          : user.signedUpOrg
          ? roles.PROSPECT
          : roles.AMBASSADOR;
        let isAuthorized = true;

        for (const route of to.matched) {
          if (route.meta?.permissions) {
            if (!route.meta.permissions.includes(userRole)) {
              isAuthorized = false;
              break;
            }
          }
        }

        if (to.name !== "Login" && !isAuthorized) {
          // If the user is not going to the Login route and is unauthorized, redirect to Login and display error
          return next({
            name: "Login",
            query: { displayAuthError: true, redirect: to.fullPath },
          });
        }
      }
    }

    // redirect hash mode
    if (to.fullPath.slice(0, 2) === "/#") {
      const path = to.fullPath.slice(2);
      return next(path);
    }

    next();
  });
}
