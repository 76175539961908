<template>
  <Scrollbar
    scrollToTopBtn
    @bottom-reached="onBottomReached"
    class="landing-page">
    <div v-if="pageLoaded">
      <div class="container contents">
        <div id="text">
          <h1 v-if="user">{{ $lang.landingPage_Welcome }} {{ user.first }}</h1>
          <h1 v-else>{{ landingPage[0] }}</h1>
          <span>{{ landingPage[1] }}</span>
        </div>
        <div class="links" v-if="landingPageLinks">
          <a
            v-for="(link, index) in landingPageLinks"
            :key="link.name"
            class="btn btn-outline btn-wide"
            @click="goToLink(index)"
            :href="link.link"
            rel="noreferrer"
            target="_blank">
            {{ link.name }}
          </a>
        </div>
        <common-search
          class="search-bar"
          v-model="searchText"
          @input="onSearchInput" />
        <div v-if="searching || !profilesLoaded" class="card-holder">
          <ProfileCard skeleton></ProfileCard>
          <ProfileCard skeleton></ProfileCard>
          <ProfileCard skeleton></ProfileCard>
        </div>
        <div v-else-if="displayedProfiles" class="card-holder">
          <ProfileCard
            v-for="profile in displayedProfiles"
            :key="profile.id"
            :user="profile"
            :landingPage="true"
            :profileFields="profileFields"
            :canSendMessage="canSendMessage && profile.canSendMessage"
            :canRequestCall="canRequestCall && profile.requestCall"
            :videoStory="profile.videoStory"
            @videoStoryClicked="playingVideoStory = $event"
            @my-story-click="showMyStory(profile)"
            @startChat="switchMessageModal"
            @startReqCall="switchCallModal"></ProfileCard>
        </div>
        <h2 v-else class="landing-page-no-profile-text">
          {{ $lang.landingPage_NoProfileText }}
        </h2>
      </div>
    </div>

    <common-modal
      v-if="playingVideoStory"
      hideFooter
      :video="playingVideoStory && playingVideoStory.video.mp4Url"
      @close="playingVideoStory = null">
    </common-modal>

    <common-modal
      :title="$lang.landingPage_MessageModalHeader.format(ambassador.first)"
      v-if="showMessageModal"
      @close="switchMessageModal()">
      <div id="chat-container">
        <div
          v-for="(message, index) in messages"
          :key="message.id"
          class="message-container">
          <div
            :class="{
              'their-message': index === 0,
              'my-message': index > 0,
            }">
            <span>{{ message.body }}</span>
          </div>
        </div>
      </div>

      <template #footer>
        <div id="inputBox">
          <common-input
            :rows="1"
            :placeholder="$lang.landingPage_MessageModalMessagePlaceholder"
            class="auto-resize"
            :max-height="200"
            v-model="messageText"
            @keyup.native.enter="onSendMessage"
            autoresize
            compact
            filled />
          <common-button @click="onSendMessage">
            {{ $lang.landingPage_MessageModalButtonSend }}
          </common-button>
        </div>
      </template>
    </common-modal>

    <common-modal
      size="lg"
      :title="$lang.landingPage_CallModalHeader"
      :subtitle="$lang.landingPage_CallModalSubtitle"
      v-if="showCallModal"
      @close="closeCallModal()">
      <div class="call-wrapper">
        <common-input
          rows="1"
          :placeholder="$lang.landingPage_CallModalTopicPlaceholder"
          :max-height="150"
          class="auto-resize"
          v-model="prospect.topic"
          autoresize
          compact
          filled />
        <common-input
          rows="1"
          :placeholder="$lang.landingPage_CallModalWhenPlaceholder"
          :max-height="150"
          class="auto-resize"
          v-model="prospect.timeFree"
          autoresize
          compact
          filled />
      </div>

      <template #footer>
        <common-button @click="switchCallModal2">
          {{ $lang.landingPage_CallModalButtonNext }}
        </common-button>
      </template>
    </common-modal>

    <common-modal
      size="lg"
      :title="$lang.landingPage_CallModalHeader"
      :subtitle="$lang.landingPage_ContactInfoModalHeader"
      v-if="showCallModal2"
      @close="closeCallModal">
      <div class="input-wrapper">
        <div class="names">
          <common-input
            type="text"
            :placeholder="$lang.general_FirstName"
            v-model="prospect.first"
            compact
            filled />
          <common-input
            type="text"
            :placeholder="$lang.general_LastName"
            v-model="prospect.last"
            compact
            filled />
        </div>
        <common-input
          type="text"
          :placeholder="$lang.general_Email"
          v-model="prospect.email"
          compact
          filled />
        <common-phone
          v-model="prospect.pNumber"
          :placeholder="$lang.general_Phone"
          class="phone" />
      </div>

      <template #footer>
        <div class="buttons">
          <common-button @click="revertCallModal1" variant="text">
            {{ $lang.landingPage_ContactInfoModalButtonBack }}
          </common-button>
          <common-button @click="submitReqCall">
            {{ $lang.modal_Submit }}
          </common-button>
        </div>
        <p class="small-text">
          {{ $lang.landingPage_ContactInfoModalTOSPrefix }}
          <router-link class="link" :to="{ name: 'Terms of Service' }">{{
            $lang.general_TermsOfService
          }}</router-link
          >.
        </p>
      </template>
    </common-modal>

    <common-modal
      size="lg"
      :title="$lang.landingPage_MessageModalHeader.format(ambassador.first)"
      :subtitle="$lang.landingPage_ConfirmationModalHeader"
      v-if="showInfoModal"
      @close="closeInfoModal">
      <div class="input-wrapper">
        <div class="names">
          <common-input
            type="text"
            :placeholder="$lang.general_FirstName"
            v-model="prospect.first"
            compact
            filled />
          <common-input
            type="text"
            :placeholder="$lang.general_LastName"
            v-model="prospect.last"
            compact
            filled />
        </div>
        <common-input
          type="text"
          :placeholder="$lang.general_Email"
          v-model="prospect.email"
          compact
          filled />
      </div>

      <template #footer>
        <div class="buttons">
          <common-button @click="revertPendingMessage" variant="text">
            {{ $lang.landingPage_ContactInfoModalButtonBack }}
          </common-button>
          <common-button @click="submitPendingMessage">
            {{ $lang.modal_Submit }}
          </common-button>
        </div>
        <p class="small-text">
          {{ $lang.landingPage_ContactInfoModalTOSPrefix }}
          <router-link class="link" :to="{ name: 'Terms of Service' }">{{
            $lang.general_TermsOfService
          }}</router-link
          >.
        </p>
      </template>
    </common-modal>

    <common-modal
      size="lg"
      :title="$lang.landingPage_MyStoryModalHeader.format(ambassador.first)"
      hideFooter
      v-if="myStory"
      @close="myStory = null">
      <div class="my-story" v-for="(qa, i) in myStory" :key="i">
        <h4>{{ qa.q }}</h4>
        <p>{{ qa.a }}</p>
      </div>
    </common-modal>
  </Scrollbar>
</template>

<script>
  import ProfileCard from "@/components/ProfileCard.vue";

  import {
    createCall,
    sendInstantMes,
    searchParentsFuzzy,
    getIndex,
    getAmbassadors,
    getVendors,
    sendMessage,
  } from "@/services/api.service.js";
  const api = {
    createCall,
    sendInstantMes,
    searchParentsFuzzy,
    getIndex,
    getAmbassadors,
    getVendors,
    sendMessage,
  };
  import * as Sentry from "@sentry/vue";
  import commonFuncs from "@/common/common";
  import { mapState } from "vuex";

  const SEARCH_MIN_CHARS = 2;
  const SEARCH_DEBOUNCE = 200;

  export default {
    name: "LandingPage",
    components: {
      ProfileCard,
    },

    data() {
      return {
        profiles: [],
        accentColor: null,
        ambassador: null,
        pageLoaded: false,
        fetching: false,
        profilesLoaded: false,
        vendors: [],
        showMessageModal: false,
        showCallModal: false,
        showCallModal2: false,
        messageText: null,
        messages: [],
        showInfoModal: false,
        myStory: null,
        prospect: {
          first: null,
          last: null,
          email: null,
          pNumber: null,
          topic: null,
          timeFree: null,
        },
        maxIndex: 15,
        curPage: 0,
        searchedProfiles: null,
        searching: false,
        playingVideoStory: null,
        showScrollToTopBtn: false,
        cacheExpiryHours: 1, //Hours until ambassadors are re-fetched
        searchText: "",
      };
    },

    computed: {
      displayedProfiles() {
        return (
          this.searchedProfiles ||
          (this.profiles.length > 0 ? this.profiles : null)
        );
      },

      allProfilesFetched() {
        return this.profiles.length >= this.maxIndex;
      },

      showNiche() {
        return (
          this.$route.query.sender === "Niche" && this.vendors.includes("Niche")
        );
      },

      ...mapState([
        "token",
        "user",
        "orgName",
        "orgDisplayName",
        "profileFields",
        "canSendMessage",
        "canRequestCall",
        "landingPageLinks",
        "landingPage",
        "hasRandomizedAmbassadors",
        "ambassadorProfiles",
        "landingPageIndex",
        "landingPageCacheTime",
      ]),
    },

    mounted() {
      this.load();
    },

    methods: {
      load() {
        if (this.orgName) {
          this.getIndex();
          this.loadProfiles(true);

          this.messages.push({
            body: this.$lang.landingPage_MessageModalInitialMessage.format(
              this.orgDisplayName
            ),
          });

          api
            .getVendors(this.orgName)
            .then((response) => {
              this.vendors = response.data.vendors || [];
            })
            .catch((err) => {
              console.log(err);
            });

          if (process.env.VUE_APP_API_KEY) {
            let options = { org: this.orgName };

            if (this.showNiche) {
              options.source = "Niche";
            }

            this.$amplitude.getInstance().logEvent("HOME_PAGE_VIEW", options);
          }

          this.loadNichePixel();

          this.$nextTick(() => {
            this.pageLoaded = true;
          });
        }
      },

      onBottomReached(atBottom) {
        atBottom && this.loadProfiles();
      },

      async loadProfiles(isInitialLoad) {
        if (this.fetching || this.allProfilesFetched) {
          return;
        }

        this.fetching = true;

        try {
          let cacheExpiryTime = new Date();
          cacheExpiryTime = cacheExpiryTime.setTime(
            cacheExpiryTime.getTime() + this.cacheExpiryHours * 60 * 60 * 1000
          );

          if (
            isInitialLoad &&
            this.landingPageCacheTime &&
            this.landingPageCacheTime <= cacheExpiryTime
          ) {
            this.profiles = this.ambassadorProfiles;
            this.curPage = this.landingPageIndex;
          } else {
            let data = await api.getAmbassadors({
              organization: this.orgName,
              indexRange: ++this.curPage,
            });

            if (this.hasRandomizedAmbassadors) {
              data = commonFuncs.shuffle(data);
            }

            if (data?.length > 0) {
              for (const profile of data) {
                profile.fullName = commonFuncs.toFullName(
                  profile.first,
                  profile.last
                );
                // prevent ambassador from messaging self
                if (profile._id === this.user?._id) {
                  profile.canSendMessage = false;
                  profile.requestCall = false;
                }
                this.profiles.push(profile);
              }
            }

            this.$store.dispatch("setHomepageProfileData", {
              profiles: this.profiles,
              landingPageIndex: this.curPage,
            });
          }

          this.profilesLoaded = true;
          this.fetching = false;
          return true;
        } catch (err) {
          this.fetching = false;
          return false;
        }
      },

      getIndex() {
        api
          .getIndex(this.orgName)
          .then((response) => {
            this.maxIndex = response.data.maxIndex;
          })
          .catch(() => {
            this.maxIndex = 15;
          });
      },

      goToLink(num) {
        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude
            .getInstance()
            .logEvent("LINK_CLICKED", { org: this.orgName });
        }
        if (
          this.orgName === "Vermont Academy" ||
          this.orgName === "Pacific Ridge"
        ) {
          this.nicheLinkClicked(this.orgName, num);
        }
        return true;
      },

      showMyStory(user) {
        this.ambassador = user;
        this.myStory = user.faq;
      },

      switchCallModal(user) {
        if (!this.showCallModal) {
          this.ambassador = user;
          if (process.env.VUE_APP_API_KEY) {
            this.$amplitude
              .getInstance()
              .logEvent("LANDING_PAGE_CALL_BUTTON_CLICKEED", {
                org: this.orgName,
              });
          }
          this.nicheCallClicked();
        } else {
          this.ambassador = null;
        }
        this.showCallModal = !this.showCallModal;
      },

      switchCallModal2() {
        if (!this.prospect.topic || !this.prospect.timeFree) {
          this.$toast.error("You need to fill out every field to submit");
          return;
        }
        this.showCallModal = false;
        this.showCallModal2 = true;
      },

      revertCallModal1() {
        this.showCallModal = true;
        this.showCallModal2 = false;
      },

      revertPendingMessage() {
        this.showMessageModal = true;
        this.showInfoModal = false;
      },

      async submitReqCall() {
        if (
          !this.prospect.first ||
          !this.prospect.last ||
          !this.prospect.email ||
          !this.prospect.topic ||
          !this.prospect.timeFree
        ) {
          this.$toast.error(this.$lang.landingPage_ValidationMissingFields);
          return;
        }

        if (!commonFuncs.isEmailValid(this.prospect.email)) {
          this.$toast.error(this.$lang.landingPage_ValidationInvalidEmail);
          return;
        }

        if (!this.prospect.pNumber) {
          this.$toast.error(this.$lang.landingPage_InvalidPhoneNumber);
          return;
        }

        const reqBody = {
          first: this.prospect.first,
          last: this.prospect.last,
          email: this.prospect.email,
          pNumber: this.prospect.pNumber,
          topic: this.prospect.topic,
          timeFree: this.prospect.timeFree,
          signedUpOrg: this.orgName,
          recipientID: this.ambassador.id,
        };

        // make a request with the data and include signedupOrg
        let res;

        try {
          res = await api.createCall(reqBody);
        } catch (err) {
          console.error(err);
          this.$toast.error(this.$lang.general_ErrorMessage);
          return;
        }

        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude.getInstance().setUserId(res.data.id);
          let identify = new this.$amplitude.Identify()
            .set("org", this.orgName)
            .set("currParent", false);
          this.$amplitude.getInstance().identify(identify);
          this.$amplitude
            .getInstance()
            .logEvent("LANDING_PAGE_REQ_CAll_SUBMITTED");
        }

        this.showCallModal = false;
        this.showCallModal2 = false;

        this.$toast.success(
          this.$lang.landingPage_ConfirmationModalMessage.format(
            this.ambassador.first
          )
        );

        this.ambassador = null;
        this.prospect = {
          first: null,
          last: null,
          email: null,
          pNumber: null,
          timeFree: null,
          topic: null,
        };
      },

      closeCallModal() {
        let result = confirm(this.$lang.landingPage_CallModalClose);

        if (!result) {
          return;
        }
        this.showCallModal = false;
        this.showCallModal2 = false;
        this.ambassador = null;
        this.prospect = {
          first: null,
          last: null,
          email: null,
          pNumber: null,
          timeFree: null,
          topic: null,
        };
      },

      switchMessageModal(user) {
        if (!this.showMessageModal) {
          this.ambassador = user;
          if (process.env.VUE_APP_API_KEY) {
            this.$amplitude
              .getInstance()
              .logEvent("HOME_PAGE_MESSAGE_CLICKED", {
                org: this.orgName,
              });
          }
          this.nicheMessageClicked();
        } else {
          this.ambassador = null;
          this.messages = [
            {
              body: this.$lang.landingPage_MessageModalInitialMessage.format(
                this.orgDisplayName
              ),
            },
          ];
          this.messageText = null;
          document.body.classList.remove("modal-open");
        }
        this.showMessageModal = !this.showMessageModal;
      },

      onSendMessage() {
        if (this.messageText) {
          this.messages.push({ body: this.messageText });
          const composedMessage = this.messageText;
          this.messageText = null;

          setTimeout(() => {
            if (process.env.VUE_APP_API_KEY) {
              this.$amplitude.getInstance().logEvent("HOME_PAGE_MESSAGE_SENT", {
                org: this.orgName,
              });
            }
            this.showMessageModal = false;

            if (!this.token) {
              this.showInfoModal = true;
            } else {
              api
                .sendMessage(this.ambassador.id, composedMessage)
                .then((response) => {
                  if (response.status === 200) {
                    this.onFinalizeMessage(response.data.uid);
                    this.$router.push({
                      name: "Messaging",
                      query: { id: response.data.conversationID },
                    });
                  } else {
                    this.$toast.error(this.$lang.general_ErrorMessage);
                  }
                })
                .catch((err) => {
                  if (err.response.data.messageKey) {
                    this.$toast.error(this.$lang[err.response.data.messageKey]);
                  } else {
                    this.$toast.error(err);
                  }
                });
            }
          }, 500);
        } else {
          this.$toast.error(this.$lang.landingPage_ValidationMissingFields);
        }
      },

      closeInfoModal() {
        let result = confirm(
          `Are you sure you want to exit? Your messages won't send until you enter your information.`
        );

        if (!result) {
          return;
        }
        this.showInfoModal = false;
        this.ambassador = null;
        this.messages = [
          {
            body: this.$lang.landingPage_MessageModalInitialMessage.format(
              this.orgDisplayName
            ),
          },
        ];
        this.messageText = null;
        this.prospect = { first: null, last: null, email: null };
      },

      submitPendingMessage() {
        if (
          !this.prospect.first ||
          !this.prospect.last ||
          !this.prospect.email
        ) {
          this.$toast.error(this.$lang.landingPage_ValidationMissingFields);
          return;
        }

        if (!commonFuncs.isEmailValid(this.prospect.email)) {
          this.$toast.error(this.$lang.landingPage_ValidationInvalidEmail);
          return;
        }
        let data = {
          recipientID: this.ambassador.id,
          first: this.prospect.first,
          last: this.prospect.last,
          email: this.prospect.email,
          composedMessage: this.messages[1].body,
          signedUpOrg: this.orgName,
        };

        if (this.showNiche) {
          data.source = "Niche";
        }

        api
          .sendInstantMes(data)
          .then((response) => {
            if (process.env.VUE_APP_API_KEY) {
              this.$amplitude.getInstance().setUserId(response.data.id);
              let identify = new this.$amplitude.Identify()
                .set("org", this.orgName)
                .set("currParent", false);
              this.$amplitude.getInstance().identify(identify);
              this.$amplitude.getInstance().logEvent("HOME_PAGE_SIGNUP");
            }

            this.onFinalizeMessage(response.data.id);
          })
          .catch((err) => {
            if (err.response) {
              switch (err.response.status) {
                case 403:
                  this.$toast.error(
                    this.$lang.landingPage_ProspectConversationLimit
                  );
                  this.showInfoModal = false;
                  break;
                case 409:
                  this.$toast.error(
                    this.$lang.landingPage_AmbassadorConversationLimit
                  );
                  this.showInfoModal = false;
                  this.ambassador.sendMessage = false;
                  break;
                case 434:
                  this.$toast.error(
                    this.$lang.landingPage_ValidationEmailInUse
                  );
                  break;
                default:
                  this.$toast.error(this.$lang.general_ErrorMessage);
                  Sentry.captureException(err);
              }
              return;
            }
            this.$toast.error(this.$lang.general_ErrorMessage);
          });
      },

      onFinalizeMessage() {
        if (process.env.VUE_APP_API_KEY) {
          let identify = new this.$amplitude.Identify()
            .set("org", this.orgName)
            .set("currParent", false);
          this.$amplitude.getInstance().identify(identify);
          this.$amplitude.getInstance().logEvent("CONVO_STARTED");
          this.$amplitude.getInstance().logEvent("MESSAGE_SENT");
        }

        this.showMessageModal = false;
        this.showInfoModal = false;
        this.messages = [
          {
            body: this.$lang.landingPage_MessageModalInitialMessage.format(
              this.orgDisplayName
            ),
          },
        ];

        this.messageText = null;

        this.$toast.success(
          this.$lang.landingPage_MessageModalConfirmation.format(
            this.ambassador.first
          )
        );
        this.ambassador = null;
      },

      nicheLinkClicked(org, num) {
        if (org === "Vermont Academy" && num === 0) {
          let evp = document.createElement("img");
          evp.height = 1;
          evp.width = 1;
          evp.style.display = "none";
          const ordnumber =
            new Date().getTime() + parseInt(Math.random() * 10000000000000, 10);
          evp.src =
            "trkn.us/pixel/conv/ppt=8709;g=vermont_academy_clicked_tour;gid=28794;ord=" +
            ordnumber +
            ";v=120";
          document.body.appendChild(evp);
        } else if (org === "Vermont Academy" && num === 1) {
          let evp = document.createElement("img");
          evp.height = 1;
          evp.width = 1;
          evp.style.display = "none";
          const ordnumber =
            new Date().getTime() + parseInt(Math.random() * 10000000000000, 10);
          evp.src =
            "trkn.us/pixel/conv/ppt=8709;g=vermont_academy_clicked_apply;gid=28795;ord=" +
            ordnumber +
            ";v=120";
          document.body.appendChild(evp);
        } else if (org === "Pacific Ridge" && num === 0) {
          let evp = document.createElement("img");
          evp.height = 1;
          evp.width = 1;
          evp.style.display = "none";
          const ordnumber =
            new Date().getTime() + parseInt(Math.random() * 10000000000000, 10);
          evp.src =
            "trkn.us/pixel/conv/ppt=8707;g=pacific_ridge_school_clicked_tour;gid=28789;ord=" +
            ordnumber +
            ";v=120";
          document.body.appendChild(evp);
        } else if (org === "Pacific Ridge" && num === 1) {
          let evp = document.createElement("img");
          evp.height = 1;
          evp.width = 1;
          evp.style.display = "none";
          const ordnumber =
            new Date().getTime() + parseInt(Math.random() * 10000000000000, 10);
          evp.src =
            "trkn.us/pixel/conv/ppt=8707;g=pacific_ridge_school_clicked_apply;gid=28790;ord=" +
            ordnumber +
            ";v=120";
          document.body.appendChild(evp);
        }
      },

      nicheMessageClicked() {
        if (this.orgName === "Vermont Academy") {
          let evp = document.createElement("img");
          evp.height = 1;
          evp.width = 1;
          evp.style.display = "none";
          const ordnumber =
            new Date().getTime() + parseInt(Math.random() * 10000000000000, 10);
          evp.src =
            "trkn.us/pixel/conv/ppt=8709;g=vermont_academy_clicked_message;gid=28796;ord=" +
            ordnumber +
            ";v=120";
          document.body.appendChild(evp);
        } else if (this.orgName === "Pacific Ridge") {
          let evp = document.createElement("img");
          evp.height = 1;
          evp.width = 1;
          evp.style.display = "none";
          const ordnumber =
            new Date().getTime() + parseInt(Math.random() * 10000000000000, 10);
          evp.src =
            "trkn.us/pixel/conv/ppt=8707;g=pacific_ridge_school_clicked_message;gid=28791;ord=" +
            ordnumber +
            ";v=120";
          document.body.appendChild(evp);
        }
      },

      nicheCallClicked() {
        if (this.orgName === "Vermont Academy") {
          let evp = document.createElement("img");
          evp.height = 1;
          evp.width = 1;
          evp.style.display = "none";
          const ordnumber =
            new Date().getTime() + parseInt(Math.random() * 10000000000000, 10);
          evp.src =
            "trkn.us/pixel/conv/ppt=8709;g=vermont_academy_clicked_request_a_call;gid=28797;ord=" +
            ordnumber +
            ";v=120";
          document.body.appendChild(evp);
        } else if (this.orgName === "Pacific Ridge") {
          let evp = document.createElement("img");
          evp.height = 1;
          evp.width = 1;
          evp.style.display = "none";
          const ordnumber =
            new Date().getTime() + parseInt(Math.random() * 10000000000000, 10);
          evp.src =
            "trkn.us/pixel/conv/ppt=8707;g=pacific_ridge_school_clicked_request_a_call;gid=28792;ord=" +
            ordnumber +
            ";v=120";
          document.body.appendChild(evp);
        }
      },

      loadNichePixel() {
        if (this.orgName === "Vermont Academy") {
          let evp = document.createElement("img");
          evp.height = 1;
          evp.width = 1;
          evp.style.display = "none";
          const ordnumber =
            new Date().getTime() + parseInt(Math.random() * 10000000000000, 10);
          evp.src =
            "trkn.us/pixel/conv/ppt=8709;g=vermont_academy_engagement;gid=28793;ord=" +
            ordnumber +
            ";v=120";
          document.body.appendChild(evp);
        } else if (this.orgName === "Pacific Ridge") {
          let evp = document.createElement("img");
          evp.height = 1;
          evp.width = 1;
          evp.style.display = "none";
          const ordnumber =
            new Date().getTime() + parseInt(Math.random() * 10000000000000, 10);
          evp.src =
            "trkn.us/pixel/conv/ppt=8707;g=pacific_ridge_school_engagement;gid=28783;ord=" +
            ordnumber +
            ";v=120";
          document.body.appendChild(evp);
        }
      },

      onSearchInput(searchText) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(
          () => this.search(searchText),
          SEARCH_DEBOUNCE
        );
      },

      search(searchText) {
        searchText = searchText.toLowerCase().trim();

        if (!searchText || searchText.length < SEARCH_MIN_CHARS) {
          this.searchedProfiles = null;
          this.searching = false;
          return; // clear search results
        }

        // search locally if we have all profiles
        if (this.allProfilesFetched) {
          this.searchedProfiles = this.profiles.filter((user) => {
            if (user.fullName.toLowerCase().includes(searchText)) {
              return true;
            }

            for (const val of Object.values(user.profile)) {
              if (val?.toLowerCase().includes(searchText)) {
                return true;
              }
            }
          });
          return;
        }

        this.searching = true;

        api
          .searchParentsFuzzy(this.orgName, searchText, this.user?._id)
          .then((response) => {
            const { users } = response.data;
            this.searchedProfiles = users;
            this.searching = false;
          })
          .catch((err) => {
            console.error(`-performSearch`, err);
            this.searchedProfiles = null;
            this.searching = false;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .landing-page {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .contents {
    flex: 1;
  }
  a.page:hover {
    background-color: #d3d3d3;
  }
  a.page:link,
  a.page:visited {
    text-decoration: none;
    margin: 5px;
    padding: 5px 7.5px;
    border-radius: 10px;
    color: #2c3e50;
    cursor: pointer;
    font-size: 1.15em;
    font-weight: 500;
  }
  a.current-page,
  a.current-page:visited,
  a.current-page:hover {
    background-color: var(--accent-color);
    color: white;
    font-weight: 700;
  }
  #text {
    padding: 2rem 2rem 1.5rem;
  }
  #text h1 {
    font-size: 36px;
    font-weight: 700;
    margin: 0;
    padding-bottom: 0.5em;
  }
  #text span {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    width: 80%;
    margin: auto;
    padding-bottom: 15px;
  }
  .card-holder {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 376px));
    gap: 30px;
    justify-content: center;
    padding-bottom: 60px;
  }
  footer {
    margin-top: auto;
    padding: 10px 20px;
    background-color: var(--school-color);
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.3);
  }
  footer p {
    font-weight: 500;
    color: var(--text-color);
  }
  #footer-link:link,
  #footer-link:visited {
    color: var(--text-color);
  }
  .links {
    padding-bottom: 1.5em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px 24px;
  }
  .link {
    color: var(--accent-color);
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
  }
  .div-title {
    width: 100%;
    display: grid;
    grid-template-columns: auto 20px;
    grid-template-rows: 100%;
  }
  #chat-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 200px;
  }
  #inputBox {
    margin: 0;
    display: flex;
    align-items: flex-end;
  }
  .phone {
    margin-top: 6px;
  }
  #inputBox button {
    margin-left: 0.8rem;
  }
  .message-container {
    margin-bottom: 6px;
  }
  .my-message {
    background-color: var(--accent-color);
    float: right;
    clear: both;
    color: white;
    text-align: left;
  }
  .their-message {
    background-color: #e6e6e6;
    float: left;
    padding-right: 45px;
  }
  .my-message,
  .their-message {
    border-radius: 22px;
    padding: 8px 12px;
    display: flex;
    white-space: pre-wrap;
    clear: both;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
  }
  .call-wrapper {
    margin-top: 18px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .input-wrapper {
    margin-top: 18px;
  }
  .names {
    display: flex;
    gap: 12px;

    & > div {
      flex-grow: 1;
    }
  }
  .auto-resize {
    width: 100%;
  }
  .small-text {
    font-size: 12px;
    margin: 1em 0 0;
  }
  .my-story {
    text-align: left;
    padding-bottom: 0.5em;
  }
  .my-story h4,
  .my-story p {
    margin: 0 0 0.5em;
  }
  .landing-page-no-profile-text {
    font-weight: 400;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .search-bar {
    max-width: 772px;
    margin: 0 auto 3.75rem auto;

    &:focus-within {
      outline: 0.25rem solid rgba(var(--accent-color-rgb), 0.3);
    }
  }

  ::v-deep.search-bar.search-container {
    background-color: $white;
  }

  @media (max-width: $xs) {
    #text {
      padding: 1rem;
    }
    #text h1 {
      font-size: 28px;
    }
    #text span {
      font-size: 1rem;
    }
  }
</style>
