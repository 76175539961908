<template>
  <common-modal
    size="lg"
    :title="this.$lang.modalInvitesCall_TitleMain.format(this.fullName)"
    @close="$emit('close')">
    <div v-if="currentPage === pages.info">
      <common-input
        v-model="phoneNumber"
        :label="$lang.modalInvitesCall_PhoneNumber"
        readonly />
      <common-input
        v-if="outreach.notes"
        v-model="outreach.notes"
        :label="this.$lang.modalInvitesCall_Notes.format(firstName)"
        autoresize
        readonly />
      <common-input
        v-if="outreach.timeFree"
        v-model="outreach.timeFree"
        :label="this.$lang.modalInvitesCall_TimeFree"
        autoresize
        readonly />
      <common-input
        v-if="outreach.topic"
        v-model="outreach.topic"
        :label="this.$lang.modalInvitesCall_Topic.format(firstName)"
        autoresize
        readonly />
    </div>
    <div v-if="currentPage === pages.feedback">
      <h3 style="color: black" class="mt-20 mb-0">
        {{ $lang.modalInvitesCall_CallFeedbackTitle.format(firstName) }}
      </h3>
      <div
        v-for="(question, index) in visibleFeedbackQuestions"
        :key="question._id">
        <h4>{{ question.text }}</h4>
        <template v-if="question.type === 'slider'">
          <input
            type="range"
            min="1"
            max="10"
            v-model.number="answers[index]"
            class="slider" />
          <h4>{{ answers[index] }}</h4>
        </template>
        <common-input v-else v-model="answers[index]" autoresize />
      </div>
    </div>
    <div v-if="currentPage === pages.message">
      <h3 style="color: black" class="mt-20 mb-0">
        {{ $lang.modalInvitesCall_MessageTitle }}
      </h3>
      <common-input
        v-model="message"
        :label="$lang.modalInvitesCall_MessageLabel.format(firstName)"
        autoresize />
    </div>
    <template #buttons>
      <div v-if="currentPage === pages.info" class="invites-call-buttons">
        <button class="btn" @click="currentPage = pages.feedback">
          {{ $lang.modalInvitesCall_CallFeedbackButton }}
        </button>
        <button class="btn" @click="currentPage = pages.message">
          {{ $lang.modalInvitesCall_MessageButton }}
        </button>
      </div>
      <div v-if="currentPage === pages.feedback" class="invites-call-buttons">
        <button @click="currentPage = pages.info">
          {{ $lang.general_Back }}
        </button>
        <button class="btn" @click="submitFeedback">
          {{ $lang.modalInvitesCall_CallFeedbackButton }}
        </button>
      </div>
      <div v-if="currentPage === pages.message" class="invites-call-buttons">
        <button @click="currentPage = pages.info">
          {{ $lang.general_Back }}
        </button>
        <button class="btn" @click="sendMessage">
          {{ $lang.modalInvitesCall_MessageButton }}
        </button>
      </div>
    </template>
  </common-modal>
</template>

<script>
  import {
    sendMessageProspectCallInvite,
    sendMessageProspectCallRequest,
    getConversationFeedbackQuestions,
    addFeedback,
  } from "@/services/api.service.js";
  const api = {
    sendMessageProspectCallInvite,
    sendMessageProspectCallRequest,
    getConversationFeedbackQuestions,
    addFeedback,
  };
  export default {
    name: "ModalInvitesCall",
    props: {
      outreach: {
        type: Object,
        required: true,
      },
      orgDisplayName: {
        type: String,
        required: true,
      },
      organizationId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        answers: [],
        feedbackQuestions: [],
        message: "",
        currentPage: "info",
        pages: { info: "info", feedback: "feedback", message: "message" },
      };
    },
    computed: {
      fullName() {
        return this.outreach.invitee.name;
      },
      firstName() {
        return this.outreach.invitee.first;
      },
      phoneNumber() {
        return this.outreach.invitee.number || this.outreach.phone;
      },
      visibleFeedbackQuestions() {
        return this.feedbackQuestions.filter((question) => question.isVisible);
      },
    },
    async created() {
      this.feedbackQuestions = (
        await api.getConversationFeedbackQuestions(this.organizationId)
      ).data.conversationFeedbackQuestionsAmbassador;
    },
    watch: {
      visibleFeedbackQuestions: {
        handler(newValue) {
          this.answers = newValue.map((question) =>
            question.type === "text" ? "" : 5
          );
        },
        immediate: true,
      },
    },
    methods: {
      async sendMessage() {
        try {
          const sendMessage =
            this.outreach.type === "call"
              ? api.sendMessageProspectCallRequest
              : api.sendMessageProspectCallInvite;

          await sendMessage(this.outreach._id, this.message);
          this.$toast.success(`Your message to ${this.firstName} was sent!`);
          this.message = "";
          this.$emit("close");
        } catch (err) {
          this.$toast.error(`We were unable to send your message!`);
        }
      },
      async submitFeedback() {
        if (this.answers.some((answer) => !answer)) {
          this.$toast.error("You need to fill out every field to submit");
          return;
        }

        const feedback = this.answers.map((text, index) => ({
          questionId: this.visibleFeedbackQuestions[index].id,
          text,
        }));

        try {
          const type =
            this.outreach.type === "call invite" ? "callInvitation" : "call";
          await api.addFeedback(this.outreach._id, type, feedback);
          this.$emit("feedback");
          this.$toast.success(
            `Feedback submitted! Thank you for calling ${this.fullName}`
          );
        } catch (err) {
          this.$toast.error("Feedback submission failed, please try again!");
          return;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .invites-call-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
</style>
