/**
 * Combines Material Design icons with custom icons
 *
 * Included icons that don't need to be added:
 *   complete, cancel, close, delete, clear, success, info, warning, error,
 *   prev, next, checkboxOn, checkboxOff, checkboxIndeterminate, delimiter,
 *   sort, expand, menu, subgroup, dropdown, radioOn, radioOff, edit,
 *   ratingEmpty, ratingFull, ratingHalf, loading, first, last, unfold, file,
 *   plus, minus
 */

import icons from "../assets/icons/_icons.json";

// add material design icons you want to use here and ...
import {
  mdiAccount,
  mdiAlert,
  mdiArrowLeft,
  mdiBell,
  mdiBlockHelper,
  mdiCamera,
  mdiCameraFlip,
  mdiCheck,
  mdiCheckCircle,
  mdiChevronRight,
  mdiChevronUp,
  mdiCircle,
  mdiClose,
  mdiCloseCircle,
  mdiContentSave,
  mdiDelete,
  mdiDotsVertical,
  mdiFlagVariant,
  mdiHome,
  mdiLogout,
  mdiMagnify,
  mdiMenu,
  mdiMenuDown,
  mdiMenuUp,
  mdiMessageAlert,
  mdiMessageText,
  mdiPause,
  mdiPencil,
  mdiRestart,
  mdiSend,
  mdiStop,
  mdiUpload,
} from "@mdi/js";

// ... add them here too
const mdIcons = {
  mdiAccount,
  mdiAlert,
  mdiArrowLeft,
  mdiBell,
  mdiBlockHelper,
  mdiCamera,
  mdiCameraFlip,
  mdiCheck,
  mdiCheckCircle,
  mdiChevronRight,
  mdiChevronUp,
  mdiCircle,
  mdiClose,
  mdiCloseCircle,
  mdiContentSave,
  mdiDelete,
  mdiDotsVertical,
  mdiFlagVariant,
  mdiHome,
  mdiLogout,
  mdiMagnify,
  mdiMenu,
  mdiMenuDown,
  mdiMenuUp,
  mdiMessageAlert,
  mdiMessageText,
  mdiPause,
  mdiPencil,
  mdiRestart,
  mdiSend,
  mdiStop,
  mdiUpload,
};

for (const icon in mdIcons) {
  icons[toKebabCase(icon.slice(3))] = mdIcons[icon];
}

function toKebabCase(str) {
  return str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, offset) => (offset ? "-" : "") + $.toLowerCase()
  );
}

export default icons;
