<template>
  <div class="header-wrapper">
    <header :class="{ prospect: showProspectPortal }">
      <div class="container">
        <div class="logo" v-if="showProspectPortal">
          <img src="../../assets/prospect-portal.svg" alt />
        </div>
        <div class="logo" v-else>
          <a v-if="backLink" :href="backLink">
            <img :src="orgLogoUrl" alt="School logo" />
          </a>
          <router-link v-else :to="homeRoute" exact>
            <img :src="orgLogoUrl" alt="School logo" />
          </router-link>
          <template v-if="showNiche">
            <div
              :style="{ 'background-color': nicheLineColor }"
              class="sep"></div>
            <img
              v-if="nicheLineColor === '#ffffff'"
              src="../../assets/NicheWhite.png"
              alt />
            <img v-else src="../../assets/NicheColor.png" alt />
          </template>
        </div>

        <div class="nav-toggle" @click="toggleNav">
          <ppicon name="menu" />
        </div>

        <div
          class="nav-overlay"
          :class="{ hidden: !showMobileNav, removed: removeMobileNav }"
          @click.self="toggleNav">
          <nav>
            <template v-if="user">
              <ul @click="toggleNav">
                <li class="nav-item">
                  <router-link :to="{ name: 'Home' }" exact>
                    <ppicon name="home" class="nav-icon" />
                    {{ $lang.navigation_Home }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'Messaging' }">
                    <div class="nav-icon-badge">
                      <ppicon name="message-text" class="nav-icon" />
                      <span class="badge" v-if="unreadMessageCount">
                        {{ unreadMessageCount }}
                      </span>
                    </div>
                    {{ $lang.navigation_Messages }}
                  </router-link>
                </li>
                <li class="nav-item" v-if="user.organization">
                  <router-link :to="{ name: 'Invites' }">
                    <div class="nav-icon-badge">
                      <ppicon name="p-outreach" class="nav-icon" />
                      <span class="badge" v-if="outreachCount">
                        {{ outreachCount }}
                      </span>
                    </div>
                    {{ $lang.navigation_Outreach }}
                  </router-link>
                </li>
              </ul>
              <common-dropdown
                :items="userMenu"
                @menu-click="menuClick"
                @toggle="menuShown = $event"
                class="user">
                <button class="user-btn">
                  <img class="user-pic" :src="profileImageUrl" />
                  <div class="user-name-icon">
                    <span class="user-name">
                      {{ user.first }} {{ user.last }}
                    </span>
                    <ppicon v-if="menuShown" name="menu-up" />
                    <ppicon v-else name="menu-down" />
                  </div>
                </button>
              </common-dropdown>
            </template>

            <template v-else>
              <ul @click="toggleNav">
                <li class="nav-item">
                  <router-link :to="{ name: 'Home' }" exact>
                    <ppicon name="home" class="nav-icon" />
                    {{ $lang.navigation_Home }}
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link :to="loginRoute">
                    <ppicon name="account" class="nav-icon" />
                    {{ $lang.general_SignIn }}
                  </router-link>
                </li>
              </ul>
            </template>

            <div class="nav-footer">
              <ul @click="toggleNav">
                <li>
                  <div @click="$emit('tutorial')">Tutorial</div>
                </li>
                <li>
                  <router-link :to="{ name: 'Terms of Service' }">
                    Terms of Service
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'FAQs' }">Help</router-link>
                </li>
                <li>
                  <div @click="$emit('report-problem')">Report a Problem</div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>

    <NotificationSettingsModal
      v-if="showNotificationSettingsModal"
      @close="showNotificationSettingsModal = false"
      @change="profileChanged"
      :user="user">
    </NotificationSettingsModal>
  </div>
</template>

<script>
  import NotificationSettingsModal from "../NotificationSettingsModal.vue";
  import commonFuncs from "@/common/common";
  import { mapState } from "vuex";

  export default {
    name: "Header",
    components: { NotificationSettingsModal },
    data() {
      return {
        showMobileNav: false,
        removeMobileNav: true,
        showNotificationSettingsModal: false,
        menuShown: false,

        userMenu: [
          {
            label: this.$lang.userMenu_NotificationSettings,
            icon: "bell",
            action: "notification-settings",
          },
          {
            label: this.$lang.userMenu_EditProfile,
            icon: "pencil",
            to: { name: "ProfileEdit" },
          },
          {
            label: this.$lang.userMenu_SignOut,
            icon: "logout",
            action: "logout",
          },
        ],
      };
    },

    computed: {
      homeRoute() {
        const route = { name: "Home" };

        if (this.showNiche) {
          route.query = { sender: "Niche" };
        }
        return route;
      },

      loginRoute() {
        const route = { name: "Login" };

        if (this.showNiche) {
          route.query = { sender: "Niche" };
        }
        return route;
      },

      orgLogoUrl() {
        return commonFuncs.optimizeOrgLogoUrl(this.logoUrl);
      },

      profileImageUrl() {
        return (
          commonFuncs.optimizeProfileImageUrl(this.user.profileImageUrl) ||
          require("@/assets/profile.svg")
        );
      },

      unreadMessageCount() {
        const count = this.user?.unreadMessageCount || 0;
        return count > 99 ? "!" : count;
      },

      outreachCount() {
        const count = this.user?.outreachCount || 0;
        return count > 99 ? "!" : count;
      },

      showNiche() {
        return (
          this.$route.query.sender === "Niche" && this.vendors.includes("Niche")
        );
      },

      nicheLineColor() {
        let bodyStyle = document.body.style;
        return bodyStyle.getPropertyValue("--text-color");
      },

      showProspectPortal() {
        return this.user?.signedUpOrg && this.$route.name !== "Home";
      },

      ...mapState([
        "user",
        "orgName",
        "backLink",
        "logoUrl",
        "profileFields",
        "token",
      ]),
    },

    methods: {
      menuClick(item) {
        this.menuShown = false;

        switch (item.action) {
          case "notification-settings":
            this.showNotificationSettingsModal = true;
            break;
          case "profile":
            if (this.$route.name !== "ProfileEdit") {
              this.$router.push({ name: "ProfileEdit" });
            }
            break;
          case "logout":
            this.logout();
            break;
        }
      },

      toggleNav() {
        if (this.removeMobileNav) {
          this.removeMobileNav = false;
        } else {
          setTimeout(() => {
            this.removeMobileNav = true;
          }, 300);
        }

        this.showMobileNav = !this.showMobileNav;
      },

      profileChanged(user) {
        this.$store.dispatch("setUser", { ...this.user, ...user });
      },

      logout() {
        this.$store.dispatch("logout");

        if (this.$route.name !== "Home") {
          this.$router.push({ name: "Home" });
        }
      },

      /* Video Story */

      /**
       * Convenient wrapper for $toasted banners with proper default values for all optional params.
       */
      showUserStoryToast(
        message,
        isError = false,
        duration = 4000,
        position = "bottom-left",
        theme = "outline",
        className = "user-story-banner"
      ) {
        let containerClass = "user-story-banner-success-container";
        if (isError) {
          containerClass = "user-story-banner-error-container";
        }

        this.$toasted.show(message, {
          theme,
          position,
          duration,
          className,
          containerClass,
        });
      },

      printUserStoryLog(message, object = undefined) {
        if (object) {
          console.log(`{PeerPal} [UserStory] ${message}`);
        } else {
          console.log(`{PeerPal} [UserStory] ${message}`, object);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  header {
    color: var(--text-color);
    box-shadow: $box-shadow-lg;
    z-index: 100;
    background-color: var(--school-color);
    position: relative;
    transition: background-color var(--transition-duration);
  }
  .logo img {
    margin: 24px;
    height: 65px;
    max-width: 250px;
    object-fit: contain;
    display: block;
  }
  .logo a {
    cursor: pointer;
  }
  header .container {
    display: flex;
    justify-content: space-between;
  }
  .logo {
    display: flex;
  }
  .sep {
    width: 2px;
    height: 85px;
    margin: 12px 0;
  }
  .header-link {
    padding: 0 20px;
    font-weight: 700;
    font-size: 22px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .header-link a,
  .header-link a:visited {
    color: var(--text-color);
    text-decoration: none;
  }
  .header-link:hover {
    color: var(--text-color);
    opacity: 0.9;
  }
  .nav-overlay {
    display: flex;
    align-items: center;
  }
  nav {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 52px;
    font-size: 12px;
  }
  nav ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 52px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .nav-toggle {
    display: none;
    cursor: pointer;
  }
  .nav-item a {
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
  }
  .nav-icon-badge {
    position: relative;
    width: fit-content;
    align-self: center;
  }
  .nav-icon {
    align-self: center;
    color: var(--text-color);
    padding: 8px;
    margin: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color var(--transition-duration),
      background-color var(--transition-duration);
  }
  .nav-item:visited {
    color: inherit;
  }
  .nav-item a:not(.router-link-active):hover {
    opacity: 0.9;
  }
  a.router-link-active {
    cursor: auto;
  }
  .router-link-active .nav-icon {
    color: var(--school-color);
    background: var(--text-color);
  }
  .user-btn {
    color: var(--text-color);
    display: flex;
    align-items: center;
    gap: 2px;
  }
  .user-pic {
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    object-fit: cover;
  }
  .user-name {
    display: none;
  }
  .nav-footer {
    display: none;
  }
  .badge {
    --size: 16px;
    font-size: 10px;
    font-weight: 700;
    position: absolute;
    top: 1px;
    right: 1px;
    width: var(--size);
    height: var(--size);
    line-height: var(--size);
    text-align: center;
    color: $white;
    background: $red;
    border-radius: 50%;
  }

  // Prospect Portal
  header.prospect {
    color: $white;
    background-color: $blue-400;

    .nav-icon {
      color: $white;
    }

    .router-link-active .nav-icon,
    .router-link-active:hover .nav-icon {
      color: $blue-400;
      background-color: $white;
    }
  }

  @media (max-width: $sm) {
    .header-wrapper {
      height: 88px;
    }
    header {
      position: fixed;
      width: 100%;
    }
    header .container {
      justify-content: center;
    }
    .logo img {
      height: 50px;
      margin: 19px;
    }
    .sep {
      height: 50px;
      margin: 10px 0;
    }
    .header-link {
      box-sizing: border-box;
      width: 100%;
      padding: 0;
    }
    .header-link a,
    .header-link a:visited {
      font-size: 18px;
      color: $primary-text;
      width: 100%;
      padding: 20px;
      text-align: left;
    }
    .nav-overlay {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100vw;
      background: $overlay-bg;
      opacity: 1;
      transition: opacity var(--transition-duration),
        transform var(--transition-duration);
    }

    .nav-overlay.hidden {
      opacity: 0;

      &.removed {
        width: 0;
      }

      nav {
        transform: translateX(-100%);
      }
    }
    nav {
      display: grid;
      grid-template-rows: auto auto 1fr;
      grid-template-columns: auto;
      align-items: flex-start;
      gap: 0;
      width: 268px;
      height: 100vh;
      background: $white;
      color: $primary-text;
      transition: transform var(--transition-duration);
    }
    nav ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      border-top: 1px solid $border-grey;
    }
    .nav-toggle {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      margin: 1rem;
      height: 50px;
      display: flex;
      align-items: center;
    }
    .nav-item {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }
    .nav-item a {
      flex-direction: row;
      align-items: center;
      justify-content: start;
      width: 100%;
      padding: 0;
    }
    .nav-icon,
    .prospect .nav-overlay .nav-icon {
      color: $primary-text;
    }
    .router-link-active {
      cursor: normal;
      background-color: $row-hover-background;
    }
    .router-link-active .nav-icon,
    .router-link-active:hover .nav-icon {
      color: unset;
      background: unset;
    }
    .user {
      grid-row: 1;
    }
    .user-btn {
      color: $primary-text;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 1rem 1rem 0 1rem;
    }
    .user-name-icon {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0.5rem 0;
    }
    .user-name {
      display: block;
      color: $primary-text;
    }
    .nav-footer {
      display: block;
    }
    .nav-footer ul {
      list-style: none;
      margin: 0;
      padding: 15px;
    }
    .nav-footer li {
      display: flex;
    }
    .nav-footer a,
    .nav-footer div {
      color: $grey-500;
      text-decoration: none;
      padding: 0.5rem 0;
    }

    ::v-deep.user .menu {
      width: 268px;
      height: 100vh;
      border-top: 1px solid $border-grey;
    }
  }
</style>
