<template>
  <common-modal
    size="lg"
    :title="$lang.chat_ProvideFeedbackTitle"
    @close="$emit('close')"
    @submit="sendFeedback">
    <template v-for="(question, index) in questions">
      <div :key="question.text">
        <h3>{{ question.text }}</h3>
        <div class="slide-container" v-if="question.type === 'slider'">
          <input
            type="range"
            min="1"
            max="10"
            v-model.number="answers[index]"
            class="slider" />
          <h4>{{ answers[index] }}</h4>
        </div>

        <div class="text-area-container" v-else>
          <textarea
            type="text"
            :placeholder="$lang.chat_ProvideFeedbackPlaceholder"
            v-model="answers[index]"></textarea>
        </div>
      </div>
    </template>
  </common-modal>
</template>

<script>
  import {
    addFeedback,
    getConversationFeedbackQuestions,
  } from "@/services/api.service.js";
  const api = { addFeedback, getConversationFeedbackQuestions };
  export default {
    props: {
      questions: {
        type: Array,
        required: true,
      },
      conversationId: {
        type: String,
        required: true,
      },
      isAmbassador: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        answers: null,
      };
    },
    watch: {
      questions: {
        handler(newValue) {
          this.answers = newValue.map((question) =>
            question.type === "text" ? "" : 5
          );
        },
        immediate: true,
      },
    },
    methods: {
      sendFeedback() {
        if (this.answers.some((answer) => answer === "")) {
          this.$toast.error(this.$lang.chat_ProvideFeedbackRequired);
          return;
        }
        const feedbackAnswers = this.answers.map((answer, index) => ({
          questionId: this.questions[index].id,
          text: answer,
        }));
        api
          .addFeedback(this.conversationId, "conversation", feedbackAnswers)
          .then(() => {
            this.$emit("close");
          })
          .catch((err) => {
            console.error(err);
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .slide-container {
    display: flex;
    justify-content: center;
    h4 {
      margin: 0 0 0 10px;
    }
  }

  .text-area-container {
    textarea {
      display: block;
      width: calc(100% - 40px);
      height: 90px;
      margin: 0 20px;
      padding: 10px;
      resize: none;
      font-size: 15px;
      border-radius: 10px;
      border: 0;
      box-sizing: border-box;
      background-color: $grey-100;
      word-wrap: break-word;
      outline: none;
    }
  }
</style>
