<template>
  <div class="input-container" :class="{ compact }">
    <textarea
      v-if="rows || autoresize"
      ref="textarea"
      :class="['input', { filled, error: hasError }]"
      :value="inputValue"
      :placeholder="placeholder"
      @keyup="emitKeyUp"
      @input="emitInput"
      :disabled="disabled"
      :readonly="readonly"
      :maxlength="maxLength"
      :aria-label="label || placeholder"
      :rows="rows"></textarea>

    <input
      v-else
      class="input"
      :class="{ filled, error: hasError, 'has-icon': hasIcon }"
      :name="name"
      :value="inputValue"
      :type="type"
      :placeholder="placeholder"
      @keyup="emitKeyUp"
      @input="emitInput"
      :disabled="disabled"
      :maxlength="maxLength"
      :aria-label="label || placeholder"
      ref="input" />

    <span
      class="input-label"
      :class="{
        dirty: isDirty,
        error: hasError,
        'has-icon': hasIcon,
      }"
      aria-hidden="true">
      {{ label }}
    </span>

    <ppicon v-if="icon" class="input-icon" :name="icon" />
    <ppicon v-if="error" class="input-error-icon" name="alert-circle" />
    <ppicon
      v-else-if="message"
      class="input-message-icon"
      name="alert-circle" />

    <div v-if="!compact" class="input-validation">
      <div>
        <span v-if="error" class="error">{{ error }}</span>
        <span v-else-if="message">{{ message }}</span>
      </div>
      <div :class="['input-length', { error: hasError }]">
        <span v-if="maxLength"> {{ inputValue.length }}/{{ maxLength }} </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "common-input",

    props: {
      name: {
        type: String,
      },

      value: {
        type: [String, Number],
      },

      label: {
        type: String,
      },

      placeholder: {
        type: String,
      },

      // textarea rows
      rows: {
        type: Number,
      },

      // auto-resizing textarea
      autoresize: {
        type: Boolean,
        default: false,
      },

      icon: {
        type: String,
      },

      type: {
        type: String,
        default: "text",
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      readonly: {
        type: Boolean,
        default: false,
      },

      error: {
        type: String,
      },

      message: {
        type: String,
      },

      compact: {
        type: Boolean,
        default: false,
      },

      maxLength: {
        type: Number,
        default: null,
      },

      filled: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        inputValue: this.value,
      };
    },

    watch: {
      value(newVal) {
        this.inputValue = newVal;
      },

      inputValue() {
        this.resizeTextarea();
      },
    },

    computed: {
      isDirty() {
        return this.inputValue?.length || typeof this.inputValue === "number";
      },
      hasError() {
        return (
          this.error ||
          (this.maxLength && this.inputValue.length > this.maxLength)
        );
      },
      hasIcon() {
        return this.icon;
      },
    },

    created() {
      if (this.value === 0) this.inputValue = 0;
    },

    mounted() {
      this.resizeTextarea();
    },

    methods: {
      emitKeyUp(e) {
        const inputValue = e.target.value;
        this.$emit("keyup", inputValue);
      },

      emitInput(e) {
        this.$emit("dirty", true);
        this.$emit("input", e.target.value);
      },

      resizeTextarea() {
        if (this.autoresize && this.$refs.textarea) {
          const BORDER_SIZE = 2;
          const el = this.$refs.textarea;
          el.style.height = "auto";
          el.style.height = BORDER_SIZE + el.scrollHeight + "px";
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .input[type="number"]::-webkit-inner-spin-button,
  .input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input-container {
    position: relative;
    padding-top: 1rem;
    font-size: 14px;

    &.compact {
      padding-top: 0.5rem;
    }
  }

  .input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 10px;
    border: 1px solid $grey-400;
    border-radius: 20px;
    font-size: 14px;
    line-height: 18px;
    transition: border var(--transition-duration) ease;
    resize: none;
    background-color: $white;

    &:disabled {
      color: $grey-500;
    }
    &:focus {
      border: 1px solid var(--accent-color);
      outline: none;
    }
    &.error {
      border: 1px solid $red;
    }

    &.has-icon {
      padding-left: 2rem;
    }

    &.filled {
      border: 1px solid $grey-100;
      background-color: $grey-100;
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome */
    color: $grey-900;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $grey-900;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $grey-900;
    opacity: 1;
  }
  :-moz-placeholder {
    /* Firefox 4 - 18 */
    color: $grey-900;
    opacity: 1;
  }

  .input-label {
    position: absolute;
    top: 1.58rem;
    left: 0;
    padding: 0 0.25rem;
    margin: 0 0.5rem;
    font-size: 14px;
    line-height: 18px;
    transition: transform var(--transition-duration),
      color var(--transition-duration);
    color: var(--primary-text-color);
    background: $white;
    pointer-events: none;
    transform-origin: left;

    &.error {
      color: $red;
    }

    &.has-icon {
      left: 1.375rem;
    }
  }

  .input:focus + .input-label,
  .input-label.dirty {
    transform: scale(0.85) translateY(-18px);
    line-height: 1;
  }

  .input:focus + .input-label {
    color: var(--accent-color);

    &.error {
      color: $red;
    }
  }

  .input-validation {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 12px;
    height: 1.5em;
    color: $grey-600;

    & .error {
      color: $red;
    }
  }

  .input-length {
    color: $grey-400;
  }

  .input-icon {
    position: absolute;
    top: 1.75rem;
    left: 0.75rem;
  }

  .input-error-icon,
  .input-message-icon {
    position: absolute;
    top: 1.75rem;
    right: 0.75rem;
  }

  .input-error-icon {
    color: $red;
  }
</style>
