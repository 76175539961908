import breakpoints from "@/constants/breakpoints";
/* eslint-disable no-useless-escape */

const orgWordsToTrim = [
  "Students",
  "Alumni",
  "Staff",
  "Ambassadors",
  "Parents",
];
const orgWordsToTrimRegex = new RegExp(` (${orgWordsToTrim.join("|")})$`);

function getDisplayName(orgName) {
  if (!orgName) {
    return "";
  }

  return orgName.replace(orgWordsToTrimRegex, "");
}

function hexToRgb(hex) {
  const hexValue = hex.substring(1);
  const rgb = parseInt(hexValue, 16);

  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  return { r, g, b, value: `${r},${g},${b}` };
}

function getTextColor(hexString) {
  const { r, g, b } = hexToRgb(hexString);
  const avg = (r + g + b) / 3;

  if (avg >= 150) {
    //black
    return "#2c3e50";
  } else {
    //white
    return "#ffffff";
  }
}

function createTextLinks(text) {
  return (text || "").replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    function (match, space, url) {
      let hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return (
        space +
        '<a href="' +
        hyperlink +
        '" rel="noreferrer" style="color: white !important">' +
        url +
        "</a>"
      );
    }
  );
}

function isMobile() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check || window.innerWidth <= breakpoints.sm;
}

function isEmailValid(email) {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  return email == "" ? false : reg.test(email) ? true : false;
}

function validURL(urlTest) {
  var expression =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  if (urlTest.match(regex)) {
    return true;
  } else {
    return false;
  }
}

function toPrettyDateString(date) {
  if (!(date instanceof Date) && typeof date === "string") {
    date = new Date(date);
  } else if (!(date instanceof Date)) {
    date = new Date();
  }

  return date.toLocaleDateString(undefined, {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

function toPrettiestPrincessOfAllTimeString(date) {
  if (!(date instanceof Date) && typeof date === "string") {
    date = new Date(date);
  } else if (!(date instanceof Date)) {
    date = new Date();
  }

  return date.toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "numeric",
  });
}

export function toFullName(first, last, fallback) {
  first = first || "";
  last = last || "";
  const name = (first + " " + last).trim() || fallback;
  return name;
}

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

function transformImageUrl(url, width, height) {
  if (!url) {
    return "";
  }
  const prefix = "https://res.cloudinary.com/peerpal/image/upload/";
  const filename = url.slice(url.lastIndexOf("/"));
  return `${prefix}c_limit,w_${width},h_${height}${filename}`;
}

function optimizeOrgLogoUrl(url) {
  // scaled to 2x displayed height
  return transformImageUrl(url, 650, 130);
}

function optimizeProfileImageUrl(url) {
  // scaled to 2x displayed size
  return transformImageUrl(url, 240, 240);
}

export default {
  getDisplayName,
  getTextColor,
  createTextLinks,
  isEmailValid,
  validURL,
  hexToRgb,
  toPrettyDateString,
  toPrettiestPrincessOfAllTimeString,
  isMobile,
  toFullName,
  shuffle,
  optimizeOrgLogoUrl,
  optimizeProfileImageUrl,
};
