<template>
  <div class="body sign-up-page">
    <div v-if="!pageLoaded" class="loading">
      <Loading></Loading>
    </div>

    <div v-else-if="invalidInvite" class="loading">
      {{ $lang.inviteSignup_AmbassadorInviteNotFound.format(orgDisplayName) }}
    </div>

    <template v-else>
      <template v-if="videoInvitationVideoURL">
        <video
          playsinline
          :src="videoInvitationVideoURL"
          :poster="videoInvitationVideoPosterURL"
          controls
          class="video-invitation"
          @play="playClicked"
          @pause="pauseClicked"
          ref="videoPlayer" />
      </template>

      <transition name="fade" mode="out-in">
        <div class="card" v-if="!loginEmail">
          <h3 class="sign-up-page-card-title" v-if="cardTitleText">
            {{ cardTitleText }}
          </h3>

          <h4 class="sign-up-page-card-subtitle" v-if="message">
            "{{ message }}"
          </h4>

          <div class="signup-button email-button" @click="toggleLoginForm">
            <img src="../assets/InboxWhite.png" alt="Email Logo" />
            <label>{{ emailSignInDisplayText }}</label>
          </div>

          <GoogleLogin
            @login="continueWithGoogle"
            :label="googleSignInDisplayText">
          </GoogleLogin>

          <div
            v-if="vendors.includes('MSA') && msaSites.site1"
            class="signup-button msa-button"
            @click="goToBlackBaud">
            <img src="../assets/bbLogo.png" alt="BB Logo" />
            <label>{{ msaSignInDisplayText }}</label>
          </div>

          <div class="admin-link">
            <a
              v-if="!isSignup"
              href="https://peerpal.gravyty.com/login"
              rel="noreferrer"
              class="admin-link">
              {{ $lang.login_AdminLink }}
            </a>
          </div>

          <p class="detail-text" v-if="isSignup">
            {{ $lang.inviteSignup_TOSPrefix }}
            <router-link
              class="link"
              :to="{ name: 'Terms of Service' }"
              target="_blank"
              >{{ $lang.general_TermsOfService }}</router-link
            >.
          </p>
        </div>

        <login-signup-form
          :isSignup="isSignup"
          :title="loginFormTitle"
          :subtitle="loginFormSubtitle"
          :signUpUserInfo="signUpUserInfo"
          v-else
          @toggle-login-form="toggleLoginForm"
          @login="login"
          @register="register"
          @update-data="updateData" />
      </transition>
    </template>
  </div>
</template>

<script>
  import * as api from "../services/api.service";
  import { mapState, mapGetters } from "vuex";
  import GoogleLogin from "@/components/GoogleLogin.vue";
  import invitationTypes from "@/constants/invitations.js";
  import Loading from "./Loading.vue";
  import LoginSignupForm from "./LoginSignupForm.vue";
  import commonFuncs from "../common/common";

  export default {
    name: "Login",
    components: { GoogleLogin, Loading, LoginSignupForm },
    props: ["invite", "inviteTab"],

    data() {
      return {
        first: "",
        last: "",
        email: "",
        emailMagic: "",
        inviterName: "",
        loginEmail: false,
        vendors: [],
        msaName: null,
        msaSites: {},
        inviteId: null,
        inviteType: null,
        invalidInvite: false,
        pageLoaded: false,

        // Outreach
        message: "",
        videoInvitationVideoPosterURL: null,
        videoInvitationVideoURL: null,
        wasVideoPlayed: false,
        callId: null,
      };
    },

    computed: {
      showNiche() {
        return (
          this.$route.query.sender === "Niche" && this.vendors.includes("Niche")
        );
      },

      isSignup() {
        if (this.inviteType !== invitationTypes.VIDEO) {
          // For some reason, all invite types have accountMade except video
          // If the account is made, let them login
          return this.inviteType && !this.accountMade;
        }

        return !!this.inviteType;
      },

      cardTitleText() {
        let text = "";

        if (this.inviterName) {
          if (this.inviteType === invitationTypes.PROSPECT) {
            text = this.$lang.login_ProspectCardTitleText.format(
              this.inviterName
            );
          } else if (this.inviteType === invitationTypes.VIDEO) {
            text = this.$lang.login_RecordedVideo.format(this.inviterName);
          } else if (
            this.inviteType === invitationTypes.CALL ||
            this.inviteType === invitationTypes.EMAIL
          ) {
            text = this.$lang.login_LeftMessage.format(this.inviterName);
          }
        }

        return text;
      },

      emailSignInDisplayText() {
        return this.isSignup
          ? this.$lang.login_SignUpEmail
          : this.$lang.login_SignInEmail;
      },

      googleSignInDisplayText() {
        return this.isSignup
          ? this.$lang.login_SignUpGoogle
          : this.$lang.login_SignInGoogle;
      },

      msaSignInDisplayText() {
        return this.isSignup
          ? this.$lang.login_SignUpMSA.format(this.msaName)
          : this.$lang.login_SignInMSA.format(this.msaName);
      },

      signUpUserInfo() {
        return {
          first: this.first,
          last: this.last,
          email: this.email,
          inviterName: this.inviterName,
          inviteId: this.inviteId,
          inviteType: this.inviteType,
        };
      },

      loginFormTitle() {
        return this.isSignup
          ? this.$lang.login_SignUp
          : this.$lang.login_LogInPageTitle;
      },

      loginFormSubtitle() {
        let subtitle = this.$lang.login_LogInPageSubtitle;

        if (this.inviteType === invitationTypes.AMBASSADOR) {
          subtitle = this.$lang.login_AmbassadorSubtitle;
        } else if (this.inviteType === invitationTypes.PROSPECT) {
          if (this.inviterName) {
            subtitle = this.$lang.login_ProspectSubtitle.format(
              this.inviterName
            );
          } else {
            subtitle = this.$lang.login_ProspectSubtitleNoInviter.format(
              this.ambassadorPluralUserType,
              this.orgName
            );
          }
        }
        return subtitle;
      },

      ...mapState(["orgName", "orgDisplayName", "inquiryQs", "token", "uid"]),
      ...mapGetters(["ambassadorPluralUserType"]),
    },

    watch: {
      $route: {
        immediate: true,
        deep: true,
        handler(val) {
          if (val.query?.displayAuthError) {
            this.$toast.error(this.$lang.login_UnauthorizedUserError);
            this.$router.replace({
              name: "Login",
              query: { redirect: this.$route.query.redirect },
            });
          }
        },
      },
    },

    created() {
      if (this.$route.query.ambInvitationID?.length) {
        // Ambassador invite via admin dashboard
        this.inviteId = this.$route.query.ambInvitationID;
        this.inviteType = invitationTypes.AMBASSADOR;

        api
          .getAmbInvite(this.inviteId)
          .then((res) => {
            const invite = res.data;
            this.first = invite.invitee.first;
            this.last = invite.invitee.last;
            this.email = invite.invitee.email;
            this.initialize();
          })
          .catch(() => {
            this.invalidInvite = true;
            this.pageLoaded = true;
          });
      } else if (this.$route.query.value || this.$route.query.emailMagic) {
        // Invite via message response
        //TODO: Remove value after transition period, value should be renamed to emailMagic KM 4-12-2022
        this.emailMagic = this.$route.query.value
          ? this.$route.query.value
          : this.$route.query.emailMagic;
        this.inviterName = decodeURI(this.$route.query.inviterName);
        this.inviteType = invitationTypes.PROSPECT;

        api
          .verifyEmailMagic(this.emailMagic)
          .then((response) => {
            if (!response.data.emailMagic) {
              throw "expired";
            }

            this.first = response.data.first;
            this.last = response.data.last;
            this.email = response.data.email;
            this.initialize();
          })
          .catch(() => {
            this.$toast.error(this.$lang.login_ExpiredEmailMagic);
            this.$router.push({ name: "Login" });
            this.inviteType = null;
            this.initialize();
          });
      } else if (
        this.$route.query.invite?.length ||
        this.$route.query.videoInvitationID?.length ||
        this.$route.query.callInvitationID?.length
      ) {
        // Prospect outreach invite
        this.initializeOutreachInvite();
      } else if (
        this.$route.query.inviteMagic &&
        this.$route.query.invitationID
      ) {
        api
          .sendExistingInvite(
            this.$route.query.inviteMagic,
            this.$route.query.invitationID
          )
          .then((response) => {
            if (response.data.id && !this.token) {
              // User clicked the invitation email but isn't logged in
              this.$toast.success(
                this.$lang.login_InvitationAcceptedUnauthorized
              );
              this.initialize();
            } else {
              // User clicked the invitation email and is logged in, so direct them to messaging page
              this.$toast.success(this.$lang.login_InvitationAccepted);
              this.$router.push({ name: "Messaging" });
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error(this.$lang.login_ExpiredInvitationLink);
            this.initialize();
          });
      } else {
        this.initialize();
      }
    },

    methods: {
      initialize() {
        api
          .getVendors(this.orgName)
          .then((response) => {
            this.vendors = response.data.vendors || [];
            this.msaName = response.data.msaName;
            this.msaSites = response.data.sites;
            this.pageLoaded = true;
          })
          .catch((err) => {
            console.log(err);
          });
      },

      goToBlackBaud() {
        if (this.inviteType === invitationTypes.AMBASSADOR) {
          this.$store.dispatch("setBlackBaudCookies", {
            ambInvitationID: this.inviteId,
          });

          window.location.href = this.msaSites.site2;
        } else {
          window.location.href = this.msaSites.site1;
        }
      },

      toggleLoginForm() {
        this.loginEmail = !this.loginEmail;
      },

      continueWithGoogle(googleLoginEvent) {
        const credentials = {
          googleToken: googleLoginEvent.googleToken,
        };

        if (this.isSignup) {
          this.register(credentials);
        } else {
          this.login(credentials);
        }
      },

      login(credentials) {
        credentials.org = this.orgName;

        api
          .login(credentials)
          .then((response) => {
            const currentParent = !!response.data.organization;

            this.$store.dispatch("setUser", response.data);
            this.$store.dispatch("setUserId", response.data._id);
            this.$store.dispatch("setLoginToken", response.data.token);
            this.$store.dispatch("setCurrentParent", currentParent);

            if (process.env.VUE_APP_API_KEY) {
              this.$amplitude.getInstance().setUserId(this.uid);
              let identify = new this.$amplitude.Identify()
                .set("org", this.orgName)
                .set("currParent", currentParent);
              this.$amplitude.getInstance().identify(identify);
              this.$amplitude.getInstance().logEvent("LOGGED_IN");
            }

            if (!this.token) {
              this.$toast.error(this.$lang.login_LogInFail);
              return;
            }

            if (currentParent && !response.data.profile) {
              this.$router.push({ name: "Signup Profile Page" });
            } else if (
              !currentParent &&
              this.inquiryQs &&
              this.inquiryQs.length > 0 &&
              (!response.data.inquiryAs || response.data.inquiryAs.length === 0)
            ) {
              this.$router.push({ name: "Signup Profile Page" });
            } else {
              if (this.invite) {
                const tab = this.inviteTab ? this.inviteTab : 0;
                this.$router.push({
                  name: "Invites",
                  params: { inviteTab: tab },
                });
              } else {
                this.$router.push(
                  this.$route.query.redirect || { name: "Messaging" }
                );
              }
            }

            this.clearLoginData();
          })
          .catch((error) => {
            if (!error.response) {
              this.$toast.error(this.$lang.login_LogInFail);
              return;
            }
            const data = error.response.data;
            const customError = this.$lang[data?.message];

            if (customError) {
              this.$toast.error(customError);
              return;
            }

            const status = error.response.status;
            const errorMessage = data?.message;

            if (status === 433) {
              this.$toast.error(errorMessage);
              const result = confirm(
                this.$lang.login_ResendValidationEmailConfirmation
              );
              if (!result) {
                return;
              }

              //request to resend email
              const url = window.location.origin + "/emailVerification/?value=";

              api
                .resendEmailVerification(url, this.email)
                .then(() => {
                  this.$toast.success(this.$lang.login_VerificationEmailSent);
                })
                .catch((err) => {
                  console.log(err);
                });
            } else if (status === 435) {
              this.$toast.error(errorMessage.format(this.msaName));
            } else if (status === 436) {
              this.$toast.error(errorMessage);
              window.open("https://peerpal.gravyty.com/dashboard", "_self");
            } else if (status === 437) {
              this.$toast.error(errorMessage.format(this.orgDisplayName));
            } else {
              this.$toast.error(errorMessage);
            }
          });
      },

      register(credentials) {
        if (credentials) {
          try {
            //NOTE: This try catch should be temporary. There are sentry issues that I believe stem from this
            //https://sentry.io/organizations/graduway/issues/2844919041/?project=5951663&query=&statsPeriod=14d
            //Kevin M 1/6/2022

            if (
              !credentials.googleToken &&
              !commonFuncs.isEmailValid(credentials.email)
            ) {
              this.$toast.error(this.$lang.login_InvalidEmail);
              return;
            }

            let endpoint = "ambassadorRegistration";
            let data = credentials.googleToken
              ? { googleToken: credentials.googleToken }
              : {
                  first: credentials.first,
                  last: credentials.last,
                  password: credentials.password,
                };

            if (this.inviteType === invitationTypes.AMBASSADOR) {
              data.ambInvitationID = this.inviteId;
            } else if (this.inviteType === invitationTypes.PROSPECT) {
              data.emailMagic = this.emailMagic;
              endpoint = "prospectRegistration";
            } else if (this.inviteType === invitationTypes.VIDEO) {
              data.videoInvitationID = this.inviteId;
            } else if (this.inviteType === invitationTypes.CALL) {
              data.callInvitationID = this.inviteId;
            } else if (this.inviteType === invitationTypes.EMAIL) {
              data.invitationID = this.inviteId;
            }

            api[endpoint](data)
              .then((response) => {
                const uid = response.data._id || response.data.user;

                if (process.env.VUE_APP_API_KEY) {
                  this.$amplitude.getInstance().setUserId(uid);
                  let identify = new this.$amplitude.Identify()
                    .set("org", this.orgName)
                    .set("currParent", false)
                    .set("newProcess", true);
                  this.$amplitude.getInstance().identify(identify);
                  this.$amplitude.getInstance().logEvent("SIGNED_UP");
                }

                // attempt to sign in
                this.login(credentials);
              })
              .catch((error) => {
                if (error.response.status === 433) {
                  // attempt to sign in
                  this.$toast.error(
                    this.$lang.login_ExistingEmailRegistrationError
                  );
                  this.login(credentials);
                } else if (error.response.status === 404) {
                  this.$toast.error(
                    this.$lang.inviteSignup_AmbassadorInviteNotFound.format(
                      this.orgDisplayName
                    )
                  );
                } else {
                  this.$toast.error(this.$lang.general_ErrorMessage);
                }
              });
          } catch (e) {
            console.error("Signup failed", e);
          }
        } else {
          this.$toast.error(this.$lang.login_FillOutAllFields);
        }
      },

      clearLoginData() {
        this.first = "";
        this.last = "";
        this.email = "";
        this.password = "";
        this.inviteId = null;
        this.inviteType = null;
      },

      // Outreach

      initializeOutreachInvite() {
        let invitationId = this.$route.query.invite;
        let type = invitationTypes.EMAIL;

        if (this.$route.query.videoInvitationID) {
          type = invitationTypes.VIDEO;
          invitationId = this.$route.query.videoInvitationID;
        } else if (this.$route.query.callInvitationID) {
          type = invitationTypes.CALL;
          invitationId = this.$route.query.callInvitationID;
          this.callId = this.$route.query.callID;
        }

        this.inviteId = invitationId;
        this.inviteType = type;

        api
          .getInvitation(invitationId, type)
          .then((response) => {
            let inviterFirst = response.data.inviterID?.first;
            let inviterLast = response.data.inviterID?.last;
            let first = response.data.invitee?.first;
            let last = response.data.invitee?.last;
            let email = response.data.invitee?.email;
            let message = response.data.message;
            this.inviteMagic = this.$route.query.inviteMagic;
            this.accountMade = response.data.accountMade;

            if (type === invitationTypes.VIDEO) {
              inviterFirst =
                response.data.videoInvitationRequestSubmission
                  ?.videoInvitationRequest?.inviterID?.first;
              inviterLast =
                response.data.videoInvitationRequestSubmission
                  ?.videoInvitationRequest?.inviterID?.last;
              first =
                response.data.videoInvitationRequestSubmission
                  ?.videoInvitationRequest?.invitee?.first;
              last =
                response.data.videoInvitationRequestSubmission
                  ?.videoInvitationRequest?.invitee?.last;
              email =
                response.data.videoInvitationRequestSubmission
                  ?.videoInvitationRequest?.invitee?.email;
              message = response.data.videoInvitationRequestSubmission?.message;
            }

            this.inviterName = commonFuncs.toFullName(
              inviterFirst,
              inviterLast
            );
            this.first = first;
            this.last = last;
            this.email = email;
            this.message = message;

            if (this.message) {
              this.message = unescape(this.message);
            }

            this.initialize();

            if (this.inviteType === invitationTypes.VIDEO) {
              api
                .getVideoMetadata(this.inviteId)
                .then((response) => {
                  this.videoInvitationVideoPosterURL = response.data.jpgURL;
                  this.videoInvitationVideoURL = response.data.mp4URL;
                })
                .catch((err) => {
                  console.error(err);
                });
            } else if (
              this.inviteType === invitationTypes.CALL &&
              !this.accountMade
            ) {
              // If the account hasn't been made yet, check if the invite is expired
              api.getIsCallExpired(this.callId, invitationId).catch((err) => {
                if (err.response.status === 410) {
                  // User already has an account, so remove invite data
                  this.inviteType = null;
                }
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },

      playClicked() {
        if (process.env.VUE_APP_API_KEY && !this.wasVideoPlayed) {
          this.$amplitude.getInstance().logEvent("VIDEO_PLAY_CLICKED");
          this.wasVideoPlayed = true;
        }

        const videoCurrentTime = this.$refs.videoPlayer.currentTime; // in seconds
        const analyticsEvent = {
          organization: this.orgName, // optional, will be automatically extracted via invite ID
          watchedVideo: {
            videoInvitation: this.inviteId,
            metadata: {
              playerEvent: "play",
              videoCurrentTime,
            },
          },
        };

        api.logAnalyticsEvent(analyticsEvent).catch((err) => {
          console.error(err);
        });
      },

      pauseClicked() {
        const videoCurrentTime = this.$refs.videoPlayer.currentTime; // in seconds
        const analyticsEvent = {
          organization: this.orgName, // optional
          watchedVideo: {
            videoInvitation: this.inviteId,
            metadata: {
              playerEvent: "pause",
              videoCurrentTime,
            },
          },
        };

        api.logAnalyticsEvent(analyticsEvent).catch((err) => {
          console.error(err);
        });
      },

      updateData(data) {
        this.email = data.email;
      },
    },
  };
</script>

<style scoped lang="scss">
  .link:focus {
    outline: none;
  }
  .link:active {
    opacity: 0.5;
  }
  .form {
    box-sizing: border-box;
    clear: both;
    width: 100%;
  }

  .detail-text {
    font-size: 15px;
    margin: 40px 0 0 0;
  }

  .loading {
    margin-top: 100px;
  }

  .admin-link {
    color: $blue-400;
    font-size: 12px;
    margin-top: 1rem;
  }

  .signup-button {
    padding: 4px 12px;
    border-radius: 24px;
    height: 30px;
    width: 245px;
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
    color: #464646;
    font-weight: 500;
    margin: 0 auto 15px auto;
    user-select: none;
  }
  .signup-button label {
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
  }
  .signup-button img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 10px;
  }
  .signup-button:active {
    opacity: 0.5;
  }
  .google-button {
    border: 1px solid lightgrey;
  }
  .msa-button {
    color: var(--text-color);
    background-color: var(--school-color);
    border: 1px solid lightgrey;
  }
  .vc-button {
    color: #ffffff;
    background-color: #072e55;
    border: 1px solid #072e55;
  }
  .email-button {
    color: #ffffff;
    background-color: #007aff;
    border: 1px solid #007aff;
  }
  .switchText {
    margin: 15px 0 0 0;
    font-size: 15px;
  }
  .detailText {
    font-size: 15px;
    margin: 40px 0 0 0;
  }

  .sign-up-page-card-title {
    margin-bottom: 20px;
    font-weight: 500;
    margin-top: 0;
  }

  ::v-deep {
    .card {
      background-color: white;
      margin: 3em auto;
      padding: 64px 95px;
      border: 1px solid #eeeeee;
      border-radius: 20px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    }

    .card h2 {
      font-size: 2em;
      margin: 20px 0 0 0;
    }

    .card .card-subtitle {
      margin: 20px 0;
      font-weight: 500;
    }

    .card .link {
      color: #007aff;
      border: 0;
      font-size: 15px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  @media (max-width: 460px) {
    .card {
      margin-left: 10px;
      margin-right: 10px;
      padding: 25px 25px;
    }
  }

  .sign-up-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .video-invitation {
    max-width: 600px;
    margin-top: 3em;
  }

  .sign-up-page-card-subtitle {
    font-weight: normal;
    margin: 0;
    margin-bottom: 20px;
  }
</style>
