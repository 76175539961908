<template>
  <div class="landing-page">
    <div class="landing-page-quadrilateral"></div>
    <div class="landing-page-content">
      <div class="landing-page-logo-wrapper">
        <img
          class="landing-page-logo"
          src="@/assets/peerpal-gravyty-logo.svg" />
        <div class="maintenance">{{ $lang.maintenance_Banner }}</div>
      </div>
      <div class="landing-page-login-wrapper">
        <h1>{{ $lang.maintenance_Title }}</h1>
        <p>
          {{ $lang.maintenance_DescriptionPart1 }}<br />
          {{ $lang.maintenance_DescriptionPart2 }}<b>{{ time }}</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LandingPage",

    data() {
      return {
        time: process.env.VUE_APP_MAINTENANCE_END_DATE,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .landing-page {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 100%;
    background-color: #ebf4ff;
    text-align: left;

    &-quadrilateral {
      height: 100%;
      width: 100%;
      background: linear-gradient(#00b0fa, $blue-400);
      clip-path: polygon(
        0% 0%,
        calc(50% - 164px) 0%,
        calc(50% - 420px) 100%,
        0% 100%
      );
    }

    &-content {
      position: absolute;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    &-logo {
      height: 8vh;
      max-height: 77px;
      vertical-align: middle;
    }

    &-logo-wrapper {
      position: relative;
      overflow: hidden;
      margin-bottom: 6vh;
    }

    &-login-wrapper {
      background-color: $white;
      border-radius: 20px;
      display: flex;
      box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.08);
      padding: 20px;
      flex-flow: column;
    }
  }

  .maintenance {
    background-color: yellow;
    width: 140px;
    padding: 2px;
    text-align: center;
    position: absolute;
    left: -32px;
    top: 31px;
    transform: rotate(45deg);
    font-size: 8px;
    font-weight: 700;
  }
</style>
