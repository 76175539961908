<template>
  <div
    :class="['scrollbar-container', { 'always-overflow-y': alwaysOverflowY }]">
    <div ref="scrollbar" class="scrollbar">
      <slot></slot>

      <ScrollToTopBtn v-if="scrollToTopBtn" :show="!atTop" @click="scrollToTop">
      </ScrollToTopBtn>
    </div>
  </div>
</template>

<script>
  import ScrollToTopBtn from "../ScrollToTopBtn.vue";

  const DEBOUNCE = 500;
  const TOUCH_TIMEOUT = 1000;

  export default {
    components: { ScrollToTopBtn },
    name: "Scrollbar",
    props: {
      topThreshold: {
        type: Number,
        default: 0.2,
      },
      bottomThreshold: {
        type: Number,
        default: 0.8,
      },
      scrollToTopBtn: {
        type: Boolean,
        default: false,
      },

      alwaysOverflowY: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        resizeTimer: null,
        touchTimer: null,
        atTop: true,
        atBottom: false,
      };
    },
    mounted() {
      const scrollbar = this.$refs.scrollbar;

      try {
        const resizeObserver = new ResizeObserver(() => {
          clearTimeout(this.resizeTimer);
          this.resizeTimer = setTimeout(this.onOverflowChanged, DEBOUNCE);
        });
        resizeObserver.observe(scrollbar);

        scrollbar.addEventListener("touchstart", this.onTouchStart);
        scrollbar.addEventListener("touchend", this.onTouchEnd);

        if (this.scrollToTopBtn) {
          window.addEventListener("scroll", this.onScroll);
        }
      } catch (e) {
        this.onOverflowChanged();
      }

      this.$emit("loaded");
    },
    beforeDestroy() {
      this.$refs.scrollbar.removeEventListener("touchstart", this.onTouchStart);
      this.$refs.scrollbar.removeEventListener("touchend", this.onTouchEnd);
      window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
      onOverflowChanged() {
        const el = this.$refs.scrollbar;
        if (el) {
          const isOverflowing = {
            x: el.clientWidth < el.scrollWidth,
            y: el.clientHeight < el.scrollHeight,
          };
          this.$emit("overflow-change", isOverflowing);
        }
      },
      onTouchStart() {
        clearTimeout(this.touchTimer);
        this.$refs.scrollbar?.classList.add("scrolling");
      },
      onTouchEnd() {
        this.touchTimer = setTimeout(() => {
          this.$refs.scrollbar?.classList.remove("scrolling");
        }, TOUCH_TIMEOUT);
      },
      onScroll() {
        const position = window.scrollY;
        const height = document.body.clientHeight - window.innerHeight;

        const atBottom = position >= height * this.bottomThreshold;
        if (atBottom !== this.atBottom) {
          this.atBottom = atBottom;
          this.$emit("bottom-reached", atBottom);
        }
        this.atTop = position <= height * this.topThreshold;
      },
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
    },
  };
</script>

<style lang="scss">
  // Firefox
  * {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    --scrollbar-normal: rgba(117, 134, 161, 0.5);
    --scrollbar-hover: rgba(117, 134, 161, 0.7);
    --scrollbar-active: rgba(117, 134, 161, 0.8);
  }
  *:hover {
    scrollbar-color: var(--scrollbar-hover) transparent;
  }
  *:active {
    scrollbar-color: var(--scrollbar-active) transparent;
  }
  // webkit browsers like Chrome, Edge, Safari
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-normal);
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-hover);
  }
  ::-webkit-scrollbar-thumb:active {
    background: var(--scrollbar-active);
  }
</style>

<style scoped lang="scss">
  .scrollbar-container {
    &.always-overflow-y {
      padding-left: 10px;
      padding-right: 10px;

      .scrollbar {
        overflow-y: scroll;
        margin-right: -10px;
        padding-right: 5px;
        padding-left: 5px;
      }
    }
  }
  // Firefox
  .scrollbar {
    height: 100%;
    overflow: auto;
    scrollbar-color: transparent transparent;
  }
  .scrollbar:hover {
    scrollbar-color: var(--scrollbar-hover) transparent;
  }
  .scrollbar:active {
    scrollbar-color: var(--scrollbar-active) transparent;
  }
  // webkit browsers like Chrome, Edge, Safari
  .scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .scrollbar:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbar-normal);
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-hover);
  }
  .scrollbar::-webkit-scrollbar-thumb:active {
    background: var(--scrollbar-hover);
  }
  // touch support
  .scrollbar.scrolling::-webkit-scrollbar-thumb {
    background: var(--scrollbar-hover);
  }
  .scrollbar.scrolling {
    scrollbar-color: var(--scrollbar-hover) transparent;
  }
</style>
