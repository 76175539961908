<template>
  <div class="container">
    <p class="message">{{ this.loadingMessage }}</p>
  </div>
</template>

<script>
  export default {
    props: ["loadingMessage"],
  };
</script>

<style scoped>
  .message {
    font-size: 1.5rem;
    padding: 1rem;
  }
</style>
