<template>
  <div class="landingPage">
    <div class="container">
      <h1>Reset Password</h1>
      <input
        type="password"
        placeholder="Enter your new password"
        class="input"
        v-model="newPassword"
        required />
      <button class="ls" @click="sendNewPassword">Reset Password</button>
    </div>
  </div>
</template>

<script>
  import { updatePassword } from "@/services/api.service.js";
  const api = { updatePassword };

  export default {
    data() {
      return {
        newPassword: null,
      };
    },

    methods: {
      sendNewPassword() {
        if (this.newPassword && this.$route.query.value) {
          //post request to send password
          api
            .updatePassword(this.newPassword, this.$route.query.value)
            .then(() => {
              this.$toast.success(
                "Password reset! You can login with your new password!"
              );
              this.$router.push({ name: "Login" });
            })
            .catch(() => {
              this.$toast.error("Resetting password failed. Please try again.");
            });
        } else {
          this.$toast.error("Please type in a new password");
        }
      },
    },
  };
</script>

<style scoped>
  .landingPage {
    width: 100%;
    height: 100%;
  }
  .container {
    max-width: 460px;
    min-width: 260px;
    background-color: white;
    margin: auto;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    margin-top: 10em;
    padding: 20px;
  }
  .container h1 {
    font-size: 20px;
    margin-top: 0;
  }
  .input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 5px;
    font-size: 15px;
    outline: none;
    color: black;
    transition: all 0.5s ease;
    background-color: #eeeeee;
  }
  .ls {
    width: 40%;
    background: var(--accent-color);
    height: 50px;
    border: 0;
    box-sizing: border-box;
    font-size: 16px;
    color: white;
    border-radius: 25px;
    margin-top: 15px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  .ls:hover {
    transform: scale(1.06);
  }
  .ls:focus {
    outline: none;
  }
  .ls:active {
    opacity: 0.5;
  }
</style>
