<template>
  <common-modal
    :title="$lang.modalReportUser_Title"
    :submitButtonText="$lang.modal_Submit"
    @submit="reportUser"
    @close="$emit('close')">
    <div class="subtext">{{ $lang.modalReportUser_Subtext }}</div>
    <div>
      <common-radio-button-group :options="options" v-model="reason" />
      <common-input
        v-if="reason === $lang.modalReportUser_Option3"
        class="modal-report-user__custom-input"
        type="text"
        :maxLength="120"
        v-model="customInput" />
    </div>
  </common-modal>
</template>

<script>
  import { requestBan } from "@/services/api.service.js";
  const api = { requestBan };
  export default {
    name: "ModalReportUser",
    props: {
      recipientID: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        reason: null,
        customInput: null,
        options: [
          this.$lang.modalReportUser_Option0,
          this.$lang.modalReportUser_Option1,
          this.$lang.modalReportUser_Option2,
          this.$lang.modalReportUser_Option3,
        ],
      };
    },
    methods: {
      reportUser() {
        if (this.customInput || this.reason) {
          api
            .requestBan(this.recipientID, this.customInput || this.reason)
            .then(() => {
              this.$emit("submit", true);
              this.$emit("close");
            })
            .catch(() => {
              this.$toast.error(this.$lang.modalReportUser_Error);
              this.$emit("close");
            });
        } else {
          this.$toast.error(this.$lang.modalReportUser_Required);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .subtext {
    text-align: left;
    color: $grey-800;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 26px;
  }
  .modal-report-user__custom-input {
    margin-top: -1em;
    margin-left: calc(1.25em + 12px);
  }
</style>
