<template>
  <div class="body">
    <div class="sk-fading-circle">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div>
  </div>
</template>

<script>
  import { validateMsa } from "@/services/api.service.js";
  const api = { validateMsa };
  import { mapState } from "vuex";

  export default {
    computed: {
      ...mapState(["orgName", "inquiryQs", "signUpSlider"]),
    },

    async created() {
      const orgName = this.orgName;

      let msaUser = {
        token: this.$route.query.token,
      };

      if (this.$route.query.login === "curr") {
        //curr parent
        msaUser.organization = orgName;
        if (this.$cookies.get("ambInvitationID")) {
          msaUser.ambInvitationID = this.$cookies.get("ambInvitationID");
        }
      } else if (this.$route.query.login === "invite") {
        //invited prosp parent
        msaUser.signedUpOrg = orgName;

        // check which type of invitation it is
        if (this.$cookies.get("invitationID")) {
          msaUser.invitationID = this.$cookies.get("invitationID");
        } else if (this.$cookies.get("videoInvitationID")) {
          msaUser.videoInvitationID = this.$cookies.get("videoInvitationID");
        } else if (this.$cookies.get("callInvitationID")) {
          msaUser.callInvitationID = this.$cookies.get("callInvitationID");
        }
      } else {
        //create prosp parent
        msaUser.signedUpOrg = orgName;
        msaUser.emailMagic = this.$cookies.get("emailMagic");
      }

      api
        .validateMsa(msaUser)
        .then((response) => {
          if (!response.data.token) {
            throw { response: { status: 422 } };
          }

          let currentParent = true;
          if (typeof response.data.user.organization === "undefined") {
            currentParent = false;
          }

          this.$store.dispatch("setUserId", response.data.user._id);
          this.$store.dispatch("setLoginToken", response.data.token);
          this.$store.dispatch("setCurrentParent", currentParent);
          this.$store.dispatch("fetchUser").catch((e) => {
            console.error(e);
          });

          // remove all cookies made for MSA sign up flow
          this.$store.dispatch("clearBlackBaudCookies");

          // amplitude
          if (response.data.isLogIn && process.env.VUE_APP_API_KEY) {
            // login
            this.$amplitude.getInstance().setUserId(response.data.user._id);
            let identify = new this.$amplitude.Identify()
              .set("org", orgName)
              .set("currParent", currentParent);
            this.$amplitude.getInstance().identify(identify);
            this.$amplitude.getInstance().logEvent("LOGGED_IN");
          } else if (process.env.VUE_APP_API_KEY) {
            // signup
            this.$amplitude.getInstance().setUserId(response.data.user._id);
            let identify = new this.$amplitude.Identify()
              .set("org", orgName)
              .set("currParent", currentParent);
            this.$amplitude.getInstance().identify(identify);
            this.$amplitude.getInstance().logEvent("SIGNED_UP");
          }

          if (currentParent && !response.data.user.profile) {
            //if current parent hasn't filled out profile
            this.$router.push({ name: "Signup Profile Page" });
          } else if (currentParent) {
            //if current parent has filled out profile
            this.$router.push({ name: "Messaging" });
          } else if (
            (this.signUpSlider && !response.data.user.q1) ||
            (this.inquiryQs &&
              this.inquiryQs.length > 0 &&
              (!response.data.user.inquiryAs ||
                response.data.user.inquiryAs.length === 0))
          ) {
            //if new parent needs to fill out slider and hasn't
            this.$router.push({ name: "Signup Profile Page" });
          } else {
            //if prospect parent needs to fill out slider and has or doesn't need to fill out slider
            this.$router.push({ name: "Messaging" });
          }
        })
        .catch((err) => {
          if (err.response.status === 409) {
            this.$toast.error(
              "This email is already in use. Please use another email."
            );
          } else if (err.response.status === 410) {
            this.$toast.error(
              "You have not created an account yet. Please create an account before trying to log in."
            );
          } else {
            this.$toast.error("Sign up/log in failed. Please try again.");
          }

          let routeQuery = null;

          let routeName = "Login";

          if (this.$route.query.login === "invite") {
            if (this.$cookies.get("invitationID")) {
              routeQuery = {
                invitationID: this.$cookies.get("invitationID"),
              };
            } else if (this.$cookies.get("videoInvitationID")) {
              routeQuery = {
                videoInvitationID: this.$cookies.get("videoInvitationID"),
              };
            } else {
              routeQuery = {
                callInvitationID: this.$cookies.get("callInvitationID"),
              };
            }
          }

          this.$store.dispatch("clearBlackBaudCookies");
          this.$router.push({
            name: routeName,
            query: routeQuery,
          });
        });
    },
  };
</script>

<style scoped lang="scss">
  .body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sk-fading-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-fading-circle .sk-circle:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  .sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  .sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  .sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  .sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  .sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  .sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  .sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  .sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  .sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -var(--transition-duration);
    animation-delay: -var(--transition-duration);
  }
  .sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }

  @keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
</style>
