<template>
  <div class="emailVerifPage">
    <div v-if="!pageLoaded" class="loader"></div>
    <div v-else>{{ message }}</div>
  </div>
</template>

<script>
  import { verifyEmail } from "@/services/api.service.js";
  import { mapState } from "vuex";

  export default {
    data() {
      return {
        pageLoaded: false,
        message: "",
      };
    },
    computed: {
      ...mapState(["user"]),
    },

    async created() {
      verifyEmail(this.$route.query.value)
        .then((response) => {
          const data = response.data;
          this.message = this.$lang.emailVerification_verified.format(
            data.email
          );

          if (this.user) {
            const user = { ...this.user, ...data };
            delete user.newEmail;
            delete user.newEmailExpiration;
            this.$store.dispatch("setUser", user);
          }
        })
        .catch(() => {
          this.message = this.$lang.emailVerification_invalidLink;
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },
  };
</script>

<style scoped>
  .emailVerifPage {
    width: 100%;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }

  .loader {
    border: 5px solid lightgrey;
    border-radius: 50%;
    border-top: 5px solid var(--accent-color);
    width: 80px;
    height: 80px;
    animation: spin 0.75s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
