<template>
  <common-stepper
    :title="$lang.stepper_ProfileSignup"
    :steps="steps"
    :stepIndex.sync="stepIndex">
    <template v-slot:step-complete> </template>
  </common-stepper>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "ProfileSignup",
    data() {
      return {
        stepIndex: 0,
        steps: [
          {
            name: this.$lang.editProfile_Details,
            component: () =>
              import("@/components/profile/StepProfileDetails.vue"),
          },
        ],
      };
    },
    computed: {
      ...mapState(["user", "features"]),
    },
    created() {
      if (this.user.organizationId) {
        if (this.features?.allowProfileVideo) {
          this.steps.push({
            name: this.$lang.watchMyStory_Title,
            component: () =>
              import("@/components/profile/StepWatchMyStory.vue"),
          });
        }

        this.steps.push({
          name: this.$lang.editProfile_ReadMyStory,
          component: () => import("@/components/profile/StepReadMyStory.vue"),
        });
      }
    },
  };
</script>
