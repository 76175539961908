var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stepper-wrapper"},[_vm._t("title",function(){return [(_vm.title)?_c('div',{staticClass:"stepper-header-title"},[_vm._v(_vm._s(_vm.title))]):_vm._e()]}),_c('div',{staticClass:"stepper-header"},[_c('div',{staticClass:"stepper-header-line"}),_vm._l((_vm.steps),function(step,index){return _c('div',{key:`stepper-header-step-${step.name || index}`,class:[
        'stepper-header-step',
        {
          'current-step': index === _vm.internalStepIndex,
          complete: _vm.isStepComplete(step),
        },
      ],on:{"click":function($event){return _vm.emitStepChange(index)}}},[_c('div',{staticClass:"stepper-header-step-counter-wrapper"},[_c('div',{staticClass:"stepper-header-step-counter"},[(!_vm.isStepComplete(step))?_c('span',[_vm._v(_vm._s(index + 1))]):_c('span',[_c('ppicon',{attrs:{"name":"check"}})],1)])]),(step.name)?_c('div',{staticClass:"stepper-header-step-name"},[_vm._v(" "+_vm._s(step.name)+" ")]):_vm._e()])})],2),_c('div',{staticClass:"stepper-step-container"},[_c('div',{staticClass:"stepper-step-container-back"},[(_vm.stepIndex > 0)?_c('common-button',{attrs:{"variant":"text"},on:{"click":function($event){return _vm.emitStepChange(_vm.stepIndex - 1)}}},[_c('ppicon',{staticClass:"stepper-step-container-back-icon",attrs:{"name":"menu-down","alt":"triangle"}}),_vm._v(" "+_vm._s(_vm.$lang.general_Back)+" ")],1):_vm._e()],1),_c('transition',{attrs:{"name":_vm.transitionName,"mode":"out-in"}},[_vm._l((_vm.stepsWithState),function(step,index){return [(index === _vm.internalStepIndex)?_c(step.component,{key:`stepper-step-${step.name || index}`,tag:"component",staticClass:"stepper-step",attrs:{"options":step.options,"state":step.state},on:{"update":function($event){return _vm.updateState(step, $event)},"back":function($event){return _vm.emitStepChange(index - 1)},"continue":function($event){return _vm.emitStepChange(index + 1)},"complete":function($event){return _vm.completeStep(step, index)}}}):_vm._e()]}),(_vm.showStepCompleteSlot)?_vm._t("step-complete",function(){return [_c('StepCompleted',{attrs:{"title":_vm.$lang.stepper_Congratulations,"description":_vm.$lang.stepper_EditProfile}})]}):_vm._e()],2)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }