<template>
  <div class="messaging-page" :style="{ height: bodyHeight }">
    <div class="messaging-page-container" :style="{ height: containerHeight }">
      <inbox
        :selectedConvoId="selectedConvoId"
        @select-conversation="selectConversation"
        @ready="inboxReady = true"></inbox>

      <transition name="slide-to-left-absolute">
        <chat
          :ready="inboxReady"
          :selectedConvoId="selectedConvoId"
          v-if="showChat"></chat>
      </transition>
    </div>
  </div>
</template>

<script>
  import Inbox from "./Inbox.vue";
  import Chat from "./Chat.vue";
  import commonFuncs from "@/common/common";
  import { mapState } from "vuex";

  export default {
    components: {
      Inbox,
      Chat,
    },

    data() {
      return {
        inboxReady: false,
        isMobile: false,
        contentHeight: 0,
      };
    },

    computed: {
      selectedConvoId() {
        return this.$route.query.id;
      },

      showChat() {
        return !this.isMobile || (this.isMobile && this.selectedConvoId);
      },

      bodyHeight() {
        const marginModifier = this.isMobile ? 0 : 46;
        return `${this.contentHeight - marginModifier}px`;
      },

      containerHeight() {
        const marginModifier = this.isMobile ? 0 : 54;
        return `${this.contentHeight - marginModifier}px`;
      },

      ...mapState(["token", "uid"]),
    },

    mounted() {
      if (!this.token || !this.uid) {
        // No user token, clean up any other cookies and redirect to login
        this.deleteToken();
        return;
      }

      window.addEventListener("resize", this.onResize);
      this.onResize();
    },

    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
    },

    methods: {
      selectConversation(data) {
        //Add to route so page reload opens convo again
        if (this.$route.query.id !== data) {
          this.$router.push({ name: "Messaging", query: { id: data } });
        }
      },

      deleteToken() {
        this.$store.dispatch("clearLoginCookies");

        if (process.env.VUE_APP_API_KEY) {
          this.$amplitude.getInstance().setUserId(null);
          this.$amplitude.getInstance().regenerateDeviceId();
        }

        this.$router.push({ name: "Login" });
      },

      onResize() {
        this.isMobile = commonFuncs.isMobile();

        const headerHeight = document.querySelector("header").offsetHeight;
        const footerHeight = document.querySelector("footer").offsetHeight;
        this.contentHeight = window.innerHeight - (footerHeight + headerHeight);
      },
    },
  };
</script>

<style scoped lang="scss">
  .messaging-page {
    display: flex;
    justify-content: center;
    margin: 23px;
  }

  .messaging-page-container {
    flex: 1;
    display: flex;
    justify-content: center;
    max-width: 1400px;
    background-color: $white;
    border-radius: 20px;
    padding: 9px;
    margin: auto;
    position: relative;
    overflow-x: hidden;
  }

  @media screen and (max-width: $sm) {
    .messaging-page {
      margin: 0;
    }

    .messaging-page-container {
      margin: 0;
      padding: 0;
      border-radius: 0;
    }
  }
</style>
