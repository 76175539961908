<template>
  <div class="dropdown" ref="dropdown">
    <div ref="button" @click.stop="toggleMenu">
      <slot>
        <ppicon class="menu-opener" name="dots-vertical" @click="toggleMenu" />
      </slot>
    </div>
    <ul ref="menu" class="menu" v-show="showMenu">
      <li v-for="item in items" :key="item.name">
        <router-link v-if="item.to" :to="item.to" class="menu-item">
          <ppicon :name="item.icon" class="menu-item-icon" />
          {{ item.label }}
        </router-link>
        <button v-else class="menu-item" @click="menuClick(item)">
          <ppicon :name="item.icon" class="menu-item-icon" />
          {{ item.label }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "Dropdown",
    props: {
      items: {
        type: Array,
      },
    },
    data() {
      return {
        showMenu: false,
      };
    },
    mounted() {
      window.addEventListener("click", this.onClickOutside);
    },
    beforeDestroy() {
      window.removeEventListener("click", this.onClickOutside);
    },
    methods: {
      toggleMenu() {
        this.showMenu = !this.showMenu;
        this.$emit("toggle", this.showMenu);
      },
      menuClick(item) {
        this.showMenu = false;
        this.$emit("menu-click", item);
      },
      onClickOutside(e) {
        if (this.showMenu && e.target != this.$refs.button) {
          this.showMenu = false;
          this.$emit("toggle", this.showMenu);
        }
      },
    },
  };
</script>
<style scoped lang="scss">
  .dropdown {
    position: relative;
  }

  .menu {
    list-style: none;
    position: absolute;
    right: 0;
    color: $primary-text;
    background: #fff;
    text-align: left;
    border-radius: 8px;
    margin: 0;
    padding: 4px;
    width: 192px;
    font-size: 12px;
  }

  .menu-opener {
    cursor: pointer;
  }

  .menu-item {
    width: 100%;
    padding: 12px;
    cursor: pointer;
    border-radius: 12px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
  a.menu-item {
    width: inherit;
  }

  .menu-item:hover {
    background-color: $row-hover-background;
  }

  .menu-item-icon {
    padding-right: 15px;
    font-size: 20px;
    vertical-align: middle;
  }
</style>
