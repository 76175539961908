var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inbox-container"},[_c('div',{staticClass:"inbox-header"},[_c('h2',{staticClass:"inbox-header-text"},[_vm._v(_vm._s(_vm.$lang.inbox_DirectMessages))]),_c('common-search',{model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('Scrollbar',{staticClass:"inbox-message-container",attrs:{"alwaysOverflowY":""}},_vm._l((_vm.searchedConvos),function(convo){return _c('div',{key:`inbox-panel-${convo.convoId}`,class:['inbox-message', { 'active-convo': _vm.isConvoSelected(convo) }],on:{"click":function($event){return _vm.selectConversation(convo)}}},[_c('div',{staticClass:"profile-pic-container"},[_c('img',{attrs:{"src":_vm.getRecipientImage(convo),"alt":"Person's picture"}})]),_c('div',{staticClass:"message-container"},[_c('div',{staticClass:"user-message-wrapper"},[_c('label',{class:[
              'user-name text-truncate',
              {
                'unread-message':
                  !convo.message.isRead && convo.message.authorUserId !== _vm.uid,
              },
            ]},[_vm._v(_vm._s(convo.recipientName))]),_c('label',{staticClass:"user-message text-truncate"},[_vm._v(_vm._s(_vm.getDisplayName(convo.recipientOrgName) || convo.message.body))])]),_c('div',{staticClass:"date-wrapper"},[_c('label',{staticClass:"message-date"},[_vm._v(_vm._s(_vm.formatDate(convo.message.createdDate)))])])])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }