import VueToasted from "vue-toasted";
import toastComponent from "./toast.vue";

function iconClass(opts) {
  if (opts?.icon) {
    opts.icon = "pp-icon " + opts.icon;
  }
}

export default {
  install: function (Vue) {
    Vue.use(VueToasted, {
      position: "top-right",
      duration: 5000,
      theme: "pp-toast",
      iconPack: "custom-class",
    });

    const toasted = Vue.prototype.$toasted;

    Vue.prototype.$toast = {
      show(message, options) {
        const opts = options || {};
        iconClass(opts);

        toasted.show(message, opts);
      },
      success(message, options) {
        const opts = {
          icon: "check-circle",
          ...options,
        };
        iconClass(opts);
        toasted.success(message, opts);
      },
      error(message, options) {
        const opts = {
          icon: "alert",
          ...options,
        };
        iconClass(opts);
        toasted.error(message, opts);
      },
      register(name, message, options) {
        toasted.register(name, message, options);
      },
      clear() {
        toasted.clear();
      },
    };

    Vue.component("toast", toastComponent);
  },
};
