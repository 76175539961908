<template>
  <div>
    <iframe class="iframe" :src="iframeSrc" ref="iframe"></iframe>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
      },
    },
    data() {
      return {
        origin: process.env.VUE_APP_GOOGLE_LOGIN_ORIGIN || "",
      };
    },
    computed: {
      iframeSrc() {
        return (
          this.origin +
          "/google-login.html?id=" +
          process.env.VUE_APP_GOOGLE_URL +
          "&label=" +
          this.label
        );
      },
    },
    created() {
      window.addEventListener("message", (event) => {
        if (event.data.googleToken) {
          this.$emit("login", event.data);
        }
      });
    },
  };
</script>

<style scoped>
  .iframe {
    margin: 0;
    border: none;
    width: 271px;
    height: 52px;
  }
</style>
