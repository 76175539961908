<template>
  <div class="search-container">
    <ppicon name="magnify" />

    <common-input
      v-model="query"
      :placeholder="placeholder"
      @input="emitInput" />
    <button v-if="query.length" class="search-container-clear" @click="clear">
      <ppicon name="close" />
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: [String, Number],
        default: "",
      },

      placeholder: {
        type: String,
        default() {
          return this.$lang.general_SearchPlaceholder;
        },
      },
    },

    data() {
      return {
        query: "",
      };
    },

    methods: {
      emitInput() {
        this.$emit("input", this.query);
      },
      clear() {
        this.query = "";
        this.emitInput();
      },
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep.search-container {
    display: flex;
    align-items: center;
    border-radius: 22px;
    background-color: #f2f2f2;
    padding: 10px 15px;
    font-size: 14px;

    .input-container {
      margin-left: 12px;
      flex: 1;
      background-color: transparent;
      display: flex;
      padding: 0;
    }

    .input {
      flex: 1;
      padding: 0;
      margin: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .search-container-clear {
    padding: 0;
  }
</style>
