<template>
  <div>
    <div class="step-completed-lottie">
      <lottie :options="options" :width="animationWidth" />
    </div>
    <h2 class="step-completed-title">{{ title }}</h2>
    <div class="step-completed-description">{{ description }}</div>
    <common-button
      class="step-completed-button"
      @click="$router.push({ name: 'Home' })"
      >{{ $lang.stepper_Ok }}</common-button
    >
  </div>
</template>

<script>
  import Lottie from "vue-lottie";
  import animationData from "@/assets/lottie/ProfileComplete.json";
  import commonFuncs from "@/common/common.js";

  export default {
    components: {
      lottie: Lottie,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
    },
    computed: {
      animationWidth() {
        return commonFuncs.isMobile() ? 294 : 304;
      },
    },
    data() {
      return {
        options: { animationData, loop: false },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .step-completed {
    &-lottie {
      margin-top: 40px;
    }

    &-title {
      margin-top: 52px;

      @media screen and (max-width: $sm) {
        margin-top: 40px;
      }
    }

    &-description {
      margin-top: 28px;
      color: $grey-500;
    }

    &-button {
      margin: 52px 0 64px 0;
      width: 164px;
    }
  }
</style>
